import React, { useState, useMemo, useEffect } from "react";

import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { Tooltip } from "@material-tailwind/react";
import toast, { Toaster } from 'react-hot-toast';
import Loader from "components/Loader";
import { useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import DraggableList from "./components/draggable-list";
import { getBatches, getPrioQueue, getRobodialers, updatePrioQueue } from "../../apis/agent";
import KeyValueGrid from "./components/key-value-grid";
import { useForm, SubmitHandler } from "react-hook-form";

interface Item {
  priority: number;
  name: string;
  id: string;
}

const useQuery = () => {
  const location = useLocation();
  return useMemo(() => new URLSearchParams(location.search), [location.search]);
};

interface FormValues {
  name: string;
  id: string;
}


export default function QMToolsPage() {

  const [sourceData, setSourceData] = useState<null | {
    id:string;
    name:string;
    robodialerName?: string;
    batchId?: string;
  }[]>(null);
  const [errorID, setErrorID] = useState<string | null>(null);
  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState("fusion");
  const [selectQueueType, setSelectQueueType] = useState("customers");
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [pendingSelectValue, setPendingSelectValue] = useState("");
  const [pendingSelectValueQueue, setPendingSelectValueQueue] = useState("");
  const { register, handleSubmit, reset } = useForm<FormValues>();

  const [loading, setLoading] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const [items, setItems] = useState<Item[]>([]);
  const fetchRobodialers = async () => {
    const { data } = await getRobodialers();
    
    setSourceData(data?.robodialers || []);
    setLoading(false);
    const response = await getPrioQueue(selectValue, selectQueueType);
    setLoading(false)
    if(response.httpCode !== 200){
      toast.error(response.httpCode + ' Unable to get Queue: ' + response.data?.error ?? '', {
      iconTheme: {
        primary: '#F36F82',
        secondary: '#ffffff',
      },
    });
    }else{
      setItems(
        (Object.keys(response?.data?.queue).map(key => ({
                              priority: parseInt(key),
                              name: '-',
                              id: response?.data?.queue[key].trim() ?? ''
                            }))) as Item[]
      )
    }

  };

  useEffect(() => {
    fetchRobodialers();
  }, []);

  const addToPrioList = (id, name) => {
    if (items.some((item) => item.id === id)) {
      setErrorID(id); // Set error to highlight the duplicate id field
      setTimeout(() => setErrorID(null), 2000); // Remove error highlight after 2 seconds
      return;
    }
    const newItem: Item = {
      priority: items.length + 1,
      name: name,
      id,
    };
    setHasChanges(true)
    setItems([...items, newItem]);
  };

  const handleSelectChange = (value: string) => {
    
    if (hasChanges) {
      setPendingSelectValue(value);
      setShowConfirmModal(true);
    } else {
      setSelectValue(value);
      setEditEnabled(false);
    }
  };

  const handleQueueTypeSelectChange = (value: string) => {
    if (hasChanges) {
      setPendingSelectValueQueue(value);
      setShowConfirmModal(true);
    } else {
      setSelectQueueType(value);
      setEditEnabled(false);
    }
  };
  
  const confirmSelectChange = () => {
    if (pendingSelectValue) {
      setSelectValue(pendingSelectValue);
    }
    if(pendingSelectValueQueue){
      setSelectQueueType(pendingSelectValueQueue);
    }
    //onItemsChange(initialItems);
    setShowConfirmModal(false);
    setHasChanges(false);
    setEditEnabled(false);
  };

  const cancelSelectChange = () => {
    setPendingSelectValue("");
    setPendingSelectValueQueue("");
    setShowConfirmModal(false);
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      const response = await getPrioQueue(selectValue, selectQueueType);
      setLoading(false)
      if(response.httpCode !== 200){
        toast.error(response.httpCode + ' Unable to get Queue: ' + response.data?.error ?? '', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      }else{
        setItems(
            (Object.keys(response?.data?.queue).map(key => ({
                                  priority: parseInt(key),
                                  name: '-',
                                  id: response?.data?.queue[key].trim() ?? ''
                                }))) as Item[]
          )
      }
    }
    fetch()
    
    if(selectQueueType === 'batch'){
      fetchBatches()
    }else{
      fetchRobodialers()
    }
  }, [selectValue, selectQueueType])

  const fetchBatches = async () => {
    setLoading(true)
    const twoWeeksAgo = DateTime.now().minus({ weeks: 2 }).toISODate();
    const twoWeeksinthefuture = DateTime.now().plus({ weeks: 2 }).toISODate();
    const response = await getBatches({
      dateOption: 'custom',
      dateFrom: twoWeeksAgo,
      dateTo: twoWeeksinthefuture,
      batchStatus: 'processing',
      filterBy: 'dueDate',
      orderDirection: 'desc',
    });
    setLoading(false)
    if(response.httpCode !== 200){
      toast.error(response.httpCode + ' Unable to get Batches: ' + response.data?.error ?? '', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return
    }
    
    setSourceData(response?.data?.data || [])
  }
  
  
  return (
    <>
      {loading ? (
        <>
          <div className="w-full h-full flex justify-center items-center">
            {" "}
            <Loader />{" "}
          </div>
        </>
      ) : null}
      {!loading ? (
        <div className="mx-4 mt-5 mb-20">
          {showConfirmModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-bold mb-4">Pending Changes</h2>
                <p className="mb-4">
                  You have unsaved changes. If you proceed, these changes will
                  be lost. Do you want to continue?
                </p>
                <div className="flex justify-end">
                  <button
                    onClick={cancelSelectChange}
                    className="px-4 py-2 bg-gray-500 text-white rounded mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={confirmSelectChange}
                    className="px-4 py-2 bg-red-500 text-white rounded"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex items-center justify-start gap-x-2">
            <span className="font-jakarta text-superbill-jacarta text-xl font-bold mb-4">
              Tools
            </span>
          </div>
          {sourceData ? (
            <>
              <div className="overflow-y-auto border border-superpay-soap rounded p-2 col-span-4 bg-superbill-anti-flash-white">
              <div className="text-superbill-jacarta font-bold text-lg font-jakarta">
                    Priority Queue
                </div>
                <div className="flex items-center justify-between w-full py-2 gap-x-4">
                  <div className="flex items-center w-full gap-x-4">
                    <select
                      value={selectValue}
                      onChange={(e) => handleSelectChange(e.target.value)}
                      className='bg-white border-1 block focus:ring-superbill-indigo rounded text-superbill-jacarta text-base ease-in-out duration-300 w-1/2'
                    >
                      <option value="fusion">Fusion</option>
                      <option value="robodialer">Robodialer</option>
                      <option value="support">Support</option>
                    </select>
                    <select
                      value={selectQueueType}
                      onChange={(e) => handleQueueTypeSelectChange(e.target.value)}
                      className='bg-white border-1 block focus:ring-superbill-indigo rounded text-superbill-jacarta text-base ease-in-out duration-300 w-1/2'
                    >
                      <option value="customers">Robodialer</option>
                      <option value="batch">Batch</option>
                    </select>
                  </div>
                  <div className="flex items-center justify-end w-full gap-x-4">
                    <button
                      onClick={() => setEditEnabled(!editEnabled)}
                      className='bg-white font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
                    >
                      {editEnabled ? "Disable Editing" : "Enable Editing"}
                    </button>
                    <button
                      disabled={!hasChanges || saveIsLoading}
                      onClick={async () => {
                        setSaveIsLoading(true)
                        const response = await updatePrioQueue({
                          'user_type': selectValue,
                          'queue_type': selectQueueType,
                          'queue_data': items
                        });
                        setSaveIsLoading(false)
                        if (response.httpCode === 200) {
                          setEditEnabled(false)
                          setHasChanges(false)
                          toast.success("Priority queue updated", {
                            iconTheme: {
                              primary: '#4a43cd',
                              secondary: '#ffffff',
                            },
                          }
                          )
                        } else {
                          toast.error(response.httpCode + ' Unable to update queue: ' + response.data.error ?? response.msg ?? '', {
                            iconTheme: {
                              primary: '#F36F82',
                              secondary: '#ffffff',
                            },
                          });
                        }
                      }}
                      className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
                    >
                      {saveIsLoading ? <Spinner /> : <>Save</>}
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-10">
                  <div className="col-span-4 px-2 py-1">
                    <div className="border border-superpay-soap divide-y divide-superpay-soap rounded">
                      <div className="grid grid-cols-10 items-center gap-4 bg-superbill-anti-flash-white-darken">
                        <span className="col-span-4 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                          {selectQueueType !== 'batch'?  'Name' : 'Batch ID'}
                        </span>
                        <span className="col-span-6 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right">
                          {selectQueueType !== 'batch'?  'ID' : 'Robo Name'}
                        </span>
                      </div>
                      <KeyValueGrid
                        action={(value,key) => {
                          if (selectQueueType === 'batch') {
                            addToPrioList(key, value)
                          }else{
                            addToPrioList(value, key)
                          }
                        }}
                        icon={
                          <Tooltip
                            content={
                              <span style={{ whiteSpace: "pre-line" }}>
                                Add to priority queue
                              </span>
                            }
                            className="max-w-[200px] bg-black py-1 px-2"
                          >
                            <strong className="text-sm text-superbill-jacarta cursor-pointer">
                              {">"}
                            </strong>
                          </Tooltip>
                        }
                        data={(sourceData ?? [])?.reduce(
                          (acc, { id, name, robodialerName, batchId }) => {
                            acc[name ??  batchId] = id ?? robodialerName;
                            return acc;
                          },
                          {} as { [key: string]: string }
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-span-6 px-2 py-1 ">
                    <div className="border border-superpay-soap divide-y divide-superpay-soap rounded">
                      <div className="grid grid-cols-10 items-center gap-4 bg-superbill-anti-flash-white-darken">
                        <span className="col-span-2 px-2 text-sm py-1 text-superbill-jacarta font-jacarta ">
                          Priority
                        </span>
                        <span className="col-span-6 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                          Name
                        </span>
                        <span className="col-span-2 px-2 text-sm py-1 text-superbill-jacarta font-jacarta ">
                          Remove
                        </span>
                      </div>
                      <DraggableList
                        errorID={errorID}
                        items={items}
                        onItemsChange={setItems}
                        editEnabled={editEnabled}
                        setEditEnabled={setEditEnabled}
                        setHasChanges={setHasChanges}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
