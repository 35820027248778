import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Questions } from "./QuestionType";


interface Props {
  questions: Array<Questions>;
  setQuestions: React.Dispatch<React.SetStateAction<Array<Questions>>>;
}



const QuestionsList = ({ questions, setQuestions }: Props) => {
  const { register } = useForm();

  const [formData, setFormData] = useState<Record<string, boolean>>({});

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    // Update the state with the new checkbox value
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));

    // Check if a subquestion was clicked and update the parent question if necessary
    if (name.includes("subquestion")) {
      //const [questionIndex, subquestionType, subquestionIndex] = name.split("-");
      const [questionIndex] = name.split("-");
      const parentQuestionName = `${questionIndex}-question-${questionIndex}`;

      // If the parent question isn't checked, check it now
      if (!formData[parentQuestionName]) {
        setFormData((prevData) => ({
          ...prevData,
          [parentQuestionName]: true,
        }));
      }
    } else {

      const [questionIndex] = name.split("-");

      questions[questionIndex]?.followUps?.Yes?.forEach((subquestion, index) => {
        const subquestionName = `${questionIndex}-subquestion-Yes-${index}`;
        setFormData((prevData) => ({
          ...prevData,
          [subquestionName]: checked,
        }));
      });

      questions[questionIndex]?.followUps?.No?.forEach((subquestion, index) => {
        const subquestionName = `${questionIndex}-subquestion-No-${index}`;
        setFormData((prevData) => ({
          ...prevData,
          [subquestionName]: checked,
        }));
      });
    }
  };





  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('submit', formData, questions);
    
    const selectedQuestions = {};

    for (const key in formData) {
      const [questionIndex, type, subQuestionType, subQuestionIndex] = key.split("-");
      if (!formData[key]) {
        continue;
      }
      
      if (type === "question") {
        const question = questions[questionIndex];
        const prevQuestion = selectedQuestions[questionIndex];
        if (question && !prevQuestion) {
          selectedQuestions[questionIndex] = {
            question: question?.question,
            answerType: question?.answerType,
          };
          
        }
      } else {
        if (formData[key]) {
          let question = selectedQuestions[questionIndex];
          
          
          if (!question) {
            selectedQuestions[questionIndex] = {
              question: questions[questionIndex]?.question,
              answerType: questions[questionIndex]?.answerType,
              followUps: {
                No: [],
                Yes: [],
              }
            };
          }
          question = selectedQuestions[questionIndex];
          const subquestions = question?.followUps;
          if (!subquestions) {
            selectedQuestions[questionIndex]['followUps'] = {
              No: [],
              Yes: [],
            }
          }
          selectedQuestions[questionIndex]['followUps'][subQuestionType].push(questions[questionIndex]['followUps'][subQuestionType][subQuestionIndex]);
        }
      }
    }
    console.log('selectedQuestions', selectedQuestions);
    
  }

  return (
    <form onSubmit={handleFormSubmit}>
      {questions.map((question, index) => (
        <div key={index}>
          <label className="cursor-pointer flex items-center p-4 bg-white rounded border border-superbill-soap my-2">
            <input
              type="checkbox" className="form-radio rounded-full mr-3"
              {...register(`${index}-question-${index}`)}
              checked={formData[`${index}-question-${index}`] || false}
              onChange={handleCheckboxChange}
            />
            {question.question}
          </label>
            {0 < question?.followUps?.Yes?.length! ? 
            <div className="flex flex-wrap items-start ml-4 md:ml-0">
              <div className="w-full md:w-auto md:p-4">
                <div className="text-gray-700 font-bold mt-1">If Yes</div>
              </div>
              <div className="w-full md:flex-1">
                {question?.followUps?.Yes?.map((subQuestion, subIndex) => (
                  <div key={'Yes-' + subIndex}>
                    <label className="cursor-pointer flex items-center p-4 bg-white rounded border border-superbill-soap my-2">
                      <input
                        type="checkbox" className="form-radio rounded-full mr-3"
                        {...register(`${index}-subquestion-Yes-${subIndex}`)}
                        checked={formData[`${index}-subquestion-Yes-${subIndex}`] || false}
                        onChange={handleCheckboxChange}
                      />
                      {subQuestion.question}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            : null
            }
            
            {0 < question?.followUps?.No?.length! ? 
            <div className="flex flex-wrap items-start ml-4 md:ml-0">
              <div className="w-full md:w-auto md:p-4">
                <div className="text-gray-700 font-bold mt-1">If No</div>
              </div>
              <div className="w-full md:flex-1">
                {question?.followUps?.No?.map((subQuestion, subIndex) => (
                  <div key={'No-' + subIndex}>
                    <label className="cursor-pointer flex items-center p-4 bg-white rounded border border-superbill-soap my-2" >
                      <input
                        type="checkbox" className="form-radio rounded-full mr-3"
                        {...register(`${index}-subquestion-No-${subIndex}`)}
                        checked={formData[`${index}-subquestion-No-${subIndex}`] || false}
                        onChange={handleCheckboxChange}
                      />
                      {subQuestion.question}
                    </label>
                  </div>
                ))}
              </div>
            </div>
              : null
            }
            
        </div>
      ))}
      <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md">DEV:Log selected questions</button>
    </form>
  );
};

export default QuestionsList;
