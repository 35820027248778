import React from "react";
import { Controller } from 'react-hook-form';
import { Option } from "./QuestionType";
import Select, { components } from "react-select";
import selectOptions from "./QuestionSelectOptions";

interface QuestionSelectProps {
  index: number | string;
  control: any;
  options: Option[];
  onChange: (selectedOption: Option | string) => void;

  defaultValue?: Option | string;
}

const QuestionSelect: React.FC<QuestionSelectProps> = ({ control, options, onChange, index, defaultValue }) => {


  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="flex items-center group">
          <div className="mx-1 fill-jacarta group-hover:fill-superbill-indigo">{props.data.icon}</div>
          <div className="mx-1">{props.data.label}</div>
        </div>
      </components.Option>
    );
  };
  
  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props} className="flex items-center">
          <div className="mx-1 ">{props.data.icon}</div>
          <div className="mx-1 font-sans">{props.data.label}</div>
      </components.SingleValue>
    );
  };

  const defaultValueObject = typeof defaultValue === 'string'
  ? selectOptions.find(option => option.value === defaultValue)
  : defaultValue;
  
  return (
    <Controller
      name={`${index}.answerType`}
      control={control}
      defaultValue={defaultValueObject ?? null}
      render={({ field }) => (
        <Select
          placeholder={'Question Type'}
          components={{
            Option,
            SingleValue: SingleValue,
            IndicatorSeparator:() => null,
            IndicatorsContainer:() => null,

          }}
          menuPortalTarget={document.body}
          styles={{
            singleValue: (provided) => ({
              ...provided,
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            control: (baseStyles, state) => ({
             ...baseStyles,
              '&:hover': {backgroundColor: '#EFF1F6', cursor: 'pointer'},
              boxShadow: state.isFocused ? '0px 0px 0px 3px #E4E8FD' : 'none',
              borderColor: state.isFocused ? '#4a43cd' : '#ffffff',
              zIndex: 11
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              fontSize: '15px',
              fontFamily: 'DM Sans',
              backgroundColor: state.isFocused ? '#f5f6fb' : '#ffffff',
              color: state.isFocused ? '#4a43cd' : '#38395B',
              ':active': {
                ...baseStyles[':active'],
                backgroundColor: '#D0D3F2',
              },
            })
          }}
          isSearchable={false}
          className='bg-white border-1 mb-2 block w-full focus:ring-superbill-indigo rounded text-superbill-jacarta text-base ease-in-out duration-300'
          {...field}
          options={options}
          value={defaultValueObject?.value? defaultValueObject : null}
          onChange={(selectedOption) => {
            onChange(selectedOption!)
          }}
        />
      )}
      rules={{ required: true }}
    />
  );
};

export default QuestionSelect;
