import React, { useEffect, useState, useMemo } from 'react'
import { ReactComponent as ExclamationIcon } from 'assets/exclamation.svg';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { addBusinessDays, addHours } from 'date-fns';
import { DateTime } from 'luxon';
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { getBatches, getCallsOverview, getRobodialerBatchCalls, downloadResults, downloadCallInputs, downloadZipResults } from 'apis/robodialer';
import { toast } from 'react-hot-toast';
import { Tooltip } from '@material-tailwind/react';
import { Batches } from 'types/types';
import { ReactComponent as TotalIcon } from 'assets/icon_phone.svg';
import { ReactComponent as SuccessIcon } from 'assets/icon_check-circle.svg';
import { ReactComponent as FailIcon } from 'assets/icon_xmark-circle.svg';
import { ReactComponent as CallSuccessIcon } from 'assets/icon_callSuccess.svg';
import { ReactComponent as CallFailIcon } from 'assets/icon_callFailure.svg';
import { ReactComponent as DownloadIcon } from 'assets/icon_downloadCallInputs.svg';
import Loader from 'components/Loader';





interface AttemptResult {
    question: string;
    answer: string;
    type: string;
}

interface Call {
    attempt_results: Record<string, AttemptResult>;
    callId: string;
    supportTeamOutcome: string;
}

type BatchCallData = Call;

type ScriptsDetailedResultsTableProps = {
    selectedBatch: string | null;
    setSelectedBatch: React.Dispatch<React.SetStateAction<string | null>>;
    batches: Batches;
}

const ScriptsDetailedResultsTable: React.FC<ScriptsDetailedResultsTableProps> = ({ selectedBatch, setSelectedBatch, batches }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [batchResults, setBatchResults] = useState<BatchCallData[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [batchInfo, setBatchInfo] = useState<Batches | undefined>(undefined);


    useEffect(() => {
        const fetchScripts = async () => {
            const response = await getRobodialerBatchCalls(selectedBatch);
            setBatchResults(response.data?.calls || []);
            console.log('response!!!!', response.data?.calls)
        };
        fetchScripts();
    }, [selectedBatch]);

    useEffect(() => {
        if (batches && selectedBatch) {
            const foundBatch = batches[selectedBatch];
            setBatchInfo(foundBatch);
        } 
    }, [batches, selectedBatch]); 

    useEffect(() => {
        if (batchResults.length > 0) {
            const uniqueHeaders = extractUniqueHeaders(batchResults);
            setHeaders(uniqueHeaders);
        }
    }, [batchResults]);

    const extractUniqueHeaders = (batchResults: BatchCallData[]) => {
        const uniqueHeaders = new Set<string>();

        batchResults.forEach(call => {
            const attemptResults = call.attempt_results;

            if (attemptResults && typeof attemptResults === 'object') {
                Object.keys(attemptResults).forEach(key => {
                    const attempt = attemptResults[key];
                    if (attempt && attempt.question) {
                        uniqueHeaders.add(attempt.question);
                    }
                });
            } else {
                console.warn('attempt_results is not an object or is missing:', call);
            }
        });

        return Array.from(uniqueHeaders);
    };

    const createAnswerMap = (call: Call) => {
        const answerMap: Record<string, string> = {};
        const attemptResults = call.attempt_results;
    
        if (attemptResults && typeof attemptResults === 'object') {
            Object.keys(attemptResults).forEach(key => {
                const attempt = attemptResults[key];
                if (attempt && attempt.question) {
                    let answer = attempt.answer;
    
                    // Convert boolean values to "Yes" or "No"
                    if (typeof answer === 'boolean') {
                        answer = answer ? 'Yes' : 'No';
                    }
    
                    // Display "-" if the answer is null
                    answerMap[attempt.question] = answer === null ? '-' : answer || '';
                }
            });
        }
        return answerMap;
    };
    const downloadInputs = async (scriptId, batchId) => {
        if (!scriptId || !batchId) {
          toast.error('Unable to Download', {
            iconTheme: {
              primary: '#F36F82',
              secondary: '#ffffff',
            },
          });
          return;
        };
        const response = await downloadCallInputs(scriptId, batchId);
        if (response.httpCode !== 200) {
          toast.error('Unable to Download', {
            iconTheme: {
              primary: '#F36F82',
              secondary: '#ffffff',
            },
          });
          return;
        };
        const data = response.data as { file?: any };
        const linkSource = `data:application/pdf;base64,${data.file}`;
        const downloadLink = document.createElement('a');
        const fileName = batchId + '_inputs.csv';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      };

      const downloadResult = async (scriptId, batchId, type) => {
        let name = type ? 'success' : 'failed'
        if (!scriptId || !batchId) {
          toast.error('Unable to Download', {
            iconTheme: {
              primary: '#F36F82',
              secondary: '#ffffff',
            },
          });
          return;
        };
        const response = await downloadResults(scriptId, batchId, type);
        if (response.httpCode !== 200) {
          toast.error('Unable to Download', {
            iconTheme: {
              primary: '#F36F82',
              secondary: '#ffffff',
            },
          });
          return;
        };
        const data = response.data as { file?: any };
        const linkSource = `data:application/pdf;base64,${data.file}`;
        const downloadLink = document.createElement('a');
        //const fileName = name + '.csv';
        const fileName = `${batchId}_${name}.csv`
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      };

    return (
        <>
        
        <div className='mb-2 flex items-center text-superbill-slate-grey text-sm font-jakarta cursor-pointer' onClick={() => setSelectedBatch(null)}>
            <BackIcon className='fill-superbill-slate-grey mr-2' />Back to all Results
        </div>
        <div className='flex justify-between items-end'>
            <div className='text-2xl font-semibold text-superbill-jacarta font-jakarta'>{selectedBatch}</div>
            <div className='text-superbill-slate-grey'>
                {batchInfo?.completedAt.toLocaleString()}
            </div>
        </div>
        <div className='flex my-4'>
            <div className='border border-superbill-soap rounded bg-white/30 p-3 basis-1/3 mr-2'>
                <div className='flex items-center'>
                    <TotalIcon className='w-[12px]'/>
                    <div className='font-semibold text-superbill-slate-grey text-sm text-jakarta ml-2 grow'>Calls Completed</div>
                    <button className='bg-white  font-jakarta hover:bg-superbill-anti-flash-white text-superbill-slate-grey text-xs font-semibold p-1 rounded ease-in-out duration-300 margin-auto border border-superbill-soap flex w-fit items-center' onClick={() => { downloadInputs(batchInfo?.scriptId, selectedBatch) }}>
                        <DownloadIcon className='mr-1'/>
                        Inputs
                    </button>
                </div>
                <div className='text-xl text-superbill-jacarta'>{batchInfo?.numCalls}</div>
            </div>
            <div className='border border-superbill-soap rounded bg-white/30 p-3 basis-1/3'>
                <div className='flex items-center'>
                    <SuccessIcon className='w-[12px]'/>
                    <div className='font-semibold text-superbill-slate-grey text-sm text-jakarta ml-2 grow'>Successful</div>
                    {batchInfo?.numSuccessful != '0' ? 
                    <button className='bg-white  font-jakarta hover:bg-superbill-anti-flash-white text-superbill-slate-grey text-xs font-semibold p-1 rounded ease-in-out duration-300 margin-auto border border-superbill-soap flex w-fit items-center' onClick={() => { downloadResult(batchInfo?.scriptId, selectedBatch, true) }}>
                        <DownloadIcon className=''/>
                        
                    </button>
                    : null
                    }
                </div>
                <div className='text-xl text-superbill-jacarta'>{batchInfo?.numSuccessful}</div>
            </div>
            <div className='border border-superbill-soap rounded bg-white/30 p-3 basis-1/3 ml-2'>
                <div className='flex items-center'>
                    <FailIcon className='w-[12px]'/>
                    <div className='font-semibold text-superbill-slate-grey text-sm text-jakarta ml-2 grow'>Insufficient / Incorrect Data</div>
                    {batchInfo?.numFailed != '0' ? 
                    <button className='bg-white  font-jakarta hover:bg-superbill-anti-flash-white text-superbill-slate-grey text-xs font-semibold p-1 rounded ease-in-out duration-300 margin-auto border border-superbill-soap flex w-fit items-center' onClick={() => { downloadResult(batchInfo?.scriptId, selectedBatch, false) }}>
                        <DownloadIcon className=''/>
                        
                    </button>
                    : null
                    }
                </div>
                <div className='text-xl text-superbill-jacarta'>{batchInfo?.numFailed}</div>
            </div>

        </div>
        {loading || !batchResults.length || !headers.length ? (
            <div className='flex justify-center items-center'>
                <Loader /> 
            </div>
        ) : (
            <div className='flex w-full border border-superbill-soap rounded overflow-hidden text-superbill-jacarta'>
                {/* Call IDs Table */}
                <div className='flex-shrink-0'>
                    <table className="w-full border-r-2 border-superbill-soap drop-shadow-[6px_0px_rgba(208,211,242,0.25)]">
                        <thead>
                            <tr>
                                <th className="p-2 bg-superbill-anti-flash-white whitespace-nowrap text-left">Call ID</th>
                                <th className="p-2 bg-superbill-anti-flash-white whitespace-nowrap text-left"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {batchResults.map((call, index) => (
                                <tr key={index}>
                                    <td className="p-2 border-t border-superbill-soap bg-white">{`${call.callId ?? '-'}`}</td>
                                    <td className="p-2 border-t border-superbill-soap bg-white">{
                                        call.supportTeamOutcome == 'Success' ?
                                        <Tooltip
                                            content={
                                                <span style={{ whiteSpace: 'pre-line' }}>
                                                    <span className="text-sm">Successful Call</span>
                                                </span>
                                            } className='bg-black py-1 px-2 max-w-[200px]'
                                        >
                                        <CallSuccessIcon />
                                        </Tooltip>
                                        : call.supportTeamOutcome == 'Failure' ?
                                        <Tooltip
                                            content={
                                                <span style={{ whiteSpace: 'pre-line' }}>
                                                    <span className="text-sm">Insufficient / Incorrect Data to retrieve answers to all questions</span>
                                                </span>
                                            } className='bg-black py-1 px-2 max-w-[200px]'
                                        >
                                        <CallFailIcon />
                                        </Tooltip>
                                        :
                                        ''
                                    }</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Details Table */}
                <div className='flex-1 overflow-x-auto'>
                    <table className="min-w-max">
                        <thead>
                            <tr>
                                {headers.map(header => (
                                    <th key={header} className="p-2 bg-superbill-anti-flash-white min-w-[150px] max-w-[300px] overflow-hidden text-clip whitespace-nowrap border-r border-superbill-soap">
                                        <Tooltip
                                            content={
                                                <span style={{ whiteSpace: 'pre-line' }}>
                                                    <span className="text-sm">{header}</span>
                                                </span>
                                            } className='bg-black py-1 px-2 max-w-[200px]'
                                        >
                                            {header}
                                        </Tooltip>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {batchResults.map((call, index) => {
                                const answerMap = createAnswerMap(call);
                                return (
                                    <tr key={index}>
                                        {headers.map(header => (
                                            <td key={header} className="p-2 border-t border-superbill-soap min-w-[150px] max-w-[300px] overflow-hidden text-clip whitespace-nowrap border-r bg-white">
                                                <Tooltip
                                                    content={
                                                        <span style={{ whiteSpace: 'pre-line' }}>
                                                            <span className="text-sm">{answerMap[header] || ''}</span>
                                                        </span>
                                                    } className='bg-black py-1 px-2 max-w-[200px]'
                                                >
                                                    {answerMap[header] || ''}
                                                </Tooltip>
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )}
        </>
    );
};

export default ScriptsDetailedResultsTable;