import { DateTime } from 'luxon';
import { Tooltip } from '@material-tailwind/react';
import { Fragment} from 'react'
import { Menu, Transition } from '@headlessui/react'
import toast from 'react-hot-toast';
import { ReactComponent as SparklesIcon } from 'assets/sparkles2.svg';
import { ReactComponent as OpenIcon } from 'assets/open.svg';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import { ReactComponent as RunIcon } from 'assets/run.svg';
import { ReactComponent as RenameIcon } from 'assets/rename.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as ViewInputsIcon } from 'assets/view-inputs.svg';
import { ReactComponent as DownloadInputsIcon } from 'assets/download.svg';
import { ReactComponent as DefineInputsIcon } from 'assets/define-inputs.svg';



type ScriptCardProps = {
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onViewInputs: (id: string) => void;
  onDefineInputs: null | ( (id: string) => void);
  onDownloadInputs: (id: string) => void;
  onRename: (id: string) => void;
  onRun: (e: {
    id: string;
    action: 'delete' | 'run' | 'duplicate';
  }) => void;
  name: string;
  createdAt: string;
  pmsCard: boolean;
  id: string;
  onDuplicate: (e: {
    id: string;
    action: 'delete' | 'run' | 'duplicate';
  }) => void;
  questionsCount?: number;
  status: boolean;
};

const onCopy = (id) => {
  navigator.clipboard.writeText(id);
  toast.success('Copied to clipboard', {
    iconTheme: {
      primary: '#4a43cd',
      secondary: '#ffffff',
    },
  }
  );
}

const ScriptCard = ({
  name,
  createdAt,
  id,
  questionsCount,
  onEdit,
  onDuplicate,
  onRun,
  onViewInputs,
  onDefineInputs,
  onDownloadInputs,
  pmsCard = false,
  onDelete,
  onRename,
  status
}: ScriptCardProps) => {


  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <div className='flex'>
        <Menu.Button className="bg-white mt-4 rounded border border-superbill-soap px-5 py-3 justify-center items-center hover:border-superbill-ultramarine w-full min-w-[325px] lg:w-[325px] grow ease-in-out duration-150">
          <span className="sr-only">Open options</span>
          <div
            key={id}
          // onClick={() => {onEdit(id)}}
          >
            <div className='flex justify-between items-center'>
              {name.length >= 32 ?
                <Tooltip content={name} className='bg-black py-1 px-2'>
                  <div className='text-left mr-2 text-superbill-jacarta text-base font-jakarta font-semibold truncate'>{name}</div>
                </Tooltip>
                :
                <div className='text-left mr-2 text-superbill-jacarta text-base font-jakarta font-semibold truncate'>{name}</div>}
              {/* <div className='ml-1 flex items-center h-full'>
                <svg width="5" height="19" viewBox="0 0 5 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.625 14.0741C3.93549 14.0741 5 15.178 5 16.537C5 17.8961 3.93549 19 2.625 19C1.31451 19 0.25 17.8961 0.25 16.537C0.25 15.178 1.31451 14.0741 2.625 14.0741ZM2.625 7.03704C3.93549 7.03704 5 8.14097 5 9.5C5 10.859 3.93549 11.963 2.625 11.963C1.31451 11.963 0.25 10.859 0.25 9.5C0.25 8.14097 1.31451 7.03704 2.625 7.03704ZM5 2.46296C5 3.82199 3.93549 4.92593 2.625 4.92593C1.31451 4.92593 0.25 3.82199 0.25 2.46296C0.25 1.10394 1.31451 0 2.625 0C3.93549 0 5 1.10394 5 2.46296Z" fill="#727272" />
                </svg>
              </div> */}
              {pmsCard ? 
              <div className='flex px-2 py-1 items-center bg-superbill-lavender rounded-full'>
                <SparklesIcon/>
                <div className='ml-1 text-superbill-indigo text-xs font-jakarta text-nowrap'>PMS Integration</div>
              </div>
              :null}
            </div>
            <div className='text-sm text-start text-superpay-light-text-gray'>ID: {id}</div>
            <div className='w-full flex justify-between items-center my-1'>
              <div className='text-superbill-jacarta text-sm'>
                <strong>{questionsCount?.toString()}</strong>{' '}
                Question{questionsCount === 1 ? '' : 's'}
              </div>
              <div className='text-superpay-light-text-gray text-sm font-sans'>{
                DateTime.fromJSDate(new Date(createdAt)).toFormat(
                  'MMM d'
                )}</div>
            </div>
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 -mt-2 w-64 origin-top-right rounded bg-white shadow-lg focus:outline-none">
          <div className="py-1 border border-superbill-soap border-1 rounded">
            <Menu.Item>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { onEdit(id) }}
              >
                <OpenIcon className="w-[20px]" />
                <div className='mx-2 text-base'>View/Edit Script</div>
              </div>
            </Menu.Item>
            {0 < (questionsCount ?? 0) ?
              <Menu.Item>
                <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                  onClick={() => {
                    onRun({ id, action: 'run' })
                  }}
                >
                  <RunIcon className="w-[20px]" />
                  <div className='mx-2 text-base'>Run Script</div>
                  {status ? null :  <div className='text-xs text-superbill-indigo bg-superbill-lavender rounded px-2 py-1'>Live Customers</div>}
                </div>
              </Menu.Item>
            :null}
            <div className='border-b border-superbill-soap'></div>
            <Menu.Item>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { onViewInputs(id) }}
              >
                <ViewInputsIcon className="w-[20px]" />
                <div className='mx-2 text-base'>View Inputs</div>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { onDownloadInputs(id) }}
              >
                <DownloadInputsIcon className="w-[20px]" />
                <div className='mx-2 text-base'>Download Inputs Template</div>
              </div>
            </Menu.Item> 
            {onDefineInputs ? 
              <Menu.Item>
                <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                  onClick={() => { onDefineInputs(id) }}
                >
                  <DefineInputsIcon className="w-[20px]" />
                  <div className='mx-2 text-base'>Define Script-Level Inputs</div>
                </div>
              </Menu.Item>
            :null}
            <div className='border-b border-superbill-soap'></div>
            <Menu.Item>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { onCopy(id) }}
              >
                <CopyIcon className="w-[20px]" />
                <div className='mx-2 text-base'>Copy ID</div>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { onRename(id) }}
              >
                <RenameIcon className="w-[20px]" />
                <div className='mx-2 text-base'>Rename Script</div>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => {
                  onDuplicate({ id, action: 'duplicate' })
                }}
              >
                <CopyIcon className="w-[20px]" />
                <div className='mx-2 text-base'>Duplicate Script</div>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { onDelete(id) }}
              >
                <DeleteIcon className="w-[20px]"/>
                <div className='mx-2 text-base'>Delete Script</div>
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ScriptCard;
