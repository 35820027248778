import { ReactComponent as BooleanIcon } from 'assets/boolean.svg';
import { ReactComponent as TextIcon } from 'assets/text.svg';
import { ReactComponent as NumberIcon } from 'assets/number.svg';
import { ReactComponent as DollarIcon } from 'assets/dollar.svg';
import { ReactComponent as DateIcon } from 'assets/date.svg';
import { ReactComponent as MultipleIcon } from 'assets/multiple.svg';
import { ReactComponent as AlphanumericIcon } from 'assets/alphanumeric.svg';
import { ReactComponent as FaxIcon } from 'assets/fax.svg';
import { ReactComponent as PhoneIcon } from 'assets/phone.svg';
import { ReactComponent as AddressIcon } from 'assets/address.svg';

const selectOptions = [
  { value: "boolean", label: "Yes/No" , icon:<BooleanIcon className='h-4 w-4'/>},
  { value: "text", label: "Text" , icon:<TextIcon className='h-4 w-4'/>},
  { value: "number", label: "Number" , icon:<NumberIcon className='h-4 w-4'/>},
  { value: "alphanumeric", label: "Alphanumeric" , icon:<AlphanumericIcon className='h-4 w-4'/>},
  { value: "dollar", label: "Dollar" , icon:<DollarIcon className='h-4 w-4'/>},
  { value: "date", label: "Date" , icon:<DateIcon className='h-4 w-4'/>},
  { value: "address", label: "Address" , icon:<AddressIcon className='h-4 w-4'/>},
  { value: "phoneNumber", label: "Phone Number" , icon:<PhoneIcon className='h-4 w-4'/>},
  { value: "faxNumber", label: "Fax Number" , icon:<FaxIcon className='h-4 w-4'/>},
  { value: "multiple", label: "Multiple Choice" , icon:<MultipleIcon className='h-4 w-4'/>}
]
export default selectOptions;