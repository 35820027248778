import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as AttemptsIcon } from 'assets/number.svg';
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as AuditIcon } from "assets/boolean.svg";
import { ReactComponent as ViewInputsIcon } from "assets/view-inputs.svg";
import { Tooltip } from "@material-tailwind/react";
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { paginationHelper, PaginationStates } from "utils/pagination";
import { ReactComponent as BundleIcon } from "assets/multiple.svg";
import { CallsType } from "types/types";
import KeyValueGrid from "./key-value-grid";

type CallsTableProps = {
  calls: CallsType[] | null;
  createModal: (title, content) => void;
};
export default function CallsTable({ calls, createModal }: CallsTableProps) {
  
  const [currentResultsPageIndex, setCurrentResultsPageIndex] =
    useState<number>(0);
  const {
    newEntries: currentActiveResults,
    currentLowerIndex,
    currentUpperIndex,
    changePagination,
  } = paginationHelper({
    entries: calls ?? [],
    currentPageIndex: currentResultsPageIndex,
    maxEntriesPerPage: 25,
    setCurrentPageIndex: setCurrentResultsPageIndex,
  });

  return (
    <div className="bg-white">
      <div className="w-full grid grid-cols-24 gap-x-2 bg-superbill-anti-flash-white divide-x divide-superpay-soap">
        <div className=" col-span-1 px-2 py-1 text-right"></div>
        <div className="py-3 col-span-4 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
          callId
        </div>
        <div className="py-3 col-span-3 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
          to
        </div>
        <div className="py-3 col-span-1 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
          Attempts
        </div>
        <div className="py-3 col-span-1 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
          success
        </div>
        <div className="py-3 col-span-1 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
          completed
        </div>
        <div className="py-3 col-span-1 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
          inProgress
        </div>
        <div className="py-3 col-span-4 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
          Assigned
        </div>
        <div className="py-3 col-span-4 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
          tryAgainAfterTimestamp
        </div>
        <div className="py-3 col-span-4 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap text-right">
          More Info
        </div>
      </div>
      {((currentActiveResults as CallsType[]) ?? []).map((call, index) => (
        <div
          key={`${call?.call_id}-${index}`}
          className={`grid grid-cols-24 gap-x-2 py-2 divide-x border-b border-superpay-soap divide-superpay-soap`}
        >
          <div className=" col-span-1 px-2 py-1 text-right">
            <input className="checked:bg-superbill-ultramarine rounded-sm disabled:bg-superbill-anti-flash-white" disabled={true} type="checkbox" name="" id="" />
          </div>
          <div className=" col-span-4 px-2 py-1 overflow-x-auto">
            {call?.call_id ?? "-"}
          </div>
          <div className=" col-span-3 px-2 py-1 overflow-x-auto">
            {call?.to ?? "-"}
          </div>
          <div className=" col-span-1 px-2 py-1 overflow-x-auto">
            {call?.n_attempts ?? "-"}
          </div>
          <div className=" col-span-1 px-2 py-1 overflow-x-auto">
            {call?.success ?? "0"}
          </div>
          <div className=" col-span-1 px-2 py-1 overflow-x-auto">
            <Tooltip
              content={
                <span style={{ whiteSpace: "pre-line" }}>
                  CompletedAt:{call?.completedAt?.et ?? "-"}
                </span>
              }
              className="max-w-[200px] bg-black py-1 px-2"
            >
              {(call?.completed ? "Yes" : "No") ?? "-"}
            </Tooltip>
          </div>
          <div className=" col-span-1 px-2 py-1 overflow-x-auto">
            {(call?.inProgress ? "Yes" : "No") ?? "-"}
          </div>
          <div className=" col-span-4 px-2 py-1 overflow-x-auto">
            <Tooltip
              content={
                <span style={{ whiteSpace: "pre-line" }}>
                  AssignedAt:{call?.assignedAt?.et ?? "-"}
                </span>
              }
              className="max-w-[200px] bg-black py-1 px-2"
            >
              {call?.assignedToRetoolUser ?? "-"}
            </Tooltip>
          </div>
          <div className=" col-span-4 px-2 py-1 overflow-x-auto">
            {call?.tryAgainAfterTimestamp?.et ?? "-"}
          </div>
          <div className="col-span-4 px-2 py-1 text-right flex items-center justify-center gap-x-3">
            <Tooltip
              content={
                <span style={{ whiteSpace: "pre-line" }}>View Inputs</span>
              }
              className="max-w-[200px] bg-black py-1 px-2"
            >
              <ViewInputsIcon
                onClick={() => {
                  createModal(
                    `${call?.call_id} - Inputs`,
                    <div className="h-[500px] overflow-y-auto">
                      <div className="border border-superpay-soap divide-y divide-superpay-soap rounded">
                        <div className="flex gap-4 bg-superbill-anti-flash-white-darken">
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                            Key:
                          </span>
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta ">
                            Value:
                          </span>
                        </div>
                        <KeyValueGrid data={call?.inputs ?? {}} />
                      </div>
                    </div>
                  );
                }}
                className="cursor-pointer w-[20px]"
              />
            </Tooltip>
            <Tooltip
              content={
                <span style={{ whiteSpace: "pre-line" }}>Bunddle Info</span>
              }
              className="max-w-[200px] bg-black py-1 px-2"
            >
              <BundleIcon
                onClick={() => {
                  createModal(
                    `${call?.call_id} - Bunddle Info`,
                    <div className="overflow-y-auto">
                      <div className="border border-superpay-soap divide-y divide-superpay-soap rounded">
                        <div className="flex gap-4 bg-superbill-anti-flash-white-darken">
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                            Key:
                          </span>
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta ">
                            Value:
                          </span>
                        </div>
                        <KeyValueGrid data={{
                          bunddleSessionId: call?.bunddleSessionId || '-',
                          bundleChild: call?.bundleChild || '-',
                          bundleParent: call?.bundleParent || '-',
                          bundleRoot: call?.bundleRoot || '-',
                          bundleRootAttempt: call?.bundleRootAttempt || '-',
                          bundleRootRobodialer: call?.bundleRootRobodialer || '-',
                          bundleSessionId: call?.bundleSessionId || '-',
                        }} />
                      </div>
                    </div>
                  );
                }}
                className="cursor-pointer w-[20px]"
              />
            </Tooltip>
            <Tooltip
              content={
                <span style={{ whiteSpace: "pre-line" }}>Audit Info</span>
              }
              className="max-w-[200px] bg-black py-1 px-2"
            >
              <AuditIcon
                onClick={() => {
                  createModal(
                    `${call?.call_id} - Audit Info`,
                    <div className="overflow-y-auto">
                      <div className="border border-superpay-soap divide-y divide-superpay-soap rounded">
                        <div className="flex gap-4 bg-superbill-anti-flash-white-darken">
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                            Key:
                          </span>
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta ">
                            Value:
                          </span>
                        </div>
                        <KeyValueGrid data={{
                          "supportTeamSuccessBy": call?.supportTeamSuccessBy || '-',
                          "assignedToRetoolAuditor": call?.assignedToRetoolAuditor || '-',
                          "auditInProgress": (call?.auditInProgress ? 'Yes' : 'No') || '-',
                          "supportTeamOutcome": call?.supportTeamOutcome || '-',
                          "assignedTo_group": call?.assignedTo?.group || '-',
                          "assignedTo_name": call?.assignedTo?.name || '-',
                        }} />
                      </div>
                    </div>
                  );
                }}
                className="cursor-pointer w-[20px]"
              />
            </Tooltip>

            <Tooltip
              content={<span style={{ whiteSpace: "pre-line" }}>Info</span>}
              className="max-w-[200px] bg-black py-1 px-2"
            >
              <InfoIcon
                onClick={() => {
                  createModal(
                    `${call?.call_id} - Audit Info`,
                    <div className="overflow-y-auto">
                      <div className="border border-superpay-soap divide-y divide-superpay-soap rounded">
                        <div className="flex gap-4 bg-superbill-anti-flash-white-darken">
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                            Key:
                          </span>
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta ">
                            Value:
                          </span>
                        </div>
                        <KeyValueGrid data={{
                          "internalId": call?.internalId || '-',
                          "robodialerId": call?.robodialer_id || '-',
                          "batchId": call?.batchId || '-',
                          "source": call?.source || '-',
                        }} />
                      </div>
                    </div>
                  );
                }}
                className="cursor-pointer w-[20px]"
              />
            </Tooltip>
            <Tooltip
              content={<span style={{ whiteSpace: "pre-line" }}>Last Attempt</span>}
              className="max-w-[200px] bg-black py-1 px-2"
            >
              <AttemptsIcon
                onClick={() => {
                  createModal(
                    `${call?.call_id} - Audit Info`,
                    <div className=" h-full overflow-y-auto">
                      <div className="border border-superpay-soap divide-y divide-superpay-soap rounded">
                        <div className="flex gap-4 bg-superbill-anti-flash-white-darken">
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                            Last Attempt Results:
                          </span>
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta ">
                            Value:
                          </span>
                        </div>
                        <KeyValueGrid data={{
                          "lastAttemptEndedAt": call?.lastAttemptEndedAt?.et || '-',
                        }} />
                      </div>
                      <div className="border border-superpay-soap divide-y divide-superpay-soap rounded mt-5">
                        <div className="flex gap-4 bg-superbill-anti-flash-white-darken ">
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                            Last Attempt MetaBillie Results:
                          </span>
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta ">
                            Value:
                          </span>
                        </div>
                        <KeyValueGrid data={{
                          "officeClosedOrVoicemailResult_office_closed": (call?.lastAttemptMetabillieResults?.officeClosedOrVoicemailResult?.office_closed ? 'Yes' : 'No') ?? '-',
                          "officeClosedOrVoicemailResult_office_closed_evidence": (call?.lastAttemptMetabillieResults?.officeClosedOrVoicemailResult?.office_closed_evidence) ?? '-',
                          "officeClosedOrVoicemailResult_office_closed_reasoning": (call?.lastAttemptMetabillieResults?.officeClosedOrVoicemailResult?.office_closed_reasoning) ?? '-',
                          "officeClosedOrVoicemailResult_reached_voicemail": (call?.lastAttemptMetabillieResults?.officeClosedOrVoicemailResult?.reached_voicemail ? 'Yes' : 'No') ?? '-',
                          "officeClosedOrVoicemailResult_reached_voicemail_evidence": (call?.lastAttemptMetabillieResults?.officeClosedOrVoicemailResult?.reached_voicemail_evidence) ?? '-',
                          "officeClosedOrVoicemailResult_reached_voicemail_reasoning": (call?.lastAttemptMetabillieResults?.officeClosedOrVoicemailResult?.reached_voicemail_reasoning) ?? '-',
                          "reachedHuman_evidenceFromTranscript": (call?.lastAttemptMetabillieResults?.reachedHuman?.evidenceFromTranscript) ?? '-',
                          "reachedHuman_reachedHuman": (call?.lastAttemptMetabillieResults?.reachedHuman?.reachedHuman ? 'Yes' : 'No') ?? '-',
                          "reachedHuman_reasoning": (call?.lastAttemptMetabillieResults?.reachedHuman?.reasoning) ?? '-',
                        }} />
                      </div>
                    </div>
                  );
                }}
                className="cursor-pointer w-[20px]"
              />
            </Tooltip>
            <Tooltip
              content={<span style={{ whiteSpace: "pre-line" }}>Last Attempt</span>}
              className="max-w-[200px] bg-black py-1 px-2"
            >
              <div className="flex relative">
                <AttemptsIcon
                  onClick={() => {
                    createModal(
                      `${call?.call_id} - Audit Info`,
                      <div className=" h-full overflow-y-auto">
                        {(Object.keys(call?.callAttemptHistory ?? {})).map( (key) => (
                          <div key={`callAttemptHistory-${key}`} className="border border-superpay-soap divide-y divide-superpay-soap rounded mt-2">
                            <div className="flex gap-4 bg-superbill-anti-flash-white-darken">
                              <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                                Attempt:
                              </span>
                              <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta ">
                                {key}
                              </span>
                            </div>
                            <KeyValueGrid data={{
                              "caller": call?.callAttemptHistory[key]?.caller,
                              "endedAt.et": call?.callAttemptHistory[key]?.endedAt.et,
                              "hangupCause": call?.callAttemptHistory[key]?.hangupCause,
                              "hangupSource": call?.callAttemptHistory[key]?.hangupSource,
                              "startedAt?.et": call?.callAttemptHistory[key]?.startedAt?.et,
                              "metabillieResults?.officeClosedOrVoicemailResult?.office_closed": call?.callAttemptHistory[key]?.metabillieResults?.officeClosedOrVoicemailResult?.office_closed,
                              "metabillieResults?.officeClosedOrVoicemailResult?.office_closed_evidence": call?.callAttemptHistory[key]?.metabillieResults?.officeClosedOrVoicemailResult?.office_closed_evidence,
                              "metabillieResults?.officeClosedOrVoicemailResult?.office_closed_reasoning": call?.callAttemptHistory[key]?.metabillieResults?.officeClosedOrVoicemailResult?.office_closed_reasoning,
                              "metabillieResults?.officeClosedOrVoicemailResult?.reached_voicemail": call?.callAttemptHistory[key]?.metabillieResults?.officeClosedOrVoicemailResult?.reached_voicemail,
                              "metabillieResults?.officeClosedOrVoicemailResult?.reached_voicemail_evidence": call?.callAttemptHistory[key]?.metabillieResults?.officeClosedOrVoicemailResult?.reached_voicemail_evidence,
                              "metabillieResults?.officeClosedOrVoicemailResult?.reached_voicemail_reasoning": call?.callAttemptHistory[key]?.metabillieResults?.officeClosedOrVoicemailResult?.reached_voicemail_reasoning,
                              }} />
                          </div>
                        ))}
                      </div>
                    );
                  }}
                  className="cursor-pointer w-[20px]"
                />
                <span className="absolute bottom-1.5 right-0 z-10 text-superbill-ultra-red-hover h-fit w-fit text-xs bg-[#ffffffaa]">N</span>
              </div>
            </Tooltip>
          </div>

        </div>
      ))}
      <div className="flex items-center justify-end flex-row py-3 px-2 font-sans">
        <div
          className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
          onClick={() => changePagination(PaginationStates.backward)}
        >
          {currentLowerIndex === 0 ? <></> : <BackIcon />}
        </div>
        <div className="mx-2">
          <strong>
            {currentLowerIndex + 1} to {currentUpperIndex + 1}
          </strong>{" "}
          of {calls?.length}
        </div>
        <div
          className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
          onClick={() => changePagination(PaginationStates.forward)}
        >
          {currentUpperIndex + 1 === calls?.length ? <></> : <ForwardIcon />}
        </div>
      </div>
    </div>
  );
}
