import React from "react";
import Modal from "react-modal";
import { RunScriptModalStates } from "enums/enums";
import UploadCSVPage from "./upload-csv-page";
import { RunScriptErrosType, Script } from "types/types";
import SelectionPage from "./selection-page";
import ErrorsPage from "./error-page";
import HeadersPage from "./headers-page";
import { useNavigate } from "react-router-dom";
import SuccessPage from "./success-page";
// custom styles
const customStyles = {
  content: {
    height: "max(70%,550px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

// components
type RunScriptModalProps = {
  modalState?: RunScriptModalStates;
  scriptData: Array<Script> | null;
  isOpen: boolean;
  onClose: () => void ;
  selectedScriptId: string;
  maxRows: number;
  onSuccess?: () => void ;
}
export default function RunScriptModal({ modalState = RunScriptModalStates.UploadCsv,
  isOpen,
  onClose,
  maxRows,
  selectedScriptId,
  onSuccess,
  scriptData }: RunScriptModalProps) {
  //const [modalIsOpen, setModalIsOpen] = React.useState(true);
  const navigate = useNavigate();
  const [currentState, setCurrentState] = React.useState<RunScriptModalStates>(modalState);

  const [selectedScript, setSelectedScript] = React.useState<string | null>(null);
  const [selectedScriptInput, setSelectedScriptInput] = React.useState<string | null>(null);
  const [csvFile, setCsvFile] = React.useState<any>(null);
  const [uploadErrors, setUploadErrors] = React.useState<RunScriptErrosType | null>(null);
  const [csvString, setCsvString] = React.useState<string>('');
  const [inputs, setInputs] = React.useState({});

  const toggleModal = () => {
    setCurrentState(RunScriptModalStates.UploadCsv)
    setSelectedScript(null)
    setSelectedScriptInput(null)
    setCsvFile(null)
    onClose()
  };
  
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Run Script Modal"
      >
        {currentState === RunScriptModalStates.ScriptSelection ? (
          <SelectionPage scriptData={scriptData} onClose={toggleModal} selectedScript={selectedScript} setSelectedScript={setSelectedScript} onNext={() => {
            if (selectedScriptInput === 'table' ) {
              navigate(`/super-dial-create/${selectedScriptId}`);
              return;
            }
           if (selectedScriptId) {
              setCurrentState(RunScriptModalStates.UploadCsv)
           }
         }} selectedScriptInput={selectedScriptInput} setSelectedScriptInput={setSelectedScriptInput}/>
        ) : null}
        {currentState === RunScriptModalStates.UploadCsv ? (
          <UploadCSVPage 
            inputs={inputs}
            maxRows={maxRows}
            setInputs={setInputs}
            csvString={csvString}
            setCsvString={setCsvString}
            onNext={() => {
            if (selectedScriptId) {
               setCurrentState(RunScriptModalStates.MatchHeaders)
            }
          }} selectedScript={selectedScriptId} selectedScriptType={scriptData?.find((e) => e.id === selectedScriptId)?.type!} onClose={toggleModal} csvFile={csvFile} setCsvFile={setCsvFile}
          onSuccess={() => {
            setCurrentState(RunScriptModalStates.Success)
          }}
          onError={(errors) => {
            setUploadErrors(errors)
            setCurrentState(RunScriptModalStates.Error)
          }}
          />
        ) : null}
        {currentState === RunScriptModalStates.MatchHeaders ? (
          <HeadersPage inputs={inputs} csvString={csvString} csvFile={csvFile} setCsvFile={setCsvFile} onClose={toggleModal}
          selectedScript={selectedScriptId}
          onSuccess={() => {
            setCurrentState(RunScriptModalStates.Success)
          }}
          onError={(errors) => {
            setUploadErrors(errors)
            setCurrentState(RunScriptModalStates.Error)
          }}
          />
        ) : null}
        {currentState === RunScriptModalStates.Success ? (
          <SuccessPage onClose={toggleModal} onSuccess={() => {
            if (onSuccess) {
              onSuccess()
            }
            toggleModal()
          }}/>
        ) : null}
        {currentState === RunScriptModalStates.Error ? (
          <ErrorsPage errors={uploadErrors!} onClose={toggleModal} onBack={() => {
            setCsvFile(null)
            setUploadErrors(null)
            setCurrentState(RunScriptModalStates.UploadCsv)
          }}/>
        ) : null}
      </Modal>
    </div>
  );
};
