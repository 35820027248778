import PageTitle from '../style-components/PageTitle';
import AutomationFeatures from './automation-features';
import SignInForm from './sign-in/sign-in-form';


export default function ScriptBuilderSignin({ agent = false }: { agent?: boolean }) {




    return (
      <div className="bg-superdial-background-full bg-cover h-full text-superbill-jacarta">
        <div className="w-full flex justify-center items-center h-screen" >
        <div className=''>
          <div className="w-full text-white text-center font-bold mb-4 font-jakarta">
          <a href="https://www.thesuperbill.com">
            SuperBill
          </a>
          </div>

          <div className="w-full justify-center p-16 rounded bg-white">
            <div className="font-jakarta text-2xl font-bold text-superbill-jacarta">
              Welcome Back to SuperDial
            </div>
            <div className="font-jakarta text-superbill-slate-grey text-sm">
              Automated outbound healthcare phone calls
            </div>
            <SignInForm agent={agent}/>
          </div>
          <div className="w-full justify-center text-center text-white font-jakarta">
            <div className="text-superbill-soap mb-4 mt-6 text-sm ">
              Want to get started with SuperDial?
            </div>
            <div>
            <a href='https://calendly.com/sam-schwager/outbound-call-automation' className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/30 text-white font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300">Schedule Consultation</a>
            </div>
          </div>
          </div>
        </div>
      </div>
    )
  }
