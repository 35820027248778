import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as FollowUpIcon } from 'assets/icon_followUp.svg';
import { ReactComponent as BackgroundIcon } from 'assets/icon_backgroundInfo.svg';
import { ReactComponent as ResponseIcon } from 'assets/icon_responseInstructions.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icon_duplicate.svg';
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg';


export interface QuestionMenuProps {
  canAddSubQuestions?: boolean;
  duplicate: () => void;
  deleteQ: () => void;
  addSubQuestion?: () => void;
  showAddSubQuestions?: boolean;
  addBackgroundContext: () => void;
  addResponseInstruction: () => void;
}

export default function QuestionMenu({ canAddSubQuestions, duplicate, addSubQuestion, deleteQ, showAddSubQuestions = true, addBackgroundContext, addResponseInstruction }: QuestionMenuProps) {
  return (
    <Menu as='div' className='relative inline-block text-left '>
      <div>
        <Menu.Button className='flex items-center rounded-full hover:bg-superbill-anti-flash-white rounded py-2 px-3 ease-in-out duration-200'>
          <span className='sr-only'>Open options</span>
          <svg
            width='5'
            height='19'
            viewBox='0 0 5 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M2.625 14.0741C3.93549 14.0741 5 15.178 5 16.537C5 17.8961 3.93549 19 2.625 19C1.31451 19 0.25 17.8961 0.25 16.537C0.25 15.178 1.31451 14.0741 2.625 14.0741ZM2.625 7.03704C3.93549 7.03704 5 8.14097 5 9.5C5 10.859 3.93549 11.963 2.625 11.963C1.31451 11.963 0.25 10.859 0.25 9.5C0.25 8.14097 1.31451 7.03704 2.625 7.03704ZM5 2.46296C5 3.82199 3.93549 4.92593 2.625 4.92593C1.31451 4.92593 0.25 3.82199 0.25 2.46296C0.25 1.10394 1.31451 0 2.625 0C3.93549 0 5 1.10394 5 2.46296Z'
              fill='#727272'
            />
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'>
         <Menu.Items className='absolute right-0 mt-2 w-60 origin-top-right rounded bg-white shadow-lg focus:outline-none z-20'>
          <div className='font-normal border border-superbill-soap border-1 rounded z-20'>
            {showAddSubQuestions ? 
              <Menu.Item disabled={!canAddSubQuestions}>
                <div
                  className={`text-superbill-jacarta flex block w-full px-4 py-2 text-left flex items-center ease-in-out duration-150 ${canAddSubQuestions ? 'cursor-pointer hover:bg-superbill-ghost-white hover:text-superbill-indigo' : ' text-superbill-slate-grey rounded-t'}`}
                  onClick={addSubQuestion}>
                    <FollowUpIcon className={`w-[20px] ${canAddSubQuestions ? 'fill-superbill-jacarta' : 'fill-superbill-slate-grey'}`} />
                  <div className='mx-2 text-base'>Add Follow-Up Subquestions</div>
                </div>
              </Menu.Item>
            : null}
              <Menu.Item >
                <div
                  className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-jacarta flex cursor-pointer block w-full px-4 py-2 text-left flex items-center ease-in-out duration-150'
                  onClick={addBackgroundContext}>
                    <BackgroundIcon className="w-[20px] fill-superbill-jacarta" />
                  <div className='mx-2 text-base'>Provide Background Info</div>
                </div>
              </Menu.Item>
              <Menu.Item >
                <div
                  className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-jacarta flex cursor-pointer block w-full px-4 py-2 text-left flex items-center ease-in-out duration-150'
                  onClick={addResponseInstruction}>
                    <ResponseIcon className="w-[20px] fill-superbill-jacarta" />
                  <div className='mx-2 text-base'>Add Response Instructions</div>
                </div>
              </Menu.Item>
              <div className='border-b border-superbill-soap'></div>
              {showAddSubQuestions &&
                <Menu.Item>
                <div
                  className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-jacarta flex cursor-pointer block w-full px-4 py-2 text-left flex items-center ease-in-out duration-150'
                  onClick={duplicate}>
                    <DuplicateIcon className="w-[20px]" />
                  <div className='mx-2 text-base'>Duplicate</div>
                </div>
              </Menu.Item>
              
              }



              <Menu.Item>
                <div
                  className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-jacarta flex cursor-pointer block w-full px-4 py-2 text-left flex items-center ease-in-out duration-150'
                  onClick={deleteQ}
                  >
                    <DeleteIcon className="w-[20px]" />
                  <div className='mx-2 text-base'>Delete</div>
                </div>
              </Menu.Item>

          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
