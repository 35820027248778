import React, { useEffect, useRef, useState } from "react";
import Loader from 'components/Loader';
import Select from "react-select";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { createScript, getScriptInputs, getScriptsTypes } from "../../apis/robodialer";
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";
import { ReactSelectOptions } from "types/types";
import { Questions } from "../../script-builder/create-script/QuestionType";
import QuestionPage from "../../script-builder/create-script/Question";

// custom styles
const customStyles = {
  content: {
    height: "max(70%,550px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  modalIsOpen: boolean;
  setSelectedScript: React.Dispatch<React.SetStateAction<number | null>>;
  n_questions?:Array<Questions> | null
}


export default function ShowQuestionsModal({ modalIsOpen, setSelectedScript, n_questions }: Props) {
  const ref = useRef<HTMLFormElement>(null);
  const [questions, setQuestions] = useState<Array<Questions> | null>(null);

  useEffect(() => {
    if (n_questions) {
      setQuestions(n_questions);
    }
  }, [n_questions]);

  const toggleModal = () => {
    setSelectedScript(null);
    setQuestions(null)
  };

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Questions
          </nav>
          <div className="flex-grow overflow-y-auto p-9 !z-50">
            <QuestionPage onSuccess={() => {}} questions={questions!} setQuestions={setQuestions} ref={ref} scriptData={null} showPmsForm={false} externalInstructions={''} setExternalInstructions={(e) => {}}/>
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={() => { setSelectedScript(null) }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
