import React, { useState, useEffect } from 'react';

import { useForm, Controller, useWatch, UseFormRegister } from 'react-hook-form';
import Select, { components, OptionProps, SingleValue } from "react-select";
import { Script } from 'types/types';
import { getScript } from '../../apis/robodialer';
import { Questions } from '../create-script/QuestionType';

interface FormData {
  script: { value: string; label: string; };
  phone: string
  acceptTerms: boolean
  
}



type SelectionPageProps = {
  onClose?: () => void;
  onNext?: () => void;
  scriptData: Array<Script> | null;
  register: UseFormRegister<any>;
  control: any
  loading: boolean;
  success: boolean
  watch: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const SelectionPage = ({ onClose, onNext, scriptData, register, control, watch, loading, setLoading, success = false }: SelectionPageProps) => {

  //const [loading, setLoading] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Array<Questions> | null>(null);
  const watchedFieldValue = useWatch({ control: control, name: 'script' });


  useEffect(() => {

    const fetchScripts = async () => {
      console.log(watchedFieldValue);

      if (watchedFieldValue) {
        setLoading(true)
        const { data, httpCode } = await getScript(watchedFieldValue.value);
        setLoading(false)
        if (httpCode !== 200) {
          return;
        }
        if (data?.questions && 0 < data?.questions?.length) {
          setQuestions(data?.questions!.filter((e) => !e.hidden) as Array<Questions>);
          console.log(data?.questions!);

        }
      }

    }
    fetchScripts()
  }, [watchedFieldValue]);

  console.log('scriptData', scriptData);
  
  const selectOptions = scriptData
    ? scriptData?.map((script) => ({ value: script.id, label: script.name }))
    : [];

  const showFollowUp = (question, nested = 0, key = '') => {

    return (
      <>
        {question?.followUps && (question?.followUps?.No || question?.followUps?.Yes) ?
          <div className={"p-2"}>
            <div className="mb-2 flex items-center">
              <span className=" text-superbill-jacarta font-sans text-sm">If YES</span>
            </div>
            {question?.followUps?.Yes?.map((subquestion, subIndex) => (
              <div className='pl-4' key={`${key}.followUps.Yes.${subIndex}.input`}>
                {typeof subquestion?.question !== 'undefined' ?
                  <>
                    <div className='text-superbill-jacarta text-sm flex items-center gap-x-1'><span className='text-xs'>●</span> {subquestion?.question}</div>
                    <div className="flex-grow h-auto rounded-r">
                      <div key={`${key}.Yes.${subIndex}`} className="flex flex-col">
                        <div className='ml-5'>
                          {showFollowUp(subquestion, nested + 1, `${key}.Yes.${subIndex}`)}
                        </div>
                      </div>
                    </div>
                  </>
                  : null}
              </div>
            ))}
          </div >
          : null
        }
        {
          question?.followUps && (question?.followUps?.No || question?.followUps?.Yes) ?
            <div className={"p-2"}>
              <div className="mb-2 flex items-center">
                <span className=" text-superbill-jacarta font-sans text-sm">If NO</span>
              </div>
              {question?.followUps?.No?.map((subquestion, subIndex) => (
                <div className='pl-4' key={`${key}.followUps.No.${subIndex}.input`}>
                  <div
                    key={`${key}.No.${subIndex}`}
                  >
                    <div className="flex-grow h-auto rounded-r">
                      <div key={`${key}.No.${subIndex}`} className="flex flex-col">
                        <div className='text-superbill-jacarta text-sm flex items-center gap-x-1'><span className='text-xs'>●</span> {subquestion?.question}</div>
                        <div className='ml-5'>
                          {showFollowUp(subquestion, nested + 1, `${key}.No.${subIndex}`)}
                          {/* {showFollowUp(subquestion, true, key + index + subIndex + '-Yes')} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            : null
        }
        {
          question?.followUps?.multiple ?
            <div className="p-2 ml-3 ">
              <div className="mb-2 flex items-center">
                <span className=" text-superbill-jacarta font-sans text-sm">Multiple choice options</span>
              </div>
              <div className='w-full flex flex-col items-start align-middle gap-y-2'>
                {question?.followUps?.multiple?.map((option, index) => (
                  <div className='flex gap-x-4 w-full items-center' key={`${key}.followUps.multiple.${index}.input`}>
                    <div className='text-superbill-jacarta text-sm'>{option}</div>
                  </div>

                ))}
              </div>
            </div>
            : null
        }
      </>
    )
  }
  return (
    <div className="flex flex-wrap h-[500px]">
      <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 h-full border border-superbill-soap">
        <div className="p-4 !h-full bg-white">
         {!success ? 
          <>
            <div className='mt-4 mb-2 text-superbill-jacarta text-sm'>Enter your phone number, select a script, and SuperDial will call you with the select scenario.</div>
            <form className=' flex flex-col items-center gap-y-3'>
              <div className='w-full'>
                <label className='text-sm text-superbill-jacarta font-semibold font-jakarta' htmlFor="script">Select the script you’d like to run</label>
                <Controller
                  control={control}
                  name="script"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select a script"
                      className='bg-white border-1 border-superbill-soap block w-full focus:ring-superbill-indigo focus:border-superbill-indigo sm:text-sm rounded placeholder:text-sm text-superbill-jacarta'
                      classNamePrefix="react-select"
                      isSearchable={false}
                      options={selectOptions}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          '&:hover': { borderColor: '#D0D3F2' },
                          boxShadow: state.isFocused ? '0px 0px 0px 3px #E4E8FD' : 'none',
                          borderColor: state.isFocused ? '#4a43cd' : '#D0D3F2',
                          zIndex: 11
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: state.isFocused ? '#f5f6fb' : '#ffffff',
                          color: state.isFocused ? '#4a43cd' : '#38395B',
                          ':active': {
                            ...baseStyles[':active'],
                            backgroundColor: '#D0D3F2',
                          },
                        })
                      }}
                    />
                  )}
                />
              </div>
              <div className='w-full'>
                <label className='text-sm text-superbill-jacarta font-semibold font-jakarta' htmlFor="phone">Enter your phone number</label>
                <input
                  type="text"
                  placeholder="+1"
                  className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
                  {...register(`phone`, { required: "This field is required" })}
                />
              </div>
              <div className="flex justify-between items-center flex-col md:flex-row">
                <div>
                  <label htmlFor="acceptTerms" className="flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      id="acceptTerms"
                      {...register('acceptTerms', { required: true })}
                      className='mr-2 cursor-pointer checked:bg-superbill-ultramarine rounded-sm'
                    />
                    <span className="text-superbill-jacarta text-xs">
                      I certify that this is my own number and I agree to the SuperBill Terms of Use and Privacy Notice. Data rates may apply.
                    </span>
                  </label>
                </div>
              </div>
            </form>
          </>
         : <div className='!h-full flex items-center justify-center flex-col'>
            <div className='text-center mt-4 mb-2 text-superbill-jacarta text-sm'>Great, you’ll receive a call soon at {watch('phone')}</div>
            <div className='text-center mt-4 my-2 font-semibold font-jakarta text-superbill-jacarta text-lg'>{(scriptData?.find(obj => obj.id === watch('script').value))?.type ?? ''}</div>
            <div className='text-center mt-4 mb-2 text-superbill-jacarta text-sm'>Feel free to ask clarifying questions during the call.</div>
            <div className='text-center mt-4 mb-2 text-superbill-jacarta text-sm'>You can end the call by hanging up at any time.</div>
            <div className='text-center mt-4 mb-2 text-superbill-slate-grey text-sm'>Questions? Reach out to support@thesuperbill.com</div>
         </div>}
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 h-full border border-superbill-soap bg-superbill-lavender-light">
        <div className="p-4 !h-full overflow-auto">
          {questions ?
            <>
              <div className='mt-4 font-bold text-superbill-jacarta text-xs mb-5'><strong>You’ll play the role of the payer</strong> , and we’ll be calling about these questions:</div>
              {questions?.map((question, index) =>
                <div key={index}>
                  {typeof question?.question !== 'undefined' ?
                    <>
                      <div className='text-superbill-jacarta text-sm'>{index + 1}. {question.question}</div>
                      {showFollowUp(question, 0, 'main.' + index)}
                    </>
                    : null}
                </div>
              )}
            </>
            : null}
        </div>
      </div>
    </div>
  );
};

export default SelectionPage;
