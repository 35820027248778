import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as Dropdown } from "../assets/angle-down.svg";


export interface QuestionMenuProps {
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
}

const OPT_MAP = {
  '7days': 'Last 7 days',
  '30days': 'Last 30 days',
  'month': 'This month',
  'all': 'All Time',
}

export default function CallsOVerviewOptions({ setSelectedOption, selectedOption }: QuestionMenuProps) {

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const handleCustomRangeClick = () => {
    setDatePickerOpen(true);
    setStartDate('');
    setEndDate('');
  };
  return (
    <Menu as='div' className='relative inline-block text-left z-999 min-w-[21px]'>
      <div>
        <Menu.Button className='flex items-center rounded hover:bg-superbill-anti-flash-white hover:text-superbill-indigo py-2 px-2 ease-in-out duration-150'>
          <span className='sr-only'>Open options</span>
          {OPT_MAP[selectedOption] ?? selectedOption}
          <Dropdown className="ml-2"/>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'>
        <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right rounded bg-white shadow-lg focus:outline-none z-50'>
          <div className='font-normal border border-superbill-soap border-1 rounded z-50 overflow-hidden'>
            <Menu.Item>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150' onClick={() => { setDatePickerOpen(false); setSelectedOption('7days') }}>
                <div className='mr-2 text-base'>Last 7 days</div>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150' onClick={() => { setDatePickerOpen(false); setSelectedOption('30days') }}>
                <div className='mr-2 text-base'>Last 30 days</div>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150' onClick={() => { setDatePickerOpen(false); setSelectedOption('month') }}>
                <div className='mr-2 text-base'>This month</div>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150' onClick={() => { setDatePickerOpen(false); setSelectedOption('all') }}>
                <div className='mr-2 text-base'>All Time</div>
              </div>
            </Menu.Item>
            <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150' onClick={() => { handleCustomRangeClick() }}>
              <div className='mr-2 text-base'>Custom Range</div>
            </div>
            {datePickerOpen && (
              <div className="p-4 space-y-2">
                <input
                    type="date"
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
                  />
                  <input
                    type="date"
                    placeholder="End Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
                  />
                <Menu.Item>
                  <button
                    onClick={() => {
                      setDatePickerOpen(false);
                      setSelectedOption(startDate+' | ' + endDate)
                    }}
                    className="w-full bg-superbill-indigo text-white py-2 rounded hover:bg-superbill-indigo-dark ease-in duration-150"
                  >
                    Apply
                  </button>
                </Menu.Item>
              </div>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}