import React, { useState } from "react";
import { paginationHelper, PaginationStates } from "../../../utils/pagination";
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";

interface Props {
  data: Record<string, string | number>;
  icon?: React.ReactNode;
  action?: (value: string | number, key?: string | number) => void;
  aspect?: string;
}

const KeyValueGrid: React.FC<Props> = ({
  data,
  icon,
  action,
  aspect = "5-5",
}) => {
  const [firstPercentage, secondPercentage] = aspect.split("-").map(Number);
  const [currentResultsPageIndex, setCurrentResultsPageIndex] =
  useState<number>(0);
  const dataArr = Object.entries(data).map(([key, value]) => ({
    key,
    value,
  }))
const {
  newEntries: currentActiveResults,
  currentLowerIndex,
  currentUpperIndex,
  changePagination,
} = paginationHelper({
  entries: dataArr ?? [],
  currentPageIndex: currentResultsPageIndex,
  maxEntriesPerPage: 30,
  setCurrentPageIndex: setCurrentResultsPageIndex,
});
  return (
    <>
      {currentActiveResults.map(({key, value}) => (
        <div
          className="grid grid-cols-10 bg-white px-2 py-1 gap-1"
          key={`${key}-${key}`}
        >
          <React.Fragment key={key}>
            <div
              className={`${
                firstPercentage === 5
                  ? "col-span-5"
                  : `col-span-${firstPercentage}`
              } text-xs text-superbill-jacarta font-jacarta font-bold text-left overflow-auto`}
            >
              {key}:
            </div>
            <div
              className={`${
                secondPercentage === 5
                  ? "col-span-5"
                  : `col-span-${secondPercentage}`
              } flex justify-between items-center text-right overflow-auto gap-x-1`}
            >
              <div className="text-xs text-superbill-jacarta font-jacarta w-full">
                {typeof value !== "boolean" ? value : value ? "Yes" : "No"}
              </div>
              {icon && action && (
                <span className="flex items-center justify-end" onClick={() => action(value, key)}>{icon}</span>
              )}
            </div>
          </React.Fragment>
        </div>
      ))}
      <div className="flex items-center justify-end flex-row py-3 px-2 font-sans">
        <div
          className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
          onClick={() => changePagination(PaginationStates.backward)}
        >
          {currentLowerIndex === 0 ? <></> : <BackIcon />}
        </div>
        <div className="mx-2">
          <strong>
            {currentLowerIndex + 1} to {currentUpperIndex + 1}
          </strong>{" "}
          of {dataArr?.length}
        </div>
        <div
          className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
          onClick={() => changePagination(PaginationStates.forward)}
        >
          {currentUpperIndex + 1 === dataArr?.length ? <></> : <ForwardIcon />}
        </div>
      </div>
    </>
  );
};

export default KeyValueGrid;
