import React from 'react';
import { UseFormRegister } from "react-hook-form";
import { sections } from './pms-data';


type PMSFormProps = {
  register: UseFormRegister<any>;
  fieldValues: any;
}
const PMSForm = ({ register, fieldValues }: PMSFormProps) => {
  const [selectedBenefit, setSelectedBenefit] = React.useState(fieldValues.benefits);
  const [selectedValues, setSelectedValues] = React.useState(fieldValues);

  const handleCheckboxChange = (sectionTitle, fieldName, isChecked) => {
    setSelectedValues({
      ...selectedValues,
      [sectionTitle]: {
        ...selectedValues[sectionTitle],
        [fieldName]: isChecked,
      },
    });
  };

  const handleBenefitChange = (e) => {
    setSelectedBenefit(e.target.value);
  };

  return (
    <div>
      <form>
        {sections.map((section) => (
          <div key={section.title}>
            {section.title === 'benefits' ? (
              <>
                <div className='font-bold font-jakarta text-superpay-jacarta text-lg my-5'>I want to verify benefits for...</div>
                <div className='grid grid-cols-3 gap-x-4 gap-y-3 rounded bg-superbill-anti-flash-white p-4'>
                  {section.fields[0].options?.map((option) => (
                      <label className='w-full text-superpay-jacarta text-sm cursor-pointer px-4 py-2 bg-white rounded' key={option}>
                         <input
                          type={section.fields[0].type}
                          placeholder="Enter a question"
                          value={option}
                          className='mr-2 checked:bg-superbill-ultramarine rounded-sm'
                          checked={option === selectedBenefit}
                          {...register(section.fields[0].name, { required: true })}
                          onChange={handleBenefitChange}
                        />
                        {option}
                      </label>
                  ))}
                </div>
                <div className='my-4 border-b border-superbill-soap'/>
                <div className='font-bold font-jakarta text-superpay-jacarta text-lg my-5'>Select the information you’d like to know</div>
              </>
            ) :
              <>
                <div className='font-bold font-jakarta text-superpay-jacarta text-sm capitalize mb-1'>{section.title}</div>
                <div className='grid grid-cols-3 gap-x-4 gap-y-3 rounded bg-superbill-anti-flash-white p-4 mb-4'>
                  {section.fields.map((field) => (
                      <label className='w-full font-sans text-superpay-jacarta text-sm cursor-pointer px-4 py-2 bg-white rounded' key={`${section.title}.${field.name}`}>
                        <input type={field.type} className='mr-2 checked:bg-superbill-ultramarine rounded-sm'
                        checked={selectedValues[section.title] && selectedValues[section.title][field.name]}
                        {...register(`${section.title}.${field.name}`, { required: false })}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckboxChange(section.title, field.name, isChecked);
                        }}
                        />
                        {field.label}
                      </label>
                  ))}
                </div>
              </>
            }
          </div>
        ))}
      </form>
    </div>
  );
};

export default PMSForm;
