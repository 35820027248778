import React, { useEffect, useState } from "react";
import Loader from 'components/Loader';
import Select from "react-select";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";
import { updateScriptDoc, getScriptInputs } from "apis/robodialer";
import { Script } from "types/types";


// custom styles
const customStyles = {
  content: {
    height: "max(40%,400px)",
    maxWidth: "max(50%,600px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  refresh: () => void;
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedScript: string | null;
  scriptData: Script | undefined;
  setSelectedScript: React.Dispatch<React.SetStateAction<string | null>>;
  
}

interface FormValues {
  name: string;
}
// components
export default function DownloadInputsModal({ modalIsOpen, setModalIsOpen, scriptData, setSelectedScript, selectedScript, refresh }: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [inputs, setInputs] = useState({});

  const generateCSV = (e) => {
    const fileName = scriptData?.name;
    if (e && e.preventDefault) {
      e?.preventDefault()
    }
    const headers = Object.keys(inputs);
    const dummyData = headers.map(() => "exampleCsv");

    const csvContent = `${headers.join(",")}\n${dummyData.join(",")}`;

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}_inputs.csv`);
    link.click();
  };

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
    setSelectedScript(null);
  };
  useEffect(() => {
    const fetch = async () => {
      const { data } = await getScriptInputs(scriptData?.type?.replaceAll(' ', '-').toLocaleLowerCase())
      setInputs(data);
    }
    if(scriptData && scriptData?.type){
      fetch()
    }

  }, [scriptData?.type])

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Download Inputs Template
          </nav>
          <div className="flex-grow p-9 text-superbill-jacarta">
            <div>Use this template CSV file as an example of how to provide inputs for your calls. Note that we list the inputs in alphabetical order, but you may use whatever order you'd like. </div>
            <div className='relative'>
            <div className=' w-full my-1 mt-3 rounded border border-superbill-soap divide-y divide-superbill-soap font-sans overflow-hidden'>

            <div className={`grid grid-cols-8 gap-4 p-2 bg-superbill-anti-flash-white content-center items-center hover:bg-superbill-lavender/50 ease-in-out duration-150`}>
            {Object.keys(inputs).slice(0, 4).map((key) => (
            <div key={key} className='col-span-2 text-truncate text-superbill-slate-grey text-base font-semibold break-words'>
                {key}
            </div>
            ))}
            </div>
            <div className='grid grid-cols-8 gap-4 p-2 content-center items-center hover:bg-superbill-lavender/50 ease-in-out duration-150' >
              <div className='col-span-2 text-superbill-slate-grey text-base'>example</div>
              <div className='col-span-2 text-superbill-slate-grey text-base'>example</div>
              <div className='col-span-2 text-superbill-slate-grey text-base'>example</div>
              <div className='col-span-2 text-superbill-slate-grey text-base'>example</div>

            </div>
          </div>
          <div className='m-0 absolute bottom-[-10px] w-full bg-gradient-to-t from-white to-transparent flex justify-center'>
                <button type='button' className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 mt-4" onClick={generateCSV}>
                Download template
              </button>
                </div>
          </div>

          </div>
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={() => { setModalIsOpen(false)}}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
