import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useNotification } from "@telnyx/react-client";
import toast from 'react-hot-toast';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import SidekickCallsContentToolbar from './sidekick-calls-content-toolbar';
import { useOutletContext } from 'react-router-dom';
import { discardCall, getNextCallInQueueAndAssign, getScript, initiateCall, submitCall } from 'apis/agent';
import KeyValueGrid from './components/key-value-grid';
import { AssignNextCallInQueueResponse } from 'types/types';
import ScriptOverview from './components/script-overview';
import ScriptForm from './components/script-form';
import SubmitFormComponent from './components/submit-form';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../routes/authProvider';
import { handleTelnyxEvent } from './components/telnyx-logger';


interface Option {
  value: boolean | string;
  other_reason?: string; //for CANNOT ANSWER - other
  label: string;
}

type SidekickCallsContentProps = {
  agentEmail: string
  agentSip: string
}

export default function SidekickCallsContent({ startTime, setIssueSubmitted, initWebCallData, setInitWebCallData,
  startOfHoldTime, setstartOfHoldTime,
  startOfIVRTime, setStartOfIVRTime,
  startOfHumanConversation, setStartOfHumanConversation,
}) {
  const { data } = useAuth();
  const appV = 19
  const { agentEmail, agentSip } = useOutletContext<SidekickCallsContentProps>()
  const [callData, setCallData] = useState<null | AssignNextCallInQueueResponse>(null);
  const [scriptData, setScriptData] = useState<null | any>(null); //type TBD
  const [initiateCallIsLoading, setInitiateCallIsLoading] = useState<boolean>(false)
  const [btnsDisabled, setBtnsDisabled] = useState<boolean>(false)
  const [callInitiated, setCallInitiated] = useState<boolean>(false)
  const [isFirstCallSubmitted, setIsFirstCallSubmitted] = useState<boolean>(false)
  const [callCount , setCallCount] = useState<number>(0)
  const { register, handleSubmit, setValue, formState: { errors }, watch } = useForm();

  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: Option | null }>({});

  const getNextCall = async () => {
    setIssueSubmitted(false)
    const response = await getNextCallInQueueAndAssign({
      retoolUser: {
        email: agentEmail,
        dummy: (agentEmail.includes("thesuperbill.com") || agentEmail.includes("superdial.ai"))
      },
      appV,
      dummy: (agentEmail.includes("thesuperbill.com") || agentEmail.includes("superdial.ai"))
    })
    handleTelnyxEvent('userAction: getNextCall', {
      custom:true,
      date: (new Date()).toISOString(),
      payload: {
        retoolUser: {
          email: agentEmail,
          dummy: (agentEmail.includes("thesuperbill.com") || agentEmail.includes("superdial.ai"))
        },
        appV,
        dummy: (agentEmail.includes("thesuperbill.com") || agentEmail.includes("superdial.ai"))
      },
    }, data?.user?.email)
    
    setstartOfHoldTime(null)
    setStartOfIVRTime(null)
    setStartOfHumanConversation(null)
  
    if (response.httpCode === 200) {
      setCallInitiated(false)
      setSelectedOptions({})
      setValue('edit', false)
      setValue('phoneNumber', "")
      setCallData(response.data)
    } else {
      toast.error(response.httpCode + ' Unable to get call, Try again: ' + (response?.data?.error ?? response?.msg ?? 'Server error, contact support'), {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }

  }

  const onCopy = (content: string | number) => {
    navigator.clipboard.writeText(content.toString());
    toast.success('Copied to clipboard', {
      iconTheme: {
        primary: '#4a43cd',
        secondary: '#ffffff',
      },
    }
    );
  }

  const fetchScript = async () => {
    const response = await getScript(callData?._robodialer_id!, callData?.scriptId!)
    handleTelnyxEvent('userAction: fetchScript', {
      custom:true,
      date: (new Date()).toISOString(),
      payload: {
        robodialer_id: callData?._robodialer_id!, 
        scriptId: callData?.scriptId!
      },
    }, data?.user?.email)
    
    if (response.httpCode === 200) {
      setScriptData(response.data)
    } else {
      toast.error(response.httpCode + ' Unable to load Script! Try again:' + response.msg, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  }

  const discard = async () => {
    const response = await discardCall(callData?._robodialer_id, callData?._call_id)
    handleTelnyxEvent('userAction: discardCall', {
      custom:true,
      date: (new Date()).toISOString(),
      payload: {
        robodialer_id: callData?._robodialer_id!, 
        scriptId: callData?.scriptId!
      },
      response
    }, data?.user?.email)
    setstartOfHoldTime(null)
    setStartOfIVRTime(null)
    setStartOfHumanConversation(null)
    
    if (response.httpCode === 200) {
      setCallData(null)
      setScriptData(null)
      setSelectedOptions({})
      setValue('edit', false)
      setValue('phoneNumber', "")
    } else {
      toast.error(response.httpCode + ' Unable to load Script! Try again:' + response.msg, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  }
  useEffect(() => {
    if (callData && callData?._robodialer_id && callData?.scriptId) {
      fetchScript()
    }
    if (callData && callData?.num_bundled_calls && 0 < callData?.num_bundled_calls) {
      setCallCount(prev =>  prev+1)
    }else{
      setCallCount(0)
    }
  }, [callData])

  const selectedOptionsValuesOnly = Object.fromEntries(
    Object.entries(selectedOptions).map(([key, value]) => {
      if (value?.value === "CANNOT ANSWER - other") {
        if (value.other_reason && value.other_reason.trim() !== '') {
          return [key, `CANNOT ANSWER - ${value.other_reason}`];
        } else {
          return [key, ''];
        }
      }
      return [key, value?.value ?? value ?? ''];
    })
  );

  function debugSelectedOptions(selectedOptionsValuesOnly) {
    const problematicValues = Object.entries(selectedOptionsValuesOnly).filter(([key, value]) => 
        value === '' || value === null || value === undefined
    );

    if (problematicValues.length > 0) {
        console.log('Problematic values found:');
        problematicValues.forEach(([key, value]) => {
            if (value === "") {
                console.log(`Key: ${key} has an empty value.`);
            } else if (value === null) {
                console.log(`Key: ${key} has a null value.`);
            } else if (value === undefined) {
                console.log(`Key: ${key} has an undefined value.`);
            }
        });
    }
}

  const submitCallForm = async (data) => {
    debugSelectedOptions(selectedOptionsValuesOnly);
    if (data['outcome'].toLocaleLowerCase() === 'success' && !Object.values(selectedOptionsValuesOnly).every(value => value !== '' && value !== null && value !== undefined)) {
      toast.error('Invalid answers inputs', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return
    }

    let detailedReasonKey = ""
    let notesKey = ""
    switch (data['mainFailureReason']) {
      case 'input_missing':
        detailedReasonKey = "detailedMissingFailureReason"
        break;
      case 'input_incorrect':
        detailedReasonKey = "detailedIncorrectFailureReason"
        break;
      case 'phone':
        detailedReasonKey = "detailedPhoneFailureReason"
        break;
      case 'other':
        detailedReasonKey = "detailedOtherFailureReason"
        break;
      default:
        break;
    }
    switch (data['callOutcome']) {
      case 'failure':
        notesKey = 'failNotes'
        break;

      default:
        notesKey = 'callRetryReason'
        break;
    }

    const parsedData = {
      ...{
        "scriptVersionUsed": scriptData?.scriptVersion,
        "n_attempt": initWebCallData?.n_attempt,
        "retoolUser": {
          "email": agentEmail,
        },
        "callResults": selectedOptionsValuesOnly,
        "combinedReason": [
          data['mainFailureReason'], data['detailedFailureReason']
        ],
        "internalNotes": ""
      },
      ...data
    }
    parsedData["phoneNumberValid"] = data["phoneNumberValid"] === 'true' ? true : false
    if (parsedData["callOutcome"]) {
      parsedData["callOutcome"] = (parsedData["callOutcome"].charAt(0).toUpperCase() + parsedData["callOutcome"].slice(1))
    }
    if (!parsedData["mainFailureReason"]) {
      parsedData["mainFailureReason"] = ''
    }
    if (!parsedData["detailedFailureReason"]) {
      parsedData["detailedFailureReason"] = ''
    }
    parsedData['lineConnected'] = data['lineConnected'] === 'true' ? true : false
    parsedData['humanReached'] = data['humanReached'] === 'true' ? true : false
    parsedData["outcome"] = parsedData["outcome"] === 'need_to_retry' ? 'Need To Retry' : (parsedData["outcome"].charAt(0).toUpperCase() + parsedData["outcome"].slice(1))
    parsedData[notesKey] = data['description']

    //!watch('callInitiatedAt') ||
    //!watch('callEndedAt') ||
    const now = DateTime.now();
    const formattedDate = now.toLocaleString({
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    });
    parsedData['callInitiatedAt'] = startTime ?? formattedDate;//data['callInitiatedAt'].toString()
    parsedData['callEndedAt'] = formattedDate;//data['callEndedAt'].toString()
    parsedData[detailedReasonKey] = data['detailedFailureReason']
    if (initWebCallData?.n_attempt && !isFirstCallSubmitted) {
      parsedData["n_attempt"] = initWebCallData?.n_attempt;
    }
    parsedData["startOfHoldTime"] = startOfHoldTime;
    parsedData["startOfIVRTime"] = startOfIVRTime;
    parsedData["startOfHumanConversation"] = startOfHumanConversation;
    parsedData["scriptVersionUsed"] = scriptData?.scriptVersion
    
    const response = await submitCall(callData?._robodialer_id, callData?._call_id, parsedData)
    setBtnsDisabled(false)
    setstartOfHoldTime(null)
    setStartOfIVRTime(null)
    setStartOfHumanConversation(null)
    setIsFirstCallSubmitted(true)
    
    handleTelnyxEvent('userAction: submitCallForm', {
      custom:true,
      date: (new Date()).toISOString(),
      payload: {
        robodialer_id: callData?._robodialer_id!, 
        call_id: callData?._call_id,
        data: parsedData
      },
      response
    }, data?.user?.email)

    if (response?.httpCode === 200) {
      toast.success('Call Submited!', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      }
      );
      setCallData(null)
      setScriptData(null)
    } else {
      toast.error(response?.httpCode + ' Unable to Submit! Try again', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  }

  const notification = useNotification();

  const call = notification?.call;
  const callState = call?.state;

  const formatString = (template: string): string => {
    return template.replace(/\{([^}]+)\}/g, (match, key) => {
      const value = callData?.inputs[key.trim()];
      return typeof value !== 'undefined' ? value : match;
    });
  }

  const initWebCall = async () => {
    if (watch('edit') && !regex.test(watch('phoneNumber'))) {
      toast.error('Invalid phoneNumber', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return
    }
    setInitiateCallIsLoading(true)
    const response = await initiateCall(callData?._robodialer_id, callData?._call_id, {
      sidekick: true,
      agent_sip: agentSip,
      to: watch('edit') ? watch('phoneNumber') : null
    })
    handleTelnyxEvent('userAction: initWebCall', {
      custom:true,
      date: (new Date()).toISOString(),
      payload: {
        robodialer_id: callData?._robodialer_id!, 
        call_id: callData?._call_id,
        data: {
          sidekick: true,
          agent_sip: agentSip,
          to: watch('edit') ? watch('phoneNumber') : null
        }
      },
      response
    }, data?.user?.email)
    //if(response.httpCode === 200){
    setCallInitiated(true)
    setBtnsDisabled(true)
    //}
    setstartOfHoldTime(null)
    setStartOfIVRTime(null)
    setStartOfHumanConversation(null)
    setIsFirstCallSubmitted(false)
    setInitWebCallData(response.data)
    setInitiateCallIsLoading(false)
    if (response.httpCode === 200) {
      toast.success('Call initiated! Please wait for us to connect your call, this may take up to 60 seconds', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      }
      );
    } else {
      toast.error(response?.httpCode + ' Unable to initiate call:' + (response?.data?.error ?? response?.data?.detail ?? response?.msg ?? 'Server error, contact support'), {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  }


  const regex = /^[0-9]+$/;
  return (
    <>
      <div className='mx-4'><strong className='text-xs text-superbill-jacarta'>appV: {appV}</strong></div>
      {callData?.num_bundled_calls && 0 < callData?.num_bundled_calls?
        <div className="gap-4 mt-4 mx-4">
          <div className='flex items-center justify-center gap-x-4 p-4 bg-superbill-lavender rounded'>
            <div className='p-4 bg-superbill-indigo rounded-lg'>
            <span className='flex items-center text-white gap-x-1'><strong className='text-white'>{callCount}</strong>/<span>{callData?.num_bundled_calls + 1}</span></span>
            </div>
            <div>
              <span className='text-sm font-jacarta text-superbill-jacarta'><strong>Call Bundling In Progress. Stay on the line for {callData?.num_bundled_calls + 1} calls.</strong> When you submit the results, you will <strong>continue talking to the same representative.</strong></span>
            </div>
          </div>
        </div>
      :null}
      {callInitiated ? 
        <div className="gap-4 mt-4 mx-4">
          <div className='flex items-center justify-center gap-x-4 p-4 bg-superbill-lavender rounded'>
          <div>
            <span className='text-sm font-jacarta text-superbill-jacarta'>You can only initiate the call <strong>once</strong>. If you experience any issues when initiating this call, please mark it as <strong>retry in 15 minutes.</strong></span>
          </div>
        </div>
      </div>
      :null}
      <SidekickCallsContentToolbar getNextCallDisabled={callState === 'active' && 0 === callData?.num_bundled_calls || btnsDisabled} initiateWebCallDisabled={callInitiated || btnsDisabled} phoneNumber={watch('phoneNumber')} initiateCallIsLoading={initiateCallIsLoading} register={register} editChecked={watch('edit')} initWebCall={initWebCall} callData={callData} discard={discard} getNextCall={getNextCall} robodialerId={callData?._robodialer_id || null} />
      {callData ?
        <>
          {/* <div className='grid grid-cols-10 gap-4 mt-4 mx-4 border border-superpay-soap rounded p-4 bg-superbill-table-grey'>
            
            <div className='col-span-3 flex flex-col items-center justify-center gap-2'>
              <div className='text-sm text-superbill-jacarta text-center'>This action may take up to 60 seconds, please wait before retrying.</div>
              <button
                className='bg-green-600 disabled:bg-superbill-aero-green font-jakarta hover:bg-green-900 text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
                disabled={initiateCallIsLoading || callData?._robodialer_id == null || (!watch('edit') && !callData.to) || (watch('edit') && watch('phoneNumber')?.length !== 10 && !regex.test(watch('phoneNumber')))}
                onClick={initWebCall}
              >
                {initiateCallIsLoading ? <Spinner /> : <>Initiate web call</>}
              </button>
            </div>
          </div> */}
          {scriptData && (scriptData?.instructions) ? <div className='mt-4 mx-4'>
            <h5 className='text-superbill-jacarta'>Script Instructions:</h5>
            <div className='flex items-center justify-start border border-superpay-soap rounded p-4 bg-superbill-table-grey'>
              <h5 className='font-bold text-superbill-jacarta'>{formatString(scriptData?.instructions)}</h5>
            </div>
          </div> : null}
          {callData && (callData?.ivrInstructions) ? <div className='mt-4 mx-4'>
            <h5 className='text-superbill-jacarta'>IVR Instructions:</h5>
            <div className='flex items-center justify-start border border-superpay-soap rounded p-4 bg-superbill-table-grey'>
              <h5 className='font-bold text-superbill-jacarta'>{formatString(callData?.ivrInstructions)}</h5>
            </div>
          </div> : null}
          <div className="grid grid-cols-10 gap-4 mt-4 mx-4">
            <div className='overflow-y-auto h-[600px] border border-superpay-soap rounded p-2 col-span-4 bg-superbill-anti-flash-white'>
              <div className="flex items-center w-full py-2">
                <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Call Information</div>
              </div>
              <div className='border border-superpay-soap divide-y divide-superpay-soap rounded'>
                <div className="flex gap-4 bg-superbill-anti-flash-white-darken">
                  <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">Assigned to:</span>
                  <span className='col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta '>{callData?.assignedToRetoolUser}</span>
                </div>
                <KeyValueGrid
                  aspect="3-7"
                  icon={<div className='absolute right-0 bottom-2 hidden group-hover:block cursor-pointer bg-[#f3f2fe] px-2'>
                    <CopyIcon className='hover:fill-superbill-indigo' />
                  </div>}
                  action={onCopy}
                  data={{
                    "Robodialer ID": callData?._robodialer_id,
                    "Script ID": callData?.scriptId,
                    "Batch ID": callData?.batchId,
                    "Call ID": callData?._call_id,
                  }} />
              </div>


              {callData?.inputs ?
                <div className='border border-superpay-soap divide-y divide-superpay-soap rounded mt-2'>
                  <div className="grid grid-cols-2 gap-4 bg-superbill-anti-flash-white-darken">
                    <div className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">Inputs</div>
                    <div className='col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta '>Value</div>
                  </div>
                  <KeyValueGrid aspect="4-6" data={callData.inputs} />
                </div>
                : null}
            </div>
            <div className='overflow-y-auto h-[600px] border border-superpay-soap rounded p-2 col-span-6 bg-superbill-anti-flash-white'>
              <div className="flex items-center justify-between w-full py-2">
                <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Script</div>
                <div className='cursor-pointer hover-underline-animation text-xs' onClick={fetchScript}>Reload</div>
              </div>
              {scriptData ?
                <div className="border border-superpay-soap rounded p-2 bg-white">
                  {/* <ScriptOverview2 questions={scriptData.questions} callInputs={callData?.inputs as Record<string, string>}/> */}
                  <ScriptOverview questions={scriptData.questions} callInputs={callData?.inputs as Record<string, string>} />
                </div>
                : null}
            </div>
          </div>
          <div className="grid grid-cols-10 gap-4 mt-4 mx-4">
            <div className='overflow-y-auto h-[600px] bg-superbill-table-grey border border-superpay-soap rounded p-2 col-span-4'>

              {/* <div className='border border-superpay-soap divide-y divide-superpay-soap rounded p-2'>
                <div className="grid grid-cols-2 gap-4 bg-superbill-anti-flash-white-darken">
                  <div className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">Key</div>
                  <div className='col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta '>Value</div>
                </div>
                <KeyValueGrid
                  aspect="3-7"
                  data={tableData} />
              </div> */}


              {callData?.inputs ?
                <div className='border border-superpay-soap divide-y divide-superpay-soap rounded mt-2'>
                  <div className="grid grid-cols-2 gap-4 bg-superbill-anti-flash-white-darken">
                    <div className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">Question ID</div>
                    <div className='col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta '>Answer</div>
                  </div>
                  <KeyValueGrid aspect="4-6" data={selectedOptionsValuesOnly as Record<string, string>} />
                </div>
                : null}
            </div>
            <div className='overflow-y-auto h-[600px] bg-superbill-table-grey border border-superpay-soap rounded p-2 col-span-6'>
              <div className="flex items-center justify-start w-full py-2">
                <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Form</div>
              </div>
              {scriptData ?
                <div className="border border-superpay-soap rounded p-2 bg-white">
                  <ScriptForm callInputs={callData?.inputs as Record<string, string>} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} questions={scriptData?.questions || []} />
                </div>
                : null}
            </div>
          </div>
          <div className='mt-4 mx-4 border border-superpay-soap p-4 bg-superbill-table-grey rounded'>
            <SubmitFormComponent onSubmit={submitCallForm} />
          </div>
        </>
        : null}
      {/* <RetoolPage /> */}
    </>
  );
}
