import { PmsSection } from "types/types";

export const sections: PmsSection[] = [
  {
    title: 'benefits',
    fields: [
      {
        name: 'benefits',
        label: 'Benefits',
        type: 'radio',
        options: ['In-Network', 'Out-of-Network', 'Both'],
      },
    ],
  },
  {
    title: 'general',
    fields: [
      {
        name: 'insuranceNetwork',
        label: 'Insurance network type',
        type: 'checkbox',
      },
      {
        name: 'benefitPeriod',
        label: 'Benefit period',
        type: 'checkbox',
      },
      {
        name: 'planRenewalDate',
        label: 'Plan renewal date',
        type: 'checkbox',
      },
      {
        name: 'policyEffectiveDate',
        label: 'Policy effective date',
        type: 'checkbox',
      },
    ],
  },
  {
    title: 'coverage',
    fields: [
      {
        name: 'diagnostic',
        label: 'Diagnostic',
        type: 'checkbox',
      },
      {
        name: 'endo',
        label: 'Endo',
        type: 'checkbox',
      },
      {
        name: 'xray',
        label: 'X-Ray',
        type: 'checkbox',
      },
      {
        name: 'oralsurgery',
        label: 'Oral Surgery',
        type: 'checkbox',
      },
      {
        name: 'preventative',
        label: 'Preventative',
        type: 'checkbox',
      },
      {
        name: 'crowns',
        label: 'Crowns',
        type: 'checkbox',
      },
      {
        name: 'restorative',
        label: 'Restorative',
        type: 'checkbox',
      },
      {
        name: 'prosthetics',
        label: 'Prosthetics',
        type: 'checkbox',
      },
      {
        name: 'perio',
        label: 'Perio',
        type: 'checkbox',
      },
    ],
  },
  {
    title: 'payments',
    fields: [
      {
        name: 'familyDeductible',
        label: 'Family deductible (annual)',
        type: 'checkbox',
      },
      {
        name: 'individualDeductible',
        label: 'Individual deductible (annual)',
        type: 'checkbox',
      },
      {
        name: 'progressIndividualDeductible',
        label: 'Progress towards individual deductible',
        type: 'checkbox',
      },
      {
        name: 'progressFamilyDeductible',
        label: 'Progress towards family deductible',
        type: 'checkbox',
      },
      {
        name: 'diagnosticDeductible',
        label: 'Diagnostic deductible',
        type: 'checkbox',
      },
      {
        name: 'preventativeDeductible',
        label: 'Preventative deductible',
        type: 'checkbox',
      },
      {
        name: 'annualMaximum',
        label: 'Annual maximum',
        type: 'checkbox',
      },
      {
        name: 'orthoLifetimeMax',
        label: 'Ortho lifetime max',
        type: 'checkbox',
      },
      {
        name: 'coPay',
        label: 'Co-Pay',
        type: 'checkbox',
      },
      {
        name: 'coInsurance',
        label: 'Co-Insurance',
        type: 'checkbox',
      },
    ],
  },
];
