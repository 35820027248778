import { TelnyxRTCProvider } from '@telnyx/react-client';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import SidekickWaitingRoom from './waitingRoom';

export default function TelnyxRTCWrapper() {
  const { login, password, ...rest } = useOutletContext<{login: string, password: string, email: string, user:any}>();
    return (
      <TelnyxRTCProvider credential={{
        login,
        password,
      }}>
        <SidekickWaitingRoom />
      </TelnyxRTCProvider>
    )
}