import React, { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import Loader from 'components/Loader';
import { getPmsResults, getPmsResultsCount } from 'apis/robodialer';

import PMSResultsTable from './pms-results-table';
import PMSDetailedResultsTable from './pms-detailed-results-table';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { RobodialerUser } from '../types/types';


type PathType = {
  name: string;
  href: string;
  current: boolean;
}

type PmsProps = {
  archived : boolean;
}

type PageProps = {
  userData: RobodialerUser | null,
}
export default function PMSPage({archived}: PmsProps) {
  
  const [props] = useOutletContext<Array<PageProps>>();
  const { search } = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);

  const [pmsResults, setPmsResults] = useState<any>(null);
  const [queryResult, setQueryResult] = useState<any>(null);
  
  const [resultsOverview, setResultsOverview] = useState<{ total: number } | null>(null);

  const updateAndSortResults = (results) => {

    const data = results?.calls
    const statusOrderMapping: { [key: string]: number } = {
      processing: 1,
      completed: 2,
      failed: 3
    };

    const sortedKeys = Object.keys(data).sort((key1, key2) => {
      const status1 = data[key1].status;
      const status2 = data[key2].status;

      const order1 = statusOrderMapping[status1] || 4;
      const order2 = statusOrderMapping[status2] || 4;

      if (order1 === order2) {
        const createdAt1 = new Date(data[key1].createdAt ?? data[key1].dateCreated);
        const createdAt2 = new Date(data[key2].createdAt ?? data[key2].dateCreated);
        return createdAt2.getTime() - createdAt1.getTime();
      }

      return order1 - order2;
    });
    const sortedResults = {};
    sortedKeys.forEach(key => {
      sortedResults[key] = data[key];
    });
    setPmsResults({
      limit: results?.limit,
      offset: results?.offset,
      calls: sortedResults
    });
  };

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true)
      getPmsResultsCount(archived).then(({data}) => {
        setResultsOverview(data as { total: number })
      })
      const response = await getPmsResults(0, 15, archived);
      updateAndSortResults(response.data)
      //setLoading(false)
    }
    /* const fetchResultsTotal = async () => {
      setLoading(true)
      const response = await getPmsResultsCount();
      setResultsOverview(response.data as {total:number})
      setLoading(false)
    } */

    fetchResults()
    //fetchResultsTotal()
  }, []);

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true)
      getPmsResultsCount(archived).then(({data}) => {
        setResultsOverview(data as { total: number })
      })
      const response = await getPmsResults(0, 15, archived);
      updateAndSortResults(response.data)
      setLoading(false)
    }
    fetchResults()

  }, [archived]);

  useEffect(() => {
    //console.log('pmsResults', pmsResults);
    //console.log('search', search, search.substring(1));
    //console.log('search, search.substring(1)', search, search.substring(1));
    const fetchCall = async () => {
      if(pmsResults){
        if (search) {
          if (pmsResults[search.substring(1)]){
            setSelectedPatient(search.substring(1))
          }else{
            const fetchResults = async () => {
              setLoading(true)
              const callId = search.substring(1)
              const response = await getPmsResults(0, 1, archived, callId);
              console.log('response, response.data[callId]', response, response?.data?.calls[callId])
              if (response.httpCode === 200 && response?.data?.calls[callId]) {
                setQueryResult(response?.data?.calls[callId])
                setSelectedPatient(callId)
              }
              setLoading(false)
            }
            fetchResults()
          }
        }   

        if(!queryResult && pmsResults.calls[selectedPatient!]){
          setQueryResult(pmsResults.calls[selectedPatient!])
          setLoading(false)
        }
      }
    }
    fetchCall()
  }, [pmsResults]);

  // useEffect(() => {
  //   setDisabled(loading)
  // }, [loading])
  
  return (
    <>
      <Toaster  toastOptions={{duration: 12000}}/>
      {loading ? (
        <>
          <div className='w-full h-full flex justify-center items-center'>
            {' '}
            <Loader />{' '}
          </div>
        </>
      ) : null}
      {!loading ? (
        <div className='pb-4'>
          {!selectedPatient ?
            <PMSResultsTable archived={archived ?? false} setLoading={setLoading} pmsResults={pmsResults} selectedPatient={selectedPatient} setSelectedPatient={setSelectedPatient}
              total={resultsOverview?.total ?? 0}
              refresh={async (offset, limit) => {
                const fetchResults = async () => {
                  setLoading(true)
                  const response = await getPmsResults(offset, limit, archived);
                  getPmsResultsCount(archived).then(({data}) => {
                    setResultsOverview(data as { total: number })
                  })
                  updateAndSortResults(response.data)
                  setLoading(false)
                }
                /* const fetchResultsTotal = async () => {
                  setLoading(true)
                  const response = await getPmsResultsCount();
                  setResultsOverview(response.data as {total:number})
                  setLoading(false)
                } */
            
                fetchResults()
                //const { data, httpCode } = await getPmsResults(offset, limit);
                //console.log(data, httpCode);
                //setPmsResults(httpCode === 200 ? updateAndSortResults(data) : {});
              
              }}
            />
            :
            <PMSDetailedResultsTable patient={ queryResult ?? pmsResults?.calls[selectedPatient] ?? {}} selectedPatient={selectedPatient} setSelectedPatient={setSelectedPatient}/>
          }
        </div>
      ) : null}
    </>
  );
}
