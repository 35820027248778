import { FC, useState } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg';
import { DragEvent } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Option, Questions } from "./QuestionType";
import selectOptions from "./QuestionSelectOptions";
import QuestionIcon from './QuestionIcon';
import QuestionInput from './QuestionInput';
import QuestionSelect from './QuestionSelect';
import QuestionMenu from './QuestionOptions';
import { ReactComponent as XIcon } from 'assets/x.svg';
import { ReactComponent as PlusIcon } from 'assets/plus-circle.svg';
import StyledQuestionInput from './StyledQuestionInput';
import { Tooltip } from '@material-tailwind/react';
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as BackgroundIcon } from 'assets/icon_backgroundInfo.svg';
import { ReactComponent as ResponseIcon } from 'assets/icon_responseInstructions.svg';


type Props = {
  allowedString: string[];
  index: number;
  question: Questions;
  handleOptionInputChange: (index: number,
    optionIndex: number,
    e: React.ChangeEvent<HTMLInputElement>) => void;
  addSubQuestionForMC: (q: Questions, options: string) => void;
  deleteMultipleChoice: (q: Questions, index: number) => void;
  addMultipleChoice: (index: Questions, opt?: string) => void;
  addSubQuestion: (index: Questions, isFollowUpYes?: boolean) => void;
  addSubQuestionToFollowUps: (index: number, subindex: number, isFollowUpYes?: boolean) => void;
  handleSubQuestionInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubQuestionSelectChange: (name: string, value: Option | string) => void;
  dragSubIndex: number | string | null;
  handleDragSubStart: (e: React.DragEvent<HTMLDivElement>, name: string) => void;
  handleDragSubEnter: (e: DragEvent<HTMLDivElement>, name: string) => void;
  handleDragSubLeave: (e) => void;
  handleDropSubquestion: (e: DragEvent<HTMLDivElement>, name: string) => void;
  handleDragOver: (e) => void;
  register: UseFormRegister<any>;
  control: any; //yolo
  setValue: UseFormSetValue<any>;
  showAddSubQuestions?: boolean;
  deleteQ?: (name: string) => void
  duplicate?: (name: string) => void
  handleSubQuestionBackgroundChange: (name: string, text: string) => void;
  handleSubQuestionResponseChange: (name: string, text: string) => void;
  handleBackgroundInfoModalState: (index: number | string | null, state: boolean) => void
  handleResponseInstructionsModalState: (index: number | string | null, state: boolean) => void
  handleSubquestionsBackgroundContextAndResponseInstructions: (index: string | null, text: string, type: 'responseInstruction' | 'backgroundContext') => void
};

const MAX_FOLLOWUPS = 4;

const Subquestions: FC<Props> = ({
  register,
  handleBackgroundInfoModalState,
  handleResponseInstructionsModalState,
  handleSubquestionsBackgroundContextAndResponseInstructions,
  handleOptionInputChange,
  control,
  index,
  question,
  addSubQuestion,
  addSubQuestionForMC,
  handleSubQuestionInputChange,
  addSubQuestionToFollowUps,
  addMultipleChoice,
  setValue,
  deleteMultipleChoice,
  handleSubQuestionSelectChange,
  dragSubIndex,
  handleDragOver,
  handleDragSubStart,
  handleDragSubEnter,
  handleDragSubLeave,
  allowedString,
  handleDropSubquestion,
  showAddSubQuestions = false,
  deleteQ = () => { },
  duplicate = () => { },
  handleSubQuestionBackgroundChange,
  handleSubQuestionResponseChange
}) => {


  const [editBackgroundIndex, setBackgroundEditIndex] = useState<string | null>(null);
  const [editResponseIndex, setResponseEditIndex] = useState<string | null>(null);
  const [tempBackgroundText, setTempBackgroundText] = useState<string>("");
  const [tempResponseText, setTempResponseText] = useState<string>("");
  const showFollowUp = (question, nested = 0, key = '') => {

  const handleBackgroundContextClick = (index: string, value) => {
    console.log(index,value);
    
    setBackgroundEditIndex(index);
    setTempBackgroundText(value || "");
  };

  const handleBackgroundInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempBackgroundText(event.target.value);
  };

  return (
      <>
        
        {
          question?.followUps && (question?.followUps?.No || question?.followUps?.Yes) ?
            <div className={"p-2"}>
              <div className="ml-5 mb-2 flex items-center">
                <span className="text-superbill-jacarta text-sm mr-4">If the answer is <strong>No</strong></span>
                <div className='flex items-center' onClick={() => { addSubQuestion(question, false) }}>
                  <PlusIcon />
                  <span className="ml-1 hover-underline-animation cursor-pointer text-sm" >Add question</span>
                </div>
              </div>
              {question?.followUps?.No?.map((subquestion, subIndex) => (
                <div key={`${key}.followUps.No.${subIndex}.input`}>
                  {subquestion?.questionId ?
                    <div className="bg-superbill-anti-flash-white rounded-t w-fit px-3 ml-9 text-superbill-slate-grey text-xs border-t border-l border-r border-superbill-soap">
                      <Tooltip
                        content={
                          <span style={{ whiteSpace: 'pre-line' }}>
                            Question ID
                          </span>
                        } className="max-w-[200px] bg-black py-1 px-2">
                        {subquestion?.questionId}
                      </Tooltip>
                    </div>
                    : null
                  }
                  <div className={"flex mb-2 bg-white rounded ml-5 border border-superbill-soap" + (dragSubIndex === `${key}.No.${subIndex}` ? ' !bg-superbill-lavender-dark' : '')}
                    key={`${key}.No.${subIndex}`}
                  >
                    <div className="cursor-pointer bg-superbill-lavender-dark rounded-l-[3px] flex-grow-0 "
                      draggable
                      onDragOver={handleDragOver}
                      onDragEnter={(e) => handleDragSubEnter(e, `${key}.No.${subIndex}`)}
                      onDragLeave={handleDragSubLeave}
                      onDragStart={(e) => handleDragSubStart(e, `${key}.No.${subIndex}`)}
                      onDrop={(e) => handleDropSubquestion(e, `${key}.No.${subIndex}`)}
                    >
                      <div className={"flex items-center m-auto h-full px-1 text-superbill-indigo font-jakarta text-xs font-semibold " + (subIndex > 8 ? 'w-[22px]' : 'w-[15px]')}>
                        {subIndex + 1}
                      </div>
                    </div>
                    <div className="flex-grow h-auto rounded-r">
                      {subquestion.backgroundContext ?
                        <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
                          <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
                          {editBackgroundIndex === `${key}.No.${subIndex}` ? (
                              <div className="flex items-center w-full">
                              <input
                                type="text"
                                value={tempBackgroundText}
                                onChange={handleBackgroundInputChange}
                                onBlur={() => {
                                    handleSubquestionsBackgroundContextAndResponseInstructions(`${key}.No.${subIndex}`, tempBackgroundText, 'backgroundContext')
                                    setBackgroundEditIndex(null)
                                  }}
                                autoFocus
                                className="border-superbill-soap focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none focus:border-superbill-indigo bg-white border rounded text-superbill-jacarta text-xs p-0 pl-1 grow"
                              />
                              <button
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => {
                                  handleSubquestionsBackgroundContextAndResponseInstructions(`${key}.No.${subIndex}`, '', 'backgroundContext')
                                }}
                                className="ml-2 text-red-500 hover:text-red-700 w-[20px] flex-none"
                              >
                                <DeleteIcon className='w=[14px]'/>
                              </button>
                              </div>
                            ) : (
                              <span onClick={() => handleBackgroundContextClick(`${key}.No.${subIndex}`, subquestion.backgroundContext)}>
                                {subquestion.backgroundContext}
                              </span>
                            )}
                        </div>
                      : null}
                      <div key={`${key}.No.${subIndex}`} className="flex flex-col">
                        <div className={"flex items-center justify-between px-2 pt-2"}>
                          <div className="mr-2 grow">
                            <StyledQuestionInput
                              allowedString={allowedString}
                              index={`${key}.No.${subIndex}`}
                              iKey={`${key}.No.${subIndex}.input`}
                              register={register}
                              onChange={(e) => handleSubQuestionInputChange(e)}
                              value={subquestion?.question}
                              className='border-white'
                            />
                          </div>
                          <div className="mr-2 w-[200px] min-w-[170px]">
                            <QuestionSelect defaultValue={subquestion?.answerType!} index={`${key}.No.${subIndex}`} control={control} options={selectOptions}
                              onChange={(e) => {
                                handleSubQuestionSelectChange(`${key}.No.${subIndex}`, e)
                              }} />
                          </div>
                          <div className="flex max-w-[30px] items-center justify-center hover:bg-superbill-anti-flash-white rounded mb-2 min-w-[30px]">
                            <QuestionMenu showAddSubQuestions={true}
                              canAddSubQuestions={(
                                ((typeof subquestion?.answerType !== 'string' && subquestion?.answerType?.value === 'boolean') ||
                                  (typeof subquestion?.answerType === 'string' && subquestion?.answerType === 'boolean')
                                ) &&
                                ((typeof subquestion?.followUps !== 'object') ||
                                  (Object.keys(subquestion?.followUps).length === 0)) &&
                                nested < MAX_FOLLOWUPS
                              )}
                              addSubQuestion={() => {
                                addSubQuestion(subquestion)
                              }}
                              duplicate={() => {
                                const duplicateElement = question?.followUps?.No[subIndex];
                                question?.followUps?.No.splice(subIndex + 1, 0, duplicateElement);
                                duplicate(`${key}.No.${subIndex}`)
                              }}
                              deleteQ={() => {
                                question?.followUps?.No.splice(subIndex, 1);
                                deleteQ(`${key}.No.${subIndex}`)
                              }}
                              addBackgroundContext={() => { 
                                handleBackgroundInfoModalState(`${key}.No.${subIndex}`, true)
                              }}
                              addResponseInstruction={() => { 
                                handleResponseInstructionsModalState(`${key}.No.${subIndex}`, true)
                              }}
                            />
                          </div>
                        </div>
                        {subquestion.responseInstruction? 
                            <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
                              <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
                              {editBackgroundIndex === `${key}.No.${subIndex}` ? (
                                  <div className="flex items-center w-full">
                                  <input
                                    type="text"
                                    value={tempBackgroundText}
                                    onChange={handleBackgroundInputChange}
                                    onBlur={() => {
                                        handleSubquestionsBackgroundContextAndResponseInstructions(`${key}.No.${subIndex}`, tempBackgroundText, 'responseInstruction')
                                        setBackgroundEditIndex(null)
                                      }}
                                    autoFocus
                                    className="border-superbill-soap focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none focus:border-superbill-indigo bg-white border rounded text-superbill-jacarta text-xs p-0 pl-1 grow"
                                  />
                                  <button
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={() => {
                                      handleSubquestionsBackgroundContextAndResponseInstructions(`${key}.No.${subIndex}`, '', 'responseInstruction')
                                    }}
                                    className="ml-2 text-red-500 hover:text-red-700 w-[20px] flex-none"
                                  >
                                    <DeleteIcon className='w=[14px]'/>
                                  </button>
                                  </div>
                                ) : (
                                  <span onClick={() => handleBackgroundContextClick(`${key}.No.${subIndex}`, subquestion.responseInstruction)}>
                                    {subquestion.responseInstruction}
                                  </span>
                                )}
                            </div>
                          : null}
                            {subquestion?.followUps ? 
                            <div className='border-b border-superbill-soap'></div>
                            : null
                          }
                        <div className='ml-5'>
                          {showFollowUp(subquestion, nested + 1, `${key}.No.${subIndex}`)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            : null
        }
        {question?.followUps && (question?.followUps?.No || question?.followUps?.Yes) ?
          <div className={"p-2"}>
            <div className="ml-5 mb-2 flex items-center">
              <span className="text-superbill-jacarta text-sm mr-4">If the answer is <strong>Yes</strong></span>
              <div className='flex items-center' onClick={() => { addSubQuestion(question, true) }}>
                <PlusIcon />
                <span className="ml-1 hover-underline-animation cursor-pointer text-sm" >Add question</span>
              </div>
            </div>
            {question?.followUps?.Yes?.map((subquestion, subIndex) => (
              <div key={`${key}.followUps.Yes.${subIndex}.input`}>
                {typeof subquestion?.question !== 'undefined' ?
                  <>
                    {subquestion?.questionId ?
                      <div className="bg-superbill-anti-flash-white rounded-t w-fit px-3 ml-9 text-superbill-slate-grey text-xs border-t border-l border-r border-superbill-soap">
                        <Tooltip
                          content={
                            <span style={{ whiteSpace: 'pre-line' }}>
                              Question ID
                            </span>
                          } className="max-w-[200px] bg-black py-1 px-2">
                          {subquestion?.questionId}
                        </Tooltip>
                      </div>
                      : null
                    }
                    <div className={"flex mb-2 bg-white rounded ml-5 border border-superbill-soap relative" + (dragSubIndex === `${key}.Yes.${subIndex}` ? ' !bg-superbill-lavender-dark' : '')} key={`${key}.Yes.${subIndex}`}>

                      <div className="cursor-pointer bg-superbill-lavender-dark rounded-l-[3px] flex-grow-0"
                        draggable
                        onDragOver={handleDragOver}
                        onDragEnter={(e) => handleDragSubEnter(e, `${key}.Yes.${subIndex}`)}
                        onDragLeave={handleDragSubLeave}
                        onDragStart={(e) => handleDragSubStart(e, `${key}.Yes.${subIndex}`)}
                        onDrop={(e) => handleDropSubquestion(e, `${key}.Yes.${subIndex}`)}
                      >
                        <div className={"flex items-center m-auto h-full px-1 text-superbill-indigo font-jakarta text-xs font-semibold " + (subIndex > 8 ? 'w-[22px]' : 'w-[15px]')}>
                          {subIndex + 1}
                        </div>
                      </div>
                      <div className="flex-grow h-auto rounded-r">
                      {subquestion.backgroundContext? 
                        <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
                          <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
                          {editBackgroundIndex === `${key}.Yes.${subIndex}` ? (
                              <div className="flex items-center w-full">
                              <input
                                type="text"
                                value={tempBackgroundText}
                                onChange={handleBackgroundInputChange}
                                onBlur={() => {
                                    handleSubquestionsBackgroundContextAndResponseInstructions(`${key}.Yes.${subIndex}`, tempBackgroundText, 'backgroundContext')
                                    setBackgroundEditIndex(null)
                                  }}
                                autoFocus
                                className="border-superbill-soap focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none focus:border-superbill-indigo bg-white border rounded text-superbill-jacarta text-xs p-0 pl-1 grow"
                              />
                              <button
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => {
                                  handleSubquestionsBackgroundContextAndResponseInstructions(`${key}.Yes.${subIndex}`, '', 'backgroundContext')
                                }}
                                className="ml-2 text-red-500 hover:text-red-700 w-[20px] flex-none"
                              >
                                <DeleteIcon className='w=[14px]'/>
                              </button>
                              </div>
                            ) : (
                              <span onClick={() => handleBackgroundContextClick(`${key}.Yes.${subIndex}`, subquestion.backgroundContext)}>
                                {subquestion.backgroundContext}
                              </span>
                            )}
                        </div>
                      : null}
                        <div key={`${key}.Yes.${subIndex}`} className="flex flex-col">
                          <div className={"flex items-center justify-between px-2 pt-2"}>
                            <div className="mr-2 grow">
                              <StyledQuestionInput
                                allowedString={allowedString}
                                index={`${key}.Yes.${subIndex}`}
                                iKey={`${key}.Yes.${subIndex}.input`}
                                register={register}
                                onChange={(e) => handleSubQuestionInputChange(e)}
                                value={subquestion?.question}
                                className='border-white'
                              />
                            </div>
                            <div className="mr-2 w-[200px] min-w-[170px]">
                              <QuestionSelect defaultValue={subquestion?.answerType!} index={`${key}.Yes.${subIndex}`} control={control} options={selectOptions}
                                onChange={(e) => {
                                  handleSubQuestionSelectChange(`${key}.Yes.${subIndex}`, e)
                                }} />
                            </div>
                            <div className="flex max-w-[30px] items-center justify-center rounded mb-2 hover:bg-superbill-anti-flash-white min-w-[30px]">
                              <QuestionMenu showAddSubQuestions={true}
                                canAddSubQuestions={(
                                  ((typeof subquestion?.answerType !== 'string' && subquestion?.answerType?.value === 'boolean') ||
                                    (typeof subquestion?.answerType === 'string' && subquestion?.answerType === 'boolean')
                                  ) &&
                                  ((typeof subquestion?.followUps !== 'object') ||
                                    (Object.keys(subquestion?.followUps).length === 0)) &&
                                  nested < MAX_FOLLOWUPS
                                )}
                                addSubQuestion={() => {
                                  addSubQuestion(subquestion)
                                }}
                                duplicate={() => {
                                  const duplicateElement = question?.followUps?.Yes[subIndex];
                                  question?.followUps?.Yes.splice(subIndex + 1, 0, duplicateElement);
                                  duplicate(`${key}.Yes.${subIndex}`)
                                }}
                                deleteQ={() => {
                                  question?.followUps?.Yes.splice(subIndex, 1);
                                  deleteQ(`${key}.Yes.${subIndex}`)
                                }}
                                addBackgroundContext={() => { 
                                  console.log('addBackgroundContext');
                                  handleBackgroundInfoModalState(`${key}.Yes.${subIndex}`, true)
                                }}
                                addResponseInstruction={() => { 
                                  handleResponseInstructionsModalState(`${key}.Yes.${subIndex}`, true)
                                }}
                              />
                            </div>
                          </div>
                          {subquestion.responseInstruction? 
                            <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
                              <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
                              {editBackgroundIndex === `${key}.Yes.${subIndex}` ? (
                                  <div className="flex items-center w-full">
                                  <input
                                    type="text"
                                    value={tempBackgroundText}
                                    onChange={handleBackgroundInputChange}
                                    onBlur={() => {
                                        handleSubquestionsBackgroundContextAndResponseInstructions(`${key}.Yes.${subIndex}`, tempBackgroundText, 'responseInstruction')
                                        setBackgroundEditIndex(null)
                                      }}
                                    autoFocus
                                    className="border-superbill-soap focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none focus:border-superbill-indigo bg-white border rounded text-superbill-jacarta text-xs p-0 pl-1 grow"
                                  />
                                  <button
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={() => {
                                      handleSubquestionsBackgroundContextAndResponseInstructions(`${key}.Yes.${subIndex}`, '', 'responseInstruction')
                                    }}
                                    className="ml-2 text-red-500 hover:text-red-700 w-[20px] flex-none"
                                  >
                                    <DeleteIcon className='w=[14px]'/>
                                  </button>
                                  </div>
                                ) : (
                                  <span onClick={() => handleBackgroundContextClick(`${key}.Yes.${subIndex}`, subquestion.responseInstruction)}>
                                    {subquestion.responseInstruction}
                                  </span>
                                )}
                            </div>
                          : null}
                            {subquestion?.followUps ? 
                            <div className='border-b border-superbill-soap'></div>
                            : null
                          }
                          <div className='ml-5'>
                            {showFollowUp(subquestion, nested + 1, `${key}.Yes.${subIndex}`)}
                          </div>
                        </div>
                      </div>

                    </div>
                  </>
                  : null}
              </div>
            ))}
          </div>
          : null
        }
        {
          question?.answerOptions && 0 < question?.answerOptions.length ?
            <div className={"p-2"}>
              <div className="p-2 ml-3 ">
                <div className="mb-2 flex items-center">
                  <span className=" text-superpay-light-text-gray font-medium font-sans text-sm">Multiple choice options</span>
                </div>
                <div className='w-full flex flex-col items-start align-middle gap-y-2'>
                  {question?.answerOptions.map((option, optionIndex) =>
                    <div className='flex gap-x-4 w-full items-center' key={`${key}.answerOptions.${optionIndex}.row`}>
                        <StyledQuestionInput
                          allowedString={allowedString} register={register}
                          index={`${key}.answerOptions.${optionIndex}`}
                          iKey={`${key}.answerOptions.${optionIndex}.input`}
                          value={option}
                          onChange={(e) => handleSubQuestionInputChange(e)}
                          disabled={question?.followUps?.multiple && question?.followUps?.multiple[option] && question?.followUps?.multiple[option]?.length !== 0}
                          className='w-1/4 border-superbill-soap'
                        />
                        <div className='flex items-center' onClick={() => { deleteMultipleChoice(question, optionIndex) }}>
                          {question?.answerOptions?.length === 2? null : 
                          <XIcon className="cursor-pointer block h-4 w-4 ml-1 fill-superbill-slate-grey/50 hover:fill-superbill-slate-grey mb-2" aria-hidden="true" />
                            }
                        </div>
                      {(question?.answerOptions?.length! - 1) === optionIndex ?
                        <div className='flex items-center' onClick={() => { addMultipleChoice(question) }}>
                          <PlusIcon className="cursor-pointer block h-6 w-6 child:fill-superbill-slate-grey/50 hover:child:fill-superbill-slate-grey mb-2" aria-hidden="true" />
                        </div>
                        : null}
                      {question?.followUps?.multiple && question?.followUps?.multiple[option] && question?.followUps?.multiple[option]?.length !== 0 ?  
                        <Tooltip
                          content={
                            <span style={{ whiteSpace: "pre-line" }}>
                              To edit this option, delete followUps   
                            </span>
                          }
                          className="max-w-[200px] bg-black py-1 px-2"
                        >
                          <InfoIcon className="cursor-pointer block h-6 w-6 ml-1 fill-superbill-slate-grey/50 hover:fill-superbill-slate-grey mb-2" aria-hidden="true" />
                        </Tooltip>
                      :null}
                    </div>)}
                </div>
              </div>
              {question?.answerOptions.map((option, qIndex) => <div key={'qIndex-' + qIndex}>
                <div className="ml-5 mb-2 flex items-center">
                  <span className="text-superbill-jacarta text-sm mr-4">If the answer is <strong>{option}</strong></span>
                  <div className='flex items-center' onClick={() => { addSubQuestionForMC(question, option) }}>
                    <PlusIcon />
                    <span className="ml-1 hover-underline-animation cursor-pointer text-sm" >Add question</span>
                  </div>
                </div>
                {(question?.followUps?.multiple ? (question?.followUps?.multiple[option] ?? []) : [])?.map((mQuestion, subIndex) => (
                  <>
                    <div key={`${key}.multiple.${option}.${subIndex}.input`}>
                    {mQuestion?.questionId ?
                      <div className="bg-superbill-anti-flash-white rounded-t w-fit px-3 ml-9 text-superbill-slate-grey text-xs border-t border-l border-r border-superbill-soap">
                        <Tooltip
                          content={
                            <span style={{ whiteSpace: 'pre-line' }}>
                              Question ID
                            </span>
                          } className="max-w-[200px] bg-black py-1 px-2">
                          {mQuestion?.questionId}
                        </Tooltip>
                      </div>
                      : null
                    }
                      <div className={"flex mb-2 bg-white rounded ml-5 border border-superbill-soap" + (dragSubIndex === `${key}.multiple.${option}.${subIndex}` ? ' !bg-superbill-lavender-dark' : '')}
                        key={`${key}.multiple.${option}.${subIndex}`}
                      >
                        <div className="cursor-pointer bg-superbill-lavender-dark rounded-l-[3px] flex-grow-0 "
                          draggable
                          onDragOver={handleDragOver}
                          onDragEnter={(e) => handleDragSubEnter(e, `${key}.multiple.${option}.${subIndex}`)}
                          onDragLeave={handleDragSubLeave}
                          onDragStart={(e) => handleDragSubStart(e, `${key}.multiple.${option}.${subIndex}`)}
                          onDrop={(e) => handleDropSubquestion(e, `${key}.multiple.${option}.${subIndex}`)}
                        >
                          <div className={"flex items-center m-auto h-full px-1 text-superbill-indigo font-jakarta text-xs font-semibold " + (subIndex > 8 ? 'w-[22px]' : 'w-[15px]')}>
                            {subIndex + 1}
                          </div>
                        </div>
                        <div className="flex-grow h-auto rounded-r">
                          <div key={`${key}.multiple.${option}.${subIndex}`} className="flex flex-col">
                            <div className={"flex items-center justify-between px-2 pt-2 border-superbill-soap"}>
                              <div className="mr-2 grow">
                                <StyledQuestionInput
                                  allowedString={allowedString}
                                  index={`${key}.multiple.${option}.${subIndex}`}
                                  iKey={`${key}.multiple.${option}.${subIndex}.input`}
                                  register={register}
                                  onChange={(e) => handleSubQuestionInputChange(e)}
                                  value={mQuestion?.question}
                                  className='border-white'
                                />
                              </div>
                              <div className="mr-2 w-[200px] min-w-[170px]">
                                <QuestionSelect defaultValue={mQuestion?.answerType!} index={`${key}.multiple.${option}.${subIndex}`} control={control} options={selectOptions}
                                  onChange={(e) => {
                                    handleSubQuestionSelectChange(`${key}.multiple.${option}.${subIndex}`, e)
                                  }} />
                              </div>
                              <div className="flex max-w-[30px] items-center justify-center hover:bg-superbill-anti-flash-white rounded mb-2 min-w-[30px]">
                                <QuestionMenu showAddSubQuestions={true}
                                  canAddSubQuestions={(
                                    ((typeof mQuestion?.answerType !== 'string' && mQuestion?.answerType?.value === 'boolean') ||
                                      (typeof mQuestion?.answerType === 'string' && mQuestion?.answerType === 'boolean')
                                    ) &&
                                    ((typeof mQuestion?.followUps !== 'object') ||
                                      (Object.keys(mQuestion?.followUps).length === 0)) &&
                                    nested < MAX_FOLLOWUPS
                                  )}
                                  addSubQuestion={() => {
                                    addSubQuestion(mQuestion)
                                  }}
                                  duplicate={() => {
                                    const duplicateElement = question?.followUps?.multiple[option][subIndex];
                                    question?.followUps?.multiple[option].splice(subIndex + 1, 0, duplicateElement);
                                    duplicate(`${key}.multiple.${option}.${subIndex}`)
                                  }}
                                  deleteQ={() => {
                                    question?.followUps?.multiple[option].splice(subIndex, 1);
                                    deleteQ(`${key}.multiple.${option}.${subIndex}`)
                                  }}
                                  addBackgroundContext={() => { 
                                    //function here
                                  }}
                                  addResponseInstruction={() => { 
                                    // function here
                                  }}
                                />
                              </div>
                            </div>
                            {mQuestion.responseInstruction? 
                              <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
                                <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
                                {editBackgroundIndex === `${key}.multiple.${subIndex}` ? (
                                    <div className="flex items-center w-full">
                                    <input
                                      type="text"
                                      value={tempBackgroundText}
                                      onChange={handleBackgroundInputChange}
                                      onBlur={() => {
                                          handleSubquestionsBackgroundContextAndResponseInstructions(`${key}.multiple.${subIndex}`, tempBackgroundText, 'responseInstruction')
                                          setBackgroundEditIndex(null)
                                        }}
                                      autoFocus
                                      className="border-superbill-soap focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none focus:border-superbill-indigo bg-white border rounded text-superbill-jacarta text-xs p-0 pl-1 grow"
                                    />
                                    <button
                                      onMouseDown={(e) => e.preventDefault()}
                                      onClick={() => {
                                        handleSubquestionsBackgroundContextAndResponseInstructions(`${key}.multiple.${subIndex}`, '', 'responseInstruction')
                                      }}
                                      className="ml-2 text-red-500 hover:text-red-700 w-[20px] flex-none"
                                    >
                                      <DeleteIcon className='w=[14px]'/>
                                    </button>
                                    </div>
                                  ) : (
                                    <span onClick={() => handleBackgroundContextClick(`${key}.multiple.${subIndex}`, mQuestion.responseInstruction)}>
                                      {mQuestion.responseInstruction}
                                    </span>
                                  )}
                              </div>
                            : null}
                            <div className='ml-5'>
                              {showFollowUp(mQuestion, nested + 1, `${key}.multiple.${option}.${subIndex}`)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              )}
            </div>
            : null
        }
        

      </>
    )
  }
  return <>{showFollowUp(question, 0, 'main.' + index)}</>
};

export default Subquestions;
