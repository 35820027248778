import React, { useEffect, useMemo, useState } from 'react'
import Loader from 'components/Loader';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { ReactComponent as ExclamationIcon } from 'assets/exclamation.svg';
import { DateTime } from 'luxon';
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { ReactComponent as NoPatients } from "assets/no-new-patients.svg";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import { PaginationStates, paginationHelper } from '../utils/pagination';
import { checkAllPatientsInsurance, checkPatientInsurance, dismissAllPatients, dismissPatient, fetchInsurance, getRobodialerUser } from 'apis/robodialer';
import { Tooltip } from '@material-tailwind/react';
import PmsOverviewCard from './pms-overview-card';
import { toast } from 'react-hot-toast';
import { PMSPatientData, RobodialerUser } from '../types/types';

type pmsNewPatientsTableProps = {
  pmsNewPatients: any,
  refresh: () => void,
  pmsAutoVerificationEnabled: boolean
  setPmsNewPatients: React.Dispatch<React.SetStateAction<any | null>>;
  openModal: () => void
}

function PmsNewPatientsTable({ pmsNewPatients, setPmsNewPatients, refresh, openModal, pmsAutoVerificationEnabled }: pmsNewPatientsTableProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [dismissAll, setDismissAll] = useState<boolean>(false);
  const [verifyAll, setVerifyAll] = useState<boolean>(false);
  const [robodialerData, setRobodialerData] = useState<RobodialerUser | null>(null);
  const [userName, setUserName] = useState<string>('');

  useEffect(() => {
    const fetch = async () => {
      const userResponse = await getRobodialerUser();
      setUserName((userResponse.data.firstName ?? '') + ' ' + (userResponse.data.lastName ?? ''));
      setLoading(false)
    }
    fetch()
  }, []);

  /* const convertedArray = Object.keys(pmsNewPatients ?? {}).reduce((result: any, locationId) => {
    if (pmsNewPatients[locationId]) {
      Object.keys(pmsNewPatients[locationId]).forEach((patientId) => {
        result.push({
          id: patientId,
          locationId,
        [...pmsNewPatients];
        });
      });
    }
    return result;
  }, []); */

  useEffect(() => {
    const fetch = async () => {
      const userResponse = await getRobodialerUser();
      setRobodialerData(userResponse.data)   
      setLoading(false)
    }
    fetch()
  }, []);

  const fetchInsuranceData = async (locId, id, index) => {
    let newPatientsArray = [...sortedPatients];
    newPatientsArray[index + currentLowerIndex].loadingInsurance = true;
    setPmsNewPatients(newPatientsArray);

    const { httpCode } = await fetchInsurance(locId, id);
    if (httpCode === 200) {
      let patArray = [...sortedPatients];
      patArray[index + currentLowerIndex].loadingInsurance = false;
      patArray[index + currentLowerIndex].missing_insurance = false;
      setPmsNewPatients(patArray);
      toast.success('Insurance successfully fetched. You may now verify the patient’s insurance.', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      });
    } else {
      newPatientsArray = [...sortedPatients];
      newPatientsArray[index + currentLowerIndex].loadingInsurance = false;
      setPmsNewPatients(newPatientsArray);
      toast.error('Fetching failed. Make sure the patient’s data is in your PMS and try again.', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  };



  const [currentResultsPageIndex, setCurrentResultsPageIndex] =
    useState<number>(0);

  /* convertedArray.sort(
    (a: any, b: any) => {
      if (a['next_appointment_start_time'] < b['next_appointment_start_time']) { return 1 } else { return -1 };
    }
  ); */
  const sortedPatients = useMemo(() => {
    const patientsWithNextAppointment = pmsNewPatients
      .filter((item) => {

        const daysRemaining = Math.ceil(
          DateTime.fromJSDate(new Date(item?.next_appointment_start_time))
            .diffNow('days')
            .as('days')
        );
        return (item?.next_appointment_start_time && 0 <= daysRemaining);
      })
      .sort((a, b) => {
        const daysRemainingA = Math.ceil(
          DateTime.fromJSDate(new Date(a?.next_appointment_start_time))
            .diffNow('days')
            .as('days')
        );
        const daysRemainingB = Math.ceil(
          DateTime.fromJSDate(new Date(b?.next_appointment_start_time))
            .diffNow('days')
            .as('days')
        );

        if (daysRemainingA !== daysRemainingB) {
          return daysRemainingA - daysRemainingB;
        }

        const nameA = `${a.last_name} ${a.first_name}`;
        const nameB = `${b.last_name} ${b.first_name}`;
        return nameA.localeCompare(nameB);
      });
    
    const patientsWithoutNextAppointment = pmsNewPatients.filter(
      (item) => !item?.next_appointment_start_time
    ).sort((a, b) => {
      const nameA = `${a.last_name} ${a.first_name}`;
      const nameB = `${b.last_name} ${b.first_name}`;
      return nameA.localeCompare(nameB);
    });


    const otherPatients = pmsNewPatients.filter((item) => {
      const daysRemaining = Math.ceil(
        DateTime.fromJSDate(new Date(item?.next_appointment_start_time))
          .diffNow('days')
          .as('days')
      );
      return (item?.next_appointment_start_time && daysRemaining < 0);
    })
    .sort((a, b) => {
      const daysRemainingA = Math.ceil(
        DateTime.fromJSDate(new Date(a?.next_appointment_start_time))
          .diffNow('days')
          .as('days')
      );
      const daysRemainingB = Math.ceil(
        DateTime.fromJSDate(new Date(b?.next_appointment_start_time))
          .diffNow('days')
          .as('days')
      );

      if (daysRemainingA !== daysRemainingB) {
        return daysRemainingB - daysRemainingA;
      }

      const nameA = `${a.last_name} ${a.first_name}`;
      const nameB = `${b.last_name} ${b.first_name}`;
      return nameA.localeCompare(nameB);
    });

    const allPatients = [...patientsWithNextAppointment, ...patientsWithoutNextAppointment, ...otherPatients];


    return allPatients
  }, [pmsNewPatients]);
  const {
    newEntries: currentActiveResults,
    currentLowerIndex,
    currentUpperIndex,
    changePagination,
  } = paginationHelper({
    entries: sortedPatients,
    currentPageIndex: currentResultsPageIndex,
    maxEntriesPerPage: 5,
    setCurrentPageIndex: setCurrentResultsPageIndex,
  });

  const dismiss = async (locId, id, index) => {
    let newPatients = [...sortedPatients];
    newPatients[index + currentLowerIndex].loadingDismiss = true;
    setPmsNewPatients(newPatients);
    const { httpCode } = await dismissPatient(locId, id)
    if (httpCode === 200) {
      refresh()
    } else {
      toast.error('Unable to dismiss', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      newPatients = [...sortedPatients];
      newPatients[index + currentLowerIndex].loadingDismiss = false;
      setPmsNewPatients(newPatients);
    }
  };

  const dismissAllNewPatients = async () => {
    setDismissAll(true)
    const { httpCode } = await dismissAllPatients()
    setDismissAll(false)
    if (httpCode === 200) {
      toast.success('You’ll still be able to find them in the Patients tab', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      });
      refresh()
    } else {
      toast.error('Unable to dismiss', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  };

  const checkInsurance = async (locId, id, index) => {
    let newPatientsArray = [...sortedPatients];

    newPatientsArray[index + currentLowerIndex].loadingInsurance = true;
    setPmsNewPatients(newPatientsArray);

    const { httpCode } = await checkPatientInsurance(locId, id)
    if (httpCode === 200) {
      refresh()
    } else {
      let msg = 'Unable to Verify'
      if (httpCode === 409) {
        msg = 'Existing call in progress for this patient'
      }
      toast.error(msg, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      newPatientsArray = [...sortedPatients];
      newPatientsArray[index + currentLowerIndex].loadingInsurance = false;
      setPmsNewPatients(newPatientsArray);
    }
  };


  const checkAll = async () => {
    setVerifyAll(true)
    const { httpCode } = await checkAllPatientsInsurance()
    setVerifyAll(false)
    if (httpCode === 200) {
      refresh()
    } else {
      toast.error('Unable to Verify', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  };


  return (
    <>
      <div className="flex justify-between items-center w-full border-b border-superbill-soap py-2 mb-4">
        <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>New Patients</div>
        {0 < pmsNewPatients.length ?
          <div className='flex items-center justify-end'>
            {!pmsAutoVerificationEnabled ?
              <button className='flex items-center px-2 py-1 mr-2 truncate border border-superbill-soap rounded-full hover:bg-superbill-lavender-dark/50 ease-in-out duration-300'><span className='text-superbill-jacarta font-jakarta font-semibold text-xs' onClick={openModal}>Enable Auto-Verification</span></button>
              :
              <Tooltip
              content={
                <span style={{ whiteSpace: 'pre-line' }}>
                  Auto-Verification enabled. SuperDial will automatically place verifications calls for each new patient detected. You can view pending and completed calls in the Results tab.
                </span>
              } className="max-w-[200px] bg-black py-1 px-2">
              <button className='flex items-center px-2 py-1 mr-2 truncate border border-superbill-lavender bg-superbill-lavender rounded-full ease-in-out duration-300'><span className='text-superbill-indigo font-jakarta font-semibold text-xs flex'> <CheckIcon className="mr-1"/> Auto-Verification Enabled</span></button>
            </Tooltip>
              }
            <button className='flex items-center px-2 py-1 mx-2 truncate border border-superbill-soap rounded-full font-jakarta hover:bg-superbill-lavender-dark/50 ease-in-out duration-300'><span className='text-superbill-jacarta font-semibold text-xs' onClick={checkAll}>Verify All</span></button>
            <Tooltip
              content={
                <span style={{ whiteSpace: 'pre-line' }}>
                  This will dismiss all the patients from this table, but you’ll still be able to find them in the Patients tab
                </span>
              } className="max-w-[200px] bg-black py-1 px-2">
              <button className='flex items-center px-2 py-1 mx-2 truncate border border-superbill-soap rounded-full font-jakarta hover:bg-superbill-lavender-dark/50 ease-in-out duration-300'><span className='text-superbill-jacarta font-semibold text-xs' onClick={() => { dismissAllNewPatients() }}>{dismissAll ? <Spinner /> : 'Dismiss All'}</span></button>
            </Tooltip>

          </div>
          : null}
      </div>

      <div className="border border-superbill-soap divide-y divide-superbill-soap rounded w-full">
        {loading ? (
          <>
            <div className='w-full h-full flex justify-center items-center p-5'>
              {' '}
              <Loader />{' '}
            </div>
          </>
        ) : null}
        {!loading ? (
          <>
            <div className="divide-y divide-superbill-soap overflow-scroll w-full bg-superbill-table-grey rounded font-sans">
              {0 === pmsNewPatients.length ?
                <div className='text-center text-superbill-jacarta text-base p-5'>
                  <NoPatients className="w-full text-center mb-4" />No patients detected</div>
                :
                <div className="grid grid-cols-14 divide-x divide-superbill-soap font-sans">
                  {/* <div className="col-span-2 bg-superbill-anti-flash-white px-2 py-1">
                    <div className='flex w-full h-full justify-left items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold'>
                        Date added</span>
                    </div>
                  </div> */}
                  <div className="col-span-2 bg-superbill-anti-flash-white px-2 py-1">
                    <div className='flex w-full h-full justify-left items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold'>
                        Days Until Appt</span>
                    </div>
                  </div>
                  <div className="col-span-2 bg-superbill-anti-flash-white px-2 py-1">
                    <div className='flex w-full h-full justify-left items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold'>
                        Appt Date</span>
                    </div>
                  </div>
                  <div className="col-span-3 bg-superbill-anti-flash-white px-2 py-1">
                    <div className='flex w-full h-full justify-left items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold'>
                        Clinic</span>
                    </div>
                  </div>
                  <div className="col-span-3 bg-superbill-anti-flash-white px-2 py-1">
                    <div className='flex w-full h-full justify-left items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold'>
                        Name</span>
                    </div>
                  </div>
                  <div className="col-span-4 bg-superbill-anti-flash-white px-2 py-1 rounded-tr">
                    <div className='flex w-full h-full justify-end items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold text-end mr-2'>
                        Actions</span>
                    </div>
                  </div>
                </div>
              }
              <>
                {currentActiveResults?.map((item: any, index) => {
                  return (
                    <div className="grid grid-cols-14 bg-white hover:bg-superbill-lavender/50  ease-in-out duration-150 last:rounded-b" key={item.locationId + item.id}>
                      {/* <div className="col-span-2 px-2">
                        <div className='flex w-full h-full justify-left items-center overflow-hidden'>
                          <div className='flex items-center flex-shrink-0'><span className='text-sm text-superbill-jacarta'>{DateTime.fromJSDate(new Date(item?.created_at)).toFormat('MMMM d, yyyy')}</span>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-span-2 px-2">
                        <div className='flex w-full h-full justify-left items-center overflow-auto'>
                          {item?.next_appointment_start_time ?
                            <div className=' flex-shrink-0  truncate'><span className='text-sm text-superbill-jacarta'>{Math.ceil(DateTime.fromJSDate(new Date(item?.next_appointment_start_time)).diff(DateTime.local(), 'days').as('days'))}</span></div> : <>-</>
                          }
                          {item?.missing_insurance && item?.lastModifiedAt ? <Tooltip
                            content={
                              <span style={{ whiteSpace: 'pre-line' }}>
                                Failed to get insurance data. Update the patient’s insurance data in your PMS and click “Fetch Insurance”.
                              </span>
                            } className='bg-black py-1 px-2'>
                            <ExclamationIcon className='child:fill-[#F46F83] min-w-[16px] max-w-[16px] ml-2' />
                          </Tooltip> : null}
                        </div>
                      </div>
                      <div className="col-span-2 px-2">
                        <div className='flex w-full h-full justify-left items-center overflow-auto'>
                          {item?.next_appointment_start_time ?
                            <div className=' flex-shrink-0  truncate'><span className='text-sm text-superbill-jacarta'>{DateTime.fromJSDate(new Date(item?.next_appointment_start_time)).toFormat('MMMM d, yyyy')}</span></div> : <>-</>
                          }
                        </div>
                      </div>
                      <div className="col-span-3 px-2">
                        <div className='flex w-full h-full justify-left items-center overflow-auto'>
                          <div className=' flex-shrink-0  truncate'><span className='text-sm text-superbill-jacarta'>{item.location_name}</span></div>
                        </div>
                      </div>
                      <div className="col-span-3 px-2">
                        <div className='flex w-full h-full justify-left items-center overflow-auto'>
                          <div className=' flex-shrink-0  truncate'><span className='text-sm text-superbill-jacarta'>{item?.last_name}, {item?.first_name}</span></div></div>
                      </div>
                      <div className="col-span-4 px-2 py-1 flex justify-end">
                        <button className='flex items-center px-2 py-1 mr-1 truncate' onClick={() => { dismiss(item.location_id, item.id, index) }}><span className='text-superbill-jacarta font-jakarta font-semibold text-xs cursor-pointer hover-underline-animation'>{item?.loadingDismiss || dismissAll ? <Spinner /> : 'Dismiss'}</span></button>
                        {item.missing_insurance ?
                          <Tooltip
                            content={
                              <span style={{ whiteSpace: 'pre-line' }}>
                                {item.loadingInsurance || verifyAll ? <>This could take a minute</> : <>We’ll fetch insurance information after you’ve updated patient data in your PMS</>}
                              </span>
                            } className='bg-black py-1 px-2'>
                            <button className='flex items-center px-2 py-1 mx-2 truncate border border-superbill-soap rounded-full hover:bg-superbill-lavender-dark/50 ease-in-out duration-300' onClick={() => { fetchInsuranceData(item.location_id, item.id, index) }}><span className='text-superbill-jacarta font-jakarta font-semibold text-xs'>{item?.loadingInsurance || verifyAll ? <div className='flex gap-x-1 items-center justify-center'><span className='font-jacarta text-xs font-bold text-superbill-wild-blue-yonder'>Fetching Data...</span> <Spinner /></div> : 'Fetch Insurance Data'}</span></button>
                          </Tooltip>
                          :
                          <button className='flex items-center px-2 py-1 mx-2 truncate border border-superbill-soap rounded-full hover:bg-superbill-lavender-dark/50 ease-in-out duration-300' onClick={() => { checkInsurance(item.location_id, item.id, index) }}><span className='text-superbill-jacarta font-jakarta font-semibold text-xs'>{item?.loadingInsurance || verifyAll ? <Spinner /> : 'Verify Insurance'}</span></button>
                        }

                      </div>
                    </div>
                  )
                })}
              </>
            </div>
          </>
        ) : null}

      </div>
      {5 < pmsNewPatients?.length ?
        <div className="flex items-center justify-end flex-row px-2 pt-2 font-sans">
          <div
            className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
            onClick={() => changePagination(PaginationStates.backward)}
          >
            {currentLowerIndex == 0 ? <></> : <BackIcon />}
          </div>
          <div className="mx-2">
            <strong>{currentLowerIndex + 1} to {currentUpperIndex + 1}</strong> of{" "}
            {pmsNewPatients?.length}
          </div>
          <div
            className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
            onClick={() => changePagination(PaginationStates.forward)}
          >
            {currentUpperIndex + 1 == pmsNewPatients?.length ? <></> : <ForwardIcon />}
          </div>
        </div>
        : null}
    </>
  )
}
export default PmsNewPatientsTable;