import React, { useState } from "react";

import { DateTime } from "luxon";
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { ReactComponent as NoResults } from "assets/no-script-results.svg";
import {
  updateRobodialer,
} from "apis/agent";
import { toast } from "react-hot-toast";
import { getRobodialersResponseType } from "types/types";
import { paginationHelper, PaginationStates } from "../../../utils/pagination";
import RobodialersAdminTableMenu from "./robodialers-admin-table-menu";
import { useNavigate } from "react-router-dom";

type ScriptsResultsTableProps = {
  data: getRobodialersResponseType;
  refresh: () => void;
};

function RobodialersAdminTable({ data,refresh }: ScriptsResultsTableProps) {
  const navigate = useNavigate();
  const [currentResultsPageIndex, setCurrentResultsPageIndex] =
    useState<number>(0);

  const {
    newEntries: currentActiveResults,
    currentLowerIndex,
    currentUpperIndex,
    changePagination,
  } = paginationHelper({
    entries: data?.robodialers ?? [],
    currentPageIndex: currentResultsPageIndex,
    maxEntriesPerPage: 20,
    setCurrentPageIndex: setCurrentResultsPageIndex,
  });


  return (
    <>
      {(data?.robodialers ?? []).length === 0 ? (
        <>
          <NoResults className="w-full text-center mb-4" />
          <div className="font-sans w-full text-center items-center text-superpay-light-text-gray">
            No robodialers
          </div>
        </>
      ) : (
        <>
          <div className="grid grid-cols-10 w-full mt-4 font-semibold font-sans text-jacarta rounded-t border-t border-l border-r border-superbill-soap overflow-hidden">
            <div className="col-span-3 text-sm bg-superbill-anti-flash-white px-3 py-2">
              ID
            </div>
            <div className="col-span-3 text-sm bg-superbill-anti-flash-white px-3 py-2">
              Name
            </div>
            <div className="col-span-3 text-sm bg-superbill-anti-flash-white px-3 py-2">
              Status
            </div>
            <div className="col-span-1 text-sm bg-superbill-anti-flash-white px-3 py-2"></div>
          </div>
          <div className="w-full bg-white rounded-b border border-superbill-soap  divide-y divide-superbill-soap font-sans">
            {currentActiveResults?.map((row) => {
              return (
                <div
                  className="grid grid-cols-10 font-sans items-center hover:bg-superbill-lavender/50  ease-in-out duration-150"
                  key={`robo-admin-table-${row.id}`}
                >
                  <div className="col-span-3 text-superbill-jacarta text-base font-light px-3 py-2 flex items-center gap-x-1">
                    {row.id}
                  </div>

                  <div className="col-span-3 truncate text-superbill-jacarta text-base px-3 py-2 flex items-center">
                    {row.name}
                  </div>
                  <div className="col-span-3 truncate text-superbill-jacarta text-base px-3 py-2 flex items-center">
                    {row.status}
                  </div>

                  <div className="col-span-1 text-superbill-jacarta text-base px-3 py-2 flex items-center justify-end">
                    <RobodialersAdminTableMenu
                      status={row?.status || "not live"}
                      onViewBatches={() => {
                          const twoWeeksAgo = DateTime.now().minus({ weeks: 2 }).toISODate();
                          const twoWeeksinthefuture = DateTime.now().plus({ weeks: 2 }).toISODate();
            
                          navigate(`/agents-dashboard/qm?filterBy=dueDate&dateOption=custom&dateFrom=${twoWeeksAgo}&dateTo=${twoWeeksinthefuture}&robodialerId=${row.id}`)
                        }}
                      onChangeStatus={async() => {
                        const {httpCode} = await updateRobodialer({
                          robodialer_id: row.id,
                          updateField: "status",
                          newValue: row.status === "live" ? "not live" : "live",
                        })
                        if(httpCode === 200){
                            toast.success("Robodialer status updated successfully", {
                              iconTheme: {
                                primary: "#4a43cd",
                                secondary: "#ffffff",
                              },
                            });
                            refresh()
                        }else{
                          toast.error("Unable to update robodialer status", {
                            iconTheme: {
                              primary: "#F36F82",
                              secondary: "#ffffff",
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-end flex-row px-3 pt-2">
            <div
              className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
              onClick={() => changePagination(PaginationStates.backward)}
            >
              {currentLowerIndex == 0 ? <></> : <BackIcon />}
            </div>
            <div className="mx-2">
              <strong>
                {currentLowerIndex + 1} to {currentUpperIndex + 1}
              </strong>{" "}
              of {(data?.robodialers ?? [])?.length}
            </div>
            <div
              className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
              onClick={() => changePagination(PaginationStates.forward)}
            >
              {currentUpperIndex + 1 == (data?.robodialers ?? [])?.length ? (
                <></>
              ) : (
                <ForwardIcon />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RobodialersAdminTable;
