import React, { useState } from 'react'
import { DateTime } from 'luxon';
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as CSVIcon } from "assets/csv-icon.svg";
import { ReactComponent as CloudIcon } from "assets/cloud-icon.svg";

import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { ReactComponent as NoResults } from "assets/no-script-results.svg";
import { PaginationStates, paginationHelper } from '../utils/pagination';
import { downloadResults, downloadCallInputs, downloadZipResults } from 'apis/robodialer';
import { Tooltip } from '@material-tailwind/react';
import { addBusinessDays, addHours } from 'date-fns';
import ResultsMenu from './results-options';
import { toast } from 'react-hot-toast';
import { Batches } from 'types/types';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import { ReactComponent as LoadingIcon } from 'assets/icon_loading.svg'
import { ReactComponent as CompleteIcon } from 'assets/icon_completed.svg'
import { ReactComponent as CompleteWithErrorsIcon } from 'assets/icon_completedWithErrors.svg'



type ScriptsResultsTableProps = {
  batches: Batches;
  selectedTurnAroundTime: '2BD' | '1BD' | '4BD hours';
  canDownload: boolean;
  allowPartialDownload: boolean;
  selectedBatch: string | null;
  setSelectedBatch: React.Dispatch<React.SetStateAction<string | null>>;

}

function ScriptsResultsTable({ allowPartialDownload = false, canDownload, batches, selectedTurnAroundTime, setSelectedBatch }: ScriptsResultsTableProps) {
  const convertedArray: Batches[] = Object.keys(batches ?? {})?.map(key => ({
    id: key,
    ...batches[key]
  }));
  
  const [currentResultsPageIndex, setCurrentResultsPageIndex] =
    useState<number>(0);

  const {
    newEntries: currentActiveResults,
    currentLowerIndex,
    currentUpperIndex,
    changePagination,
  } = paginationHelper({
    entries: convertedArray,
    currentPageIndex: currentResultsPageIndex,
    maxEntriesPerPage: 10,
    setCurrentPageIndex: setCurrentResultsPageIndex,
  });

  const downloadInputs = async (scriptId, batchId) => {
    if (!scriptId || !batchId) {
      toast.error('Unable to Download', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    };
    const response = await downloadCallInputs(scriptId, batchId);
    if (response.httpCode !== 200) {
      toast.error('Unable to Download', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    };
    const data = response.data as { file?: any };
    const linkSource = `data:application/pdf;base64,${data.file}`;
    const downloadLink = document.createElement('a');
    const fileName = batchId + '_inputs.csv';
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const onCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast.success('Copied to clipboard', {
      iconTheme: {
        primary: '#4a43cd',
        secondary: '#ffffff',
      },
    }
    );
  }

  const downloadZip = async (batchId) => {

    if (!batchId) {
      toast.error('Unable to Download', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    };
    toast.success("This may take a little while. Please don't close the window.", {
      iconTheme: {
        primary: '#4a43cd',
        secondary: '#ffffff',
      },
    });
    const response = await downloadZipResults(batchId);
    if (response.httpCode !== 200) {
      toast.error('Unable to Download', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    };


    const blob = await response.data.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${batchId}.zip`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    /* const data = response.data as { file?: any };
    const linkSource = `data:application/pdf;base64,${data.file}`;
    const downloadLink = document.createElement('a');
    //const fileName = name + '.csv';
    const fileName = `${batchId}.zip`
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click(); */
  };

  const downloadResult = async (scriptId, batchId, type) => {
    let name = type ? 'success' : 'failed'
    if (!scriptId || !batchId) {
      toast.error('Unable to Download', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    };
    const response = await downloadResults(scriptId, batchId, type);
    if (response.httpCode !== 200) {
      toast.error('Unable to Download', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    };
    const data = response.data as { file?: any };
    const linkSource = `data:application/pdf;base64,${data.file}`;
    const downloadLink = document.createElement('a');
    //const fileName = name + '.csv';
    const fileName = `${batchId}_${name}.csv`
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <>
      {Object.keys(batches ?? {}).length === 0 ? <>
        <NoResults className="w-full text-center mb-4" />
        <div className="font-sans w-full text-center items-center text-superpay-light-text-gray">
          No manual script results available
        </div>
      </> :
        <>
          <div className="grid grid-cols-8 md:grid-cols-14 w-full mt-4 font-semibold font-sans text-jacarta rounded-t border-t border-l border-r border-superbill-soap overflow-hidden">
            <div className='col-span-3 text-sm bg-superbill-anti-flash-white hidden md:block px-2 py-1'>Batch ID</div>
            <div className='col-span-2 text-sm bg-superbill-anti-flash-white px-2 py-1 hidden md:block'>Date</div>
            <div className='col-span-2 text-sm hidden md:block bg-superbill-anti-flash-white px-2 py-1'>Script</div>
            <div className='col-span-2 text-sm bg-superbill-anti-flash-white hidden md:block px-2 py-1'>Input</div>
            <div className='col-span-2 text-sm bg-superbill-anti-flash-white px-2 py-1'>Calls</div>
            <div className='col-span-1 text-sm bg-superbill-anti-flash-white px-2 py-1'>Status</div>
            <div className='col-span-2 text-sm bg-superbill-anti-flash-white px-2 py-1'>Completed</div>
          </div>
          <div className='w-full bg-white rounded-b border border-superbill-soap  divide-y divide-superbill-soap font-sans'>
            {Object.keys(currentActiveResults)?.map((id) => {
              let entry: Batches = currentActiveResults[id];
              let formattedNewDate;
              let newDateResult = new Date(entry.createdAt ?? entry.dateCreated);

              if (selectedTurnAroundTime && selectedTurnAroundTime.includes('hours')) {

                const match = selectedTurnAroundTime.match(/\d+/);
                let hours = 2;
                if (match) {
                  hours = parseInt(match[0], 10);
                }
                let date = new Date(entry.createdAt ?? entry.dateCreated);
                newDateResult = addHours(date, hours)//new Date(date.getTime() + hours * 60 * 60 * 1000); 
                formattedNewDate = newDateResult.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                });
              } else if (selectedTurnAroundTime && selectedTurnAroundTime.includes('BD')) {
                const match = selectedTurnAroundTime.match(/\d+/);
                let days = 2
                if (match) {
                  days = parseInt(match[0], 10);
                }
                const formattedInitialDate = new Date(entry.createdAt ?? entry.dateCreated);
                newDateResult = addBusinessDays(formattedInitialDate, days);
                formattedNewDate = newDateResult.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                }) + ' 17:00';
              }
              return (
                <div className='grid grid-cols-8 md:grid-cols-14 font-sans items-center hover:bg-superbill-lavender/50  ease-in-out duration-150' key={id}>
                  <div className='col-span-3 text-superbill-slate-grey text-base px-2 py-1 relative truncate group hover-underline-adnimation' >
                    <span className={`truncate ${entry.status == 'processing' ? 'text-superbill-jacarta' : 'cursor-pointer hover-underline-animation font-semibold text-superbill-indigo '}`} 
                    onClick={entry.status !== "processing" ? () => setSelectedBatch(entry.id) : undefined}>{entry.id}</span>
                    <div className='absolute right-0 bottom-2 hidden group-hover:block cursor-pointer bg-[#f3f2fe] px-2' onClick={() => { onCopy(entry.id ?? '') }}>
                    <CopyIcon className='hover:fill-superbill-indigo'/>
                      </div>
                  </div>
                  <div className='col-span-2 text-superbill-slate-grey text-base font-light px-2 py-1 flex items-center gap-x-1 hidden md:block'>{DateTime.fromJSDate(new Date((entry.createdAt ?? entry.dateCreated))).toFormat('MM/dd/yyyy')} 
                  </div>
                  
                  <div className='col-span-2 truncate text-superbill-jacarta text-base hidden md:block px-2 py-1'>{entry.name ?? entry.scriptId}</div>
                  <div className='col-span-2 items-center text-superbill-jacarta text-base hidden md:flex px-2 py-1 truncate'>{entry.source === 'portal' ? <CSVIcon className='w-[14px] min-w-[14px] mr-1'/> : <CloudIcon className='w-[14px] min-w-[14px] mr-1'/>}{entry.fileName || '-'}</div>
                  <div className='col-span-2 text-superbill-jacarta text-base px-2 py-1'>
                    {entry.status === 'processing' ?
                    <span className='text-superbill-slate-grey italic'>{parseInt(entry.numSuccessful ?? '0') + parseInt(entry.numFailed ?? '0')}/{entry.numCalls ?? "-"}</span>
                    :
                    <>
                    <Tooltip
                      content={
                        <span style={{ whiteSpace: 'pre-line' }}>
                          Successful calls
                        </span>
                      } className='bg-black py-1 px-2'>
                        <span className='text-superbill-indigo p-1 bg-superbill-lavender rounded'>{parseInt(entry.numSuccessful ?? '0')}</span>
                    </Tooltip>
                    <span> : </span>
                    <Tooltip
                      content={
                        <span style={{ whiteSpace: 'pre-line' }}>
                          Failed calls
                        </span>
                      } className='bg-black py-1 px-2'>
                        <span className='text-superbill-ultra-red p-1 bg-superbill-banner-red rounded'>{parseInt(entry.numFailed ?? '0')}</span>
                    </Tooltip>
                    <span className=''> / {entry.numCalls ? (parseInt(entry.numSuccessful) + parseInt(entry.numFailed)): "-"}</span>
                    </>
                    }
                  </div>
                  <div className={`col-span-1 flex items-center justify-center uppercase text-xs rounded-full font-semibold w-fit p-2 font-jakarta h-fit ${entry.status === "processing" ? ' text-superbill-jacarta bg-superbill-anti-flash-white' : ' text-superbill-indigo bg-superbill-lavender'}`}>
                    {entry.status !== "processing" && entry.numSuccessful != entry.numCalls ? <>
                      <Tooltip
                        content={
                          <span style={{ whiteSpace: 'pre-line' }}>
                            Some of these calls failed - you may still download both successful and failed results.
                          </span>
                        } className='bg-black py-1 px-2'>
                        <CompleteWithErrorsIcon className=' min-w-[14px] fill-superbill-indigo'/>
                      </Tooltip>
                    </> : 
                    entry.status == 'completed' ? 
                    <CompleteIcon className=' min-w-[14px] fill-superbill-indigo'/>
                    :
                    <LoadingIcon className=' min-w-[14px]'/>
                    }</div>

                  <div className={`flex w-full items-center justify-between col-span-2 px-2 py-1`}>
                    {/* {entry?.completedAt} */}
                    <span className={`${entry.status !== "processing" ? 'text-superbill-jacarta' : 'italic text-superbill-slate-grey'} text-base`}>
                      {entry?.completedAt ?
                        <>{DateTime.fromJSDate(new Date(entry?.completedAt)).toFormat('MM/dd/yyyy HH:mm')}
                        </>
                        :
                        <div className='flex items-center'>
                          {entry.status === "processing" ?
                            <Tooltip
                              content={
                                <span style={{ whiteSpace: 'pre-line' }}>Estimated completion time: 
                                  Times reflect local timezone based on browser settings
                                </span>
                              } className='bg-black py-1 px-2'>
                              {/* <InfoIcon className='mx-2 child:fill-[#888888] min-w-[16px] max-w-[16px]' /> */}
                              
                              {entry?.dueDate ? 
                                <>{DateTime.fromJSDate(new Date(entry?.dueDate)).toFormat('MM/dd/yyyy HH:mm')}</>
                                 :
                                <>{formattedNewDate}</>
                              }
                            </Tooltip>
                            : null}
                        </div>
                      }
                    </span>
                    <div>
                      <ResultsMenu
                        showSucessful={(allowPartialDownload || entry.status !== "processing") && !!entry.numSuccessful && 0 < +entry.numSuccessful}
                        showFailed={(allowPartialDownload || entry.status !== "processing") && !!entry.numFailed && 0 < +entry.numFailed}
                        showRetry={false}
                        onDownload={async (e: boolean) => {
                          downloadResult(entry.scriptId, entry.id, e)
                        }}
                        showDownloadZip={
                          canDownload &&
                          (!!entry.numCalls && +entry.numCalls <= 50) &&
                          ((!!entry.numSuccessful && 0 < +entry.numSuccessful) ||
                            (!!entry.numFailed && 0 < +entry.numFailed))
                        }
                        onDownloadZip={async () => {
                          downloadZip(entry.id)
                        }}
                        retryCalls={() => {

                        }}
                        onDownloadInputs={() => {
                          downloadInputs(entry.scriptId, entry.id)
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="flex justify-end flex-row px-2 pt-2">
            <div
              className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
              onClick={() => changePagination(PaginationStates.backward)}
            >
              {currentLowerIndex == 0 ? <></> : <BackIcon />}
            </div>
            <div className="mx-2">
              <strong>{currentLowerIndex + 1} to {currentUpperIndex + 1}</strong> of{" "}
              {convertedArray?.length}
            </div>
            <div
              className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
              onClick={() => changePagination(PaginationStates.forward)}
            >
              {currentUpperIndex + 1 == convertedArray?.length ? <></> : <ForwardIcon />}
            </div>
          </div>
        </>
      }
    </>
  )
}

export default ScriptsResultsTable