import { Fragment, forwardRef, useState, useRef, useImperativeHandle } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { runScript } from 'apis/robodialer';

interface TableRow {
  [key: string]: string;
}


type DynamicTableProps = {
  MAX_ROWS?: number;
  id: string;
  fields?: unknown | null;
  setFields: React.Dispatch<React.SetStateAction<Array<unknown | null>>>;
}

const DynamicTable = forwardRef(({ MAX_ROWS = 5, fields, id }: DynamicTableProps, ref) => {
  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();
  const [rows, setRows] = useState<TableRow[]>([{}]);
  const [invalidFields, setInvalidFields] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = async () => {
    console.log('rows', rows, isDirty, isValid);
    if (!isValid) {
      setInvalidFields(!isValid)
      return;
    }
    const updatedCsvBlob = new Blob([rowsToCSV()], { type: 'text/csv' });
    const updatedCsvFile = new File([updatedCsvBlob], 'manually.csv', { type: 'text/csv' });
    let { httpCode } = await runScript(updatedCsvFile, id, 'manually.csv')
    return httpCode;
  };

  const handleAddRow = () => {
    setRows((prevRows) => [...prevRows, {}]);
  };

  const handleDeleteRow = (index) => {
    if (1 < Object.keys(rows).length) {
      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows.splice(index, 1);
        return updatedRows;
      });
    }

  };

  const handleInputChange = (event, rowIndex, fieldName) => {
    if (invalidFields) {
      setInvalidFields(false)
    }
    const { value } = event.target;
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex][fieldName] = value;
      return updatedRows;
    });
  };

  const rowsToCSV = () => {
    const headers = Object.keys(fields!);
  
    let csvContent = headers.join(',');
  
    rows.forEach((row) => {
      const rowValues = headers.map((header) => {
        // Escape ","
        const escapedValue = row[header] ? `"${row[header]}"` : '""';
        return escapedValue;
      });
      csvContent += '\n' + rowValues.join(',');
    });
    /* rows.forEach((row) => {
      const rowValues = headers.map((header) => row[header] || '');
      csvContent += '\n' + rowValues.join(',');
    }); */
  
    return csvContent;
  };
  

  useImperativeHandle(ref, () => ({ onSubmit }));

  return (
    <div className='w-full overflow-x-auto m-4 pb-10'>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <div className='rounded border border-1 border-superbill-soap bg-white mb-4'>
        <table className='w-full'>
          <thead>
            <tr>
              {Object.keys(fields!).map((fieldName) => (
                <th className='px-3 bg-superbill-anti-flash-white py-2 font-normal text-superbill-jacarta rounded-t text-base' key={fieldName}>{fieldName}</th>
              ))}
              <th className='px-3 bg-superbill-anti-flash-white py-2 font-normal text-superbill-jacarta rounded-t'>Options</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Object.keys(fields!).map((fieldName) => (
                  <td className='px-3 py-2 bg-white rounded-b' key={fieldName}>
                    <input
                      type="text"
                      placeholder=""
                      className="w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-superbill-jacarta text-base"
                      {...register(`${rowIndex}.${fieldName}`, { required: fields![fieldName].required })}
                      value={row[fieldName] || ''}
                      onChange={(event) => handleInputChange(event, rowIndex, fieldName)}
                    />
                    {errors[`${rowIndex}.${fieldName}`] && (
                      <span className="text-red-500">This field is required</span>
                    )}
                  </td>
                ))}
                <td className='px-3 py-2 bg-white rounded-b'>
                  <Menu as='div' className='relative inline-block text-left'>
                    <div>
                      <Menu.Button className='flex items-center focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:border-superbill-indigo focus:outline-none border border-1 border-superbill-soap hover:border-superbill-ultramarine px-2 py-1 rounded'>
                        <span className='sr-only'>Open options</span>
                        <svg
                          width='5'
                          height='19'
                          viewBox='0 0 5 19'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M2.625 14.0741C3.93549 14.0741 5 15.178 5 16.537C5 17.8961 3.93549 19 2.625 19C1.31451 19 0.25 17.8961 0.25 16.537C0.25 15.178 1.31451 14.0741 2.625 14.0741ZM2.625 7.03704C3.93549 7.03704 5 8.14097 5 9.5C5 10.859 3.93549 11.963 2.625 11.963C1.31451 11.963 0.25 10.859 0.25 9.5C0.25 8.14097 1.31451 7.03704 2.625 7.03704ZM5 2.46296C5 3.82199 3.93549 4.92593 2.625 4.92593C1.31451 4.92593 0.25 3.82199 0.25 2.46296C0.25 1.10394 1.31451 0 2.625 0C3.93549 0 5 1.10394 5 2.46296Z'
                            fill='#727272'
                          />
                        </svg>
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'>
                      <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded bg-white shadow-lg focus:outline-none'>
                        <div className='font-normal py-1 border border-superbill-soap border-1 rounded'>

                          <Menu.Item >
                            <div
                              onClick={() => handleDeleteRow(rowIndex)}
                              className={`text-superbill-jacarta cursor-pointer w-full px-4 py-2 text-left flex items-center ease-in-out duration-150 ${true ? 'hover:bg-superbill-ghost-white hover:text-superbill-indigo' : ' text-superpay-light-text-gray rounded-t'}`}
                            >
                              <div className="text-base" >Delete</div>
                            </div>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>


                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </form>
      {invalidFields ? <div className="text-center text-red-500 text-sm my-3">Invalid fields</div> :null}
      <button onClick={handleAddRow} disabled={Object.keys(rows).length >= MAX_ROWS} className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'>
      Add Row
      </button>
    </div >
  );
});

export default DynamicTable;
