import React, { useState } from "react";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import Modal from "react-modal";
import { inviteMember } from "apis/robodialer";
import { useForm } from 'react-hook-form';
import toast from "react-hot-toast";

// custom styles
const customStyles = {
  content: {
    height: "max(50%,400px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  isOpen: boolean;

  onSuccess: (data) => void;
  onClose: () => void;
}


export default function AddAgentModal({ isOpen, onSuccess, onClose }: Props) {
  
  const [isLoading, setLoading] = useState<boolean>(false);
  const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({mode: 'onChange'});

  const toggleModal = () => {
    reset()
    onClose()
  }
  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true)
    await onSuccess(data)
    setLoading(false)
  };
  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Add agent
          </nav>
          <div className="flex-grow overflow-y-auto px-9 py-4">
            <div className="w-full bg-white rounded mb-4">
              <span className="text-sm text-superpay-light-text-gray">The agent will be able to sign up for SuperDial Agent's portal using the email you provide</span>
            </div>
            <form>
              <div className="mb-4">
                <label htmlFor="email" className="block text-superbill-jacarta text-sm font-bold">Email:</label>
                <input
                type="email"
                className={`w-full mb-2 rounded font-sans ${
                  errors.email
                    ? "border-red-500"
                    : "border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                }`}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                    message: "Invalid email address",
                  },
                })}
              />
              <div className="mb-1 text-red-500 text-xs">
                  {errors && errors?.email?.message && typeof errors?.email?.message === 'string' ? <span>{errors?.email?.message}</span> : <span></span> } 
                </div>
              </div>

              <div className="mb-4">
                <div className="flex flex-col my-2 gap-y-3">
                  <label className="items-center">
                    <input type="checkbox" {...register('allowAudit')} className="form-checkbox" />
                    <span className="ml-2 text-superbill-jacarta text-sm">Allow Audit</span>
                    <div className="text-superbill-slate-grey text-sm">May audit calls</div>
                  </label>
                </div>
              </div>
              <div className="mb-4">
                <div className="flex flex-col my-2 gap-y-3">
                  <label className="items-center">
                    <input type="checkbox" {...register('allowAdmin')} className="form-checkbox" />
                    <span className="ml-2 text-superbill-jacarta text-sm">Admin User</span>
                    <div className="text-superbill-slate-grey text-sm">May see/add robodialers and agents.</div>
                  </label>
                </div>
              </div>

            </form>
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={toggleModal}
            >
              Close
            </button>
            <button
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading || !isValid}
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'>
              {isLoading ? <Spinner /> : <>Invite</>}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
