import React, { useState,useRef, useEffect } from 'react';
import { UseFormRegister } from "react-hook-form";


interface QuestionInputProps {
  register: UseFormRegister<any>;
  index: number | string;
  iKey: number | string;
  error?: string | undefined;
  value?: string | undefined;
  onChange?: (e) => void;
  disabled?:boolean;

  className?:string;

  allowedString: string[];
}


const StyledQuestionInput: React.FC<QuestionInputProps> = ({allowedString, iKey, register, error, index, value, onChange, disabled=false, className='w-full mb-2' }) => {

  const [background, setBackground] = useState<string>('');
  const [responseInstructions, setResponseInstructions] = useState<string>('');
  const editorRef = useRef<HTMLDivElement>(null);
  const [posObj, setPosObj] = useState<{
    editor: any;
    pos:{
      pos:number;
      done: boolean;
      childIndex?: number;
    }}>({
    editor: null,
    pos: {
      pos:0,
      done: false,
      childIndex: 0
    }})
  const parse = (text: string) => {    
    const regex = /{([^}]*)}/g;
    return text.replace(regex, (match, content) => {
      const isMatch = allowedString.some(word => content.includes(word));
      const colorClass = isMatch ? 'bg-superbill-lavender rounded p-1 text-superbill-indigo' : 'bg-superbill-banner-red rounded p-1 text-superbill-ultra-red';

      return `<code class="${colorClass}">${match}</code>`;
    });
    /* return (
      text
        .replace(/\*{2}(.*?)\*{2}/gm, '**<strong>$1</strong>**') // bold
        .replace(/(?<!\*)\*(?!\*)(.*?)(?<!\*)\*(?!\*)/gm, '*<em>$1</em>*') // italic
        .replace(/\n/gm, '<br>')
        .replace(/\t/gm, '&#9')
    ); */
  };

  const getCursorPosition = (
    parent: Node,
    node: Node | null,
    offset: number,
    stat: { pos: number; done: boolean, childIndex?: number }
  ) => {
    if (stat.done) return stat;

    let currentNode: Node | null = null;
    if (parent.childNodes.length === 0) {
      stat.pos += parent.textContent ? parent.textContent.length : 0;
    } else {
      for (let i = 0; i < parent.childNodes.length && !stat.done; i++) {
        currentNode = parent.childNodes[i];
        if (currentNode === node) {
          stat.pos += offset;
          stat.done = true;
          stat.childIndex = i;
          return stat;
        } else {
          stat.childIndex = i;
          getCursorPosition(currentNode, node, offset, stat);
        }
      }
    }
    return stat;
  };

  const setCursorPosition = (
    parent: Node,
    range: Range,
    stat: { pos: number; done: boolean; childIndex?: number }
  ) => {
    
    if (stat.done) return range;

    if (parent.childNodes.length === 0) {
      if (parent.textContent && parent.textContent.length >= stat.pos) {
        if (stat.childIndex) {
          range.setStart(parent.childNodes[stat.childIndex], 0);
        }else{
          range.setStart(parent, stat.pos);
        }
        stat.done = true;
      } else {
        stat.pos = stat.pos - (parent.textContent ? parent.textContent.length : 0);
      }
    } else {
      for (let i = 0; i < parent.childNodes.length && !stat.done; i++) {
        const currentNode = parent.childNodes[i];
        
        setCursorPosition(currentNode, range, stat);
      }
    }
    return range;
  };

  useEffect(() => {
    const editor = editorRef.current;
    if (!editor) return;

    editor.innerHTML = parse(value ?? '');
  }, []);

  useEffect(() => {
    
    if (posObj && editorRef.current) {
      
      const sel = window.getSelection()
      sel?.removeAllRanges();
      let pp = posObj.pos
      const range = setCursorPosition(editorRef.current, document.createRange(), {pos:posObj.pos.pos, done:false});
      range.collapse(true);
      sel?.addRange(range);
    }
  },[posObj])

  
  return (
      <div
        key={iKey}
        {...register(`${index}.question`)}
        dangerouslySetInnerHTML={{__html:value ?? ''}}
        className={`${className} rounded focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:border-superbill-indigo focus:outline-none text-base textarea border mb-2 focus:bg-superbill-light-gray-background hover:bg-superbill-anti-flash-white ${disabled? 'bg-superbill-anti-flash-white' : ''} p-2 font-sans ease-in-out duration-200`}
        style={{ whiteSpace: '-moz-pre-space' } as unknown as React.CSSProperties}
        contentEditable={!disabled}
        onInput={(e) => {
          const editor = editorRef.current;
          if (editor) {
            let sel = window.getSelection();
            let node = sel?.focusNode;
            let offset = sel?.focusOffset;
            const activeElement = document.activeElement;

            if(node && offset){

              const pos = getCursorPosition(editor, node, offset, { pos: 0, done: false });
              if (offset === 0) pos.pos += 0.5;
              setPosObj({editor, pos})
              const text = editor.innerText
              const formatted = parse(editor.innerText);
              
              if (onChange) {
                onChange({target: {name:`${index}.question`, value:formatted}})
              }
              editor.innerHTML = formatted;
              e.preventDefault()
              return
            }
          }
        }}
        ref={editorRef}
      />
  );
};

export default StyledQuestionInput;