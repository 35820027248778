
export enum PaginationStates {
    forward = 'forward',
    backward = 'backward'
}
type PaginateTypes<T> = {
    entries: Array<T>,
    maxEntriesPerPage?: number,
    currentPageIndex: number,
    setCurrentPageIndex: (currentIndex: number) => void;
}

type PaginationReturn<T> = {
    newEntries: Array<T>,
    currentLowerIndex: number,
    currentUpperIndex: number,
    setNewLowerIndex: (state: number) => void
    changePagination: (state: PaginationStates) => void
}

export const paginationHelper = <T>({ entries, maxEntriesPerPage = 10, currentPageIndex, setCurrentPageIndex }: PaginateTypes<T>): PaginationReturn<T> => {

    const currentLowerIndex = currentPageIndex === 0 ? 0 : currentPageIndex * maxEntriesPerPage;
    const currentUpperIndex = Math.min(
        currentLowerIndex + maxEntriesPerPage - 1,
        entries?.length - 1
    );

    const changePagination = (state: PaginationStates): void => {
        const currentIndex = currentPageIndex;
        if (state === PaginationStates.forward && currentUpperIndex < entries?.length - 1) {
            setCurrentPageIndex(currentIndex + 1);
        }
        if (state === PaginationStates.backward && currentLowerIndex > 0) {
            setCurrentPageIndex(currentIndex - 1);
        }
        return
    };
    const newEntries = entries?.slice(
        currentLowerIndex,
        currentUpperIndex + 1
    );
    const setNewLowerIndex = (value) => {
        if (0 < currentLowerIndex && currentUpperIndex < entries?.length - 1 ) {
            setCurrentPageIndex(value);
        }
    }
    return { newEntries, currentLowerIndex, currentUpperIndex, changePagination, setNewLowerIndex }
}
