import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

export interface RobodialersAdminTableProps {
  onChangeStatus: () => void;
  onViewBatches: () => void;
  status: "live" | "not live";
}

export default function RobodialersAdminTableMenu(
  props: RobodialersAdminTableProps
) {
  
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center text-gray-400 hover:text-gray-600 ">
          <span className="sr-only">Open options</span>
          <svg
            width="5"
            height="19"
            viewBox="0 0 5 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.625 14.0741C3.93549 14.0741 5 15.178 5 16.537C5 17.8961 3.93549 19 2.625 19C1.31451 19 0.25 17.8961 0.25 16.537C0.25 15.178 1.31451 14.0741 2.625 14.0741ZM2.625 7.03704C3.93549 7.03704 5 8.14097 5 9.5C5 10.859 3.93549 11.963 2.625 11.963C1.31451 11.963 0.25 10.859 0.25 9.5C0.25 8.14097 1.31451 7.03704 2.625 7.03704ZM5 2.46296C5 3.82199 3.93549 4.92593 2.625 4.92593C1.31451 4.92593 0.25 3.82199 0.25 2.46296C0.25 1.10394 1.31451 0 2.625 0C3.93549 0 5 1.10394 5 2.46296Z"
              fill="#727272"
            />
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded bg-white shadow-lg focus:outline-none">
          <div className="py-1 border border-superbill-soap border-1 rounded">
            <Menu.Item>
              <div
                className="hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray cursor-pointer w-full px-4 py-2 text-left flex items-center ease-in-out duration-150"
                onClick={props?.onViewBatches}
              >
                <div className="mr-2 text-base">View Batches</div>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div
                className="hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray cursor-pointer w-full px-4 py-2 text-left flex items-center ease-in-out duration-150"
                onClick={props.onChangeStatus}
              >
                <div className="mr-2 text-base">{props?.status === 'live' ? 'Change to not-live' : 'Change to live' }</div>
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
