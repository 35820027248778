export enum AddPolicyModalStates {
    BasicInfo = 'BasicInfo',
    PolicyInfo = 'PolicyInfo',
    Success = 'Success',
}

export enum EditOrAddPolicyModalStates {
    AddPolicy = 'AddPolicy',
    EditPolicy = 'EditPolicy',
}

export enum EditOrAddPatientModalStates {
    AddPatient = 'AddPatient',
    EditPatient = 'EditPatient'
}

// TODO: Approved, Processing, Rejected
export enum CardStatus {
    Failed = 'Failed',
    Processing = 'Processing',
    Success = 'Success',
    Unlinked = 'Unlinked'
}

export enum ClaimStatus {
    Approved = 'Approved',
    Finished = 'Finished',
    Pending = 'Pending',
    PartiallyApproved = 'Partially Approved',
    Processing = 'Processing',
    ReviewingBill = 'Reviewing Bill',
    Rejected = 'Rejected',
    Denied = 'Denied',
    Reviewing = 'Reviewing',
}

export enum UploadBillModalStates {
    IsBillPaid = 'IsBillPaid',
    UploadProvider = 'UploadProvider',
    UploadBill = 'UploadBill',
    HowMuch = 'HowMuch',
    PaymentOptions = 'PaymentOptions',

    AcceptPayment = 'AcceptPayment',
    InviteProvider = 'InviteProvider',
    PaymentSubmitted = 'PaymentSubmitted',
    UploadOptions = 'UploadOptions',
    FileClaimCode = 'FileClaimCode',
    FileClaimVisit = 'FileClaimVisit',
    SubscriptionOptions = 'SubscriptionOptions',
    SubscriptionPlanOptions = 'SubscriptionPlanOptions',
}

export enum ManuallyInvitePatientsModalStates {
    Invite = 'Invite',
    Success = 'Success',
    PatientPreview = 'PatientPreview',
    EmailPreview = 'EmailPreview',
}

export enum PaymentsModalStates {
    Options = 'Options',
    Authorization = 'Authorization',
}

export enum UnpaidBillsModalState {
    CollectData = 'CollectData',
    Success = 'Success',
}


export enum WelcomeModalState {
    CollectData = 'CollectData',
    Success = 'Success',
}

export enum NewCaseModalState {
    TpaBills = 'TpaBills',

    UploadDocuments = 'UploadDocuments',
    CaseDetails = 'CaseDetails',
    HIPAAAuthorization = 'HIPAAAuthorization',
    HIPAAAuthorizationSignature = 'HIPAAAuthorizationSignature',
    PaymentMethod = 'PaymentMethod',
}

export enum BenefitsCheckState {
    Processing = 'Processing',
    NoOONBenfits = 'NoOONBenfits',
    HasOONBenefits = 'HasOONBenefits',
}

export enum BuilderState {
    Setup = "Setup",
    Questions = "Questions",
    Communication = "Communication",
    Result = "Result"
}

export enum ProviderVobPage {
    Benefits,
    CreateAccount,
    Results,
}

export enum BenefitsCheckInitiator {
    External = 'external',
    Provider = 'provider',
}


export enum RunScriptModalStates {
    ScriptSelection = 'ScriptSelection',
    UploadCsv = 'UploadCsv',
    MatchHeaders = 'MatchHeaders',
    
    Success = 'Success',
    Error = 'Error',
}

export enum PlaceDemoCallsModalStates {
    ScriptSelection = 'ScriptSelection',
    UploadCsv = 'UploadCsv',
    MatchHeaders = 'MatchHeaders',
    
    Success = 'Success',
    Error = 'Error',
}


export enum SuperpayPlusModalStates {
    PlanSelection = 'PlanSelection',
    Success = 'Success',
    Error = 'Error',
}


export enum SuperpayPlusNudgeModalStates {
    PlanSelection = 'PlanSelection',
    Success = 'Success',
    Error = 'Error',
}


export enum SubscriptionModalStates {
    SubscriptionOptions = 'SubscriptionOptions',
    SubscriptionPlanOptions = 'SubscriptionPlanOptions',
    Error = 'Error',
    Success = 'Success',
    Unsubscribe = 'Unsubscribe',
    Patients = 'Patients'

}


export enum RobodialersSubscriptionModalStates {
    SubscriptionOptions = 'SubscriptionOptions',
    SubscriptionPlanOptions = 'SubscriptionPlanOptions',
    Error = 'Error',
    Success = 'Success',

    Unsubscribe = 'Unsubscribe',
    UnsubscribeSuccessful = 'UnsubscribeSuccessful'

}


export enum BillingPages {
    Home = 'Home',
    Tasks = 'Tasks',
    Support = 'Support',
}

export enum TicketsModalStates {
    TicketForm = 'TicketForm',
}

export enum SuperDialTutorialModalStates{
    CreateScriptInfo = 'CreateScriptInfo',
    RunScriptInfo = 'RunScriptInfo',
    ResultsInfo = 'ResultsInfo',
    CreditsInfo = 'CreditsInfo',
}

export enum GenerateSecretModalStates{
    GenerateSecret = 'GenerateSecret',
    RegenerateSecret = 'RegenerateSecret',
}

export enum SimulateCallModalStates{
    ScriptSelection = 'ScriptSelection',
}
export enum AutoVerificationModalStates{
    EnableVerification = 'EnableVerification',
}

export enum SignUpPageStates{
    SignupForm = 'SignupForm',
    Verification = 'Verification',
}


export enum AddToPhonebookModalStates{
    OH = 'officeHours',
    IVR = 'ivrInstructions',
}
