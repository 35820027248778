import React, { useEffect, useMemo, useState } from 'react'
import Loader from 'components/Loader';
import { ReactComponent as ExclamationIcon } from 'assets/exclamation.svg';
import { DateTime } from 'luxon';
import { addBusinessDays, addHours } from 'date-fns';
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { ReactComponent as NoPatients } from "assets/no-patient-results.svg";
import { ReactComponent as CloseIcon } from 'assets/x.svg';

import { archiveResults, retryPmsCalls } from '../apis/robodialer';
import { Tooltip } from '@material-tailwind/react';
import { toast } from 'react-hot-toast';
import { PMSPatientData } from 'types/types';
import { FixedSizeList, } from 'react-window';
import { Controller, useForm } from 'react-hook-form';
import RetryCallsModal from './retry-calls-modal';
import { ReactComponent as ChevronUpIcon } from "assets/chevron-up-icon.svg";
import { ReactComponent as ChevronDownIcon } from 'assets/chevron-down-icon.svg';


interface FormType {
  gotoInput: number;
}



type PMSResultsTableProps = {
  pmsResults: {
    calls: PMSPatientData[],
    limit: number,
    offset: number,
  };
  selectedPatient: string | null;
  setSelectedPatient: React.Dispatch<React.SetStateAction<string | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: (limit?: number, offset?: number) => void,
  total: number;
  archived: boolean;
}

function PMSResultsTable({ archived, pmsResults, setSelectedPatient, refresh, total, setLoading }: PMSResultsTableProps) {
  const [isArchiving, setIsArchiving] = useState<boolean>(false);
  const [isRetrying, setIsRetrying] = useState<boolean>(false);
  const [isRetryModalOpen, setIsRetryModalOpen] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const handleSort = (key: string) => {
    if (sortBy === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(key);
      setSortDirection('asc');
    }
  };

  const uniqueHeaders = useMemo(() => {
    const uniqueHeaderSet = new Set<string>();
    uniqueHeaderSet.add('notes');

    Object.values(pmsResults?.calls ?? []).forEach((patient) => {
      if (patient?.script?.customMode) {
        const headers = patient?.headers instanceof Array ? patient?.headers : [];
        (headers ?? []).forEach((header) => {
          uniqueHeaderSet.add(header);
        });
      } else {
        Object.keys((patient.headers)).forEach((header) => {
          if (patient.headers[header]) {
            uniqueHeaderSet.add(header);
          }
        });
      }
    });


    const uniqueHeadersArray = Array.from(uniqueHeaderSet).sort((a, b) => {
      const numA = parseFloat((a as string).split('-')[0]);
      const numB = parseFloat((b as string).split('-')[0]);
      return numA - numB;
    });

    return uniqueHeadersArray;
  }, [pmsResults?.calls]);


  const [selectAll, setSelectAll] = useState(false);

  const [selectedItems, setSelectedItems] = useState(new Set<string>());

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set<string>(prevSelectedItems);
      Object.keys(pmsResults?.calls ?? {}).forEach((index) => {
        const itemKey = `${pmsResults?.calls[index].call_id}`;
        if (!newSelectedItems.has(itemKey)) {
          newSelectedItems.add(itemKey);
        }else{
          newSelectedItems.delete(itemKey);
        }
      });
      return newSelectedItems;
    });
  };

  const handleSelectItem = (call_id: string) => {
    const key = `${call_id}`;
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set<string>(prevSelectedItems);
      if (newSelectedItems.has(key)) {
        newSelectedItems.delete(key);
      } else {
        newSelectedItems.add(key);
      }
      return newSelectedItems;
    });
  };
  const [searchValue, setSearchValue] = useState('');


  const filteredAndSortedPatients = useMemo(() => {
    const convertedArray = Object.keys(pmsResults.calls ?? {})?.map(key => ({
      //id: key,
      ...pmsResults.calls[key]
    }));

    console.log('pmsResults', pmsResults);

    let filteredPatients = [...convertedArray];

    if (searchValue) {
      filteredPatients = filteredPatients.filter((patient) =>
        //patient[searchType].toLowerCase().includes(searchValue.toLowerCase())
        patient['first_name'].toLowerCase().includes(searchValue.toLowerCase()) ||
        patient['last_name'].toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    /* if (statusFilter !== 'all') {
      filteredPatients = filteredPatients.filter((patient) =>
        patient?.call?.status === statusFilter || (!patient?.call?.status && statusFilter === 'processing')
      );
    } */
    filteredPatients.sort((a, b) => {
      if (a?.call?.status === 'processing' && b?.call?.status !== 'processing') {
        return -1;
      }
      if (a?.call?.status !== 'processing' && b?.call?.status === 'processing') {
        return 1;
      }
      const dateA = DateTime.fromJSDate(new Date(a.assignedAt ?? a.date_created)).toFormat('MM-dd-yyyy');
      const dateB = DateTime.fromJSDate(new Date(b.assignedAt ?? b.date_created)).toFormat('MM-dd-yyyy');
      if (dateA === null && dateB !== null) {
        return 1;
      }
      if (dateA !== null && dateB === null) {
        return -1;
      }
      if (dateA !== null && dateB !== null && dateA !== dateB) {
        if (dateB > dateA) {
          return 1
        } else if (dateB < dateA) {
          return -1
        }
      }
      const nameA = `${a.last_name} ${a.first_name}`;
      const nameB = `${b.last_name} ${b.first_name}`;
      return nameA.localeCompare(nameB);

    })
    return filteredPatients;
  }, [sortBy, sortDirection, searchValue, /* searchType */]);


  const { setValue, control, } = useForm<FormType>();

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);

    const newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) {
      setValue('gotoInput', newValue);
      if (0 <= (newValue - 1) && (newValue - 1) < Math.ceil(total! / pmsResults?.limit)) {
        await refresh(((newValue - 1) * pmsResults?.limit))
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  const retryCalls = async () => {
    setIsRetrying(true);
    const response = await retryPmsCalls(Array.from(selectedItems));
    const {retryCategories} = response.data;
    const toastMessages: string[] = [];
    const patientNames: { [key: string]: string[] } = {};

    const computeToastMessage = (category, callPatientNames: string[]) => {
      const pluralS = callPatientNames.length > 1 ? 's' : '';
      let callsClause = `${callPatientNames.length} call${pluralS} (${callPatientNames.join(', ')}) `;
      let reasonClause = '';

      switch (category) {
        case 'success':
          reasonClause = 'successfully added to the queue.';
          break;
        case 'completedInPastWeek':
          reasonClause = 'could not be placed because successful calls cannot be retried within 7 days of completion.'
          break;
        case 'alreadyRetrying':
          reasonClause = `could not be placed due to other existing call${pluralS} in progress for the same patient${pluralS}.`
          break;
        case 'currentlyPending':
          reasonClause = `could not be placed because the call${callPatientNames.length > 1 ? 's are' : ' is'} already in progress.`
          break;
        case 'error':
          reasonClause = 'could not be placed. This may be due to incomplete information in the PMS.'
          break;
      }

      return `${callsClause}${reasonClause}`;
    }

    for (const category in retryCategories) {
      patientNames[category] = [];
      for (const callID of retryCategories[category]) {
        patientNames[category].push(`${pmsResults.calls[callID].first_name} ${pmsResults.calls[callID].last_name}`);
      }

      if (patientNames[category].length > 0) {
        toastMessages.push(computeToastMessage(category, patientNames[category]));
      }
    }

    refresh(pmsResults.offset, pmsResults.limit);
    setIsRetryModalOpen(false);
    setIsRetrying(false);
    toast(
      (t) => (
        <div className="flex flex-col">
          {toastMessages.map((message) => (
            <div key={message} className="flex flex-row">
              <p className="my-1.5">{message}</p>
            </div>
          ))}
          <CloseIcon className="absolute right-2 top-2 w-3 cursor-pointer" onClick={() => toast.dismiss(t.id)} />
        </div>
      ),
      {
        duration: Infinity,
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      }
    )
  }

  useEffect(() => {
    setValue('gotoInput', (pmsResults?.offset / pmsResults?.limit + 1));
  }, [])

  const TableHeader = () => (
    <div className="flex divide-x divide-superbill-soap">
      <div className="flex items-center p-2 bg-superbill-anti-flash-white flex-shrink-0 w-[50px] h-[55px] truncate">
        <div className='flex w-full h-full justify-center items-center'>
          <input
            type="checkbox"
            className="rounded-sm checked:border-superbill-ultramarine"
            checked={selectAll}
            onChange={handleSelectAll}
          />
        </div>
      </div>
      <div className="flex items-center p-2 bg-superbill-anti-flash-white flex-shrink-0 w-[150px] h-[55px] truncate">
        <span className="font-bold text-superbill-jacarta text-sm">Date Verified</span>
      </div>
      <div className="p-2 bg-superbill-anti-flash-white flex-shrink-0 w-[130px] h-[55px] truncate">
        <span className="font-bold text-superbill-jacarta text-sm">Date of Birth</span>
      </div>
      <div className="p-2 bg-superbill-anti-flash-white flex-shrink-0 w-[130px] h-[55px] truncate">
        <span className="font-bold text-superbill-jacarta text-sm">First Name</span>
      </div>
      <div className="p-2 bg-superbill-anti-flash-white flex-shrink-0 w-[130px] h-[55px] truncate">
        <span className="font-bold text-superbill-jacarta text-sm">Last Name</span>
      </div>
      {uniqueHeaders.map((header) => (
        <div
          key={`header-${header}`}
          className={`p-2 bg-superbill-anti-flash-white min-w-[150px] w-full h-[55px] overflow-auto flex items-center justify-center`}
        >
          <Tooltip
            content={
              <span style={{ whiteSpace: 'pre-line' }}>
                <span className="text-sm">{header}</span>
              </span>
            } className='bg-black py-1 px-2'
          >
            <span className="font-bold text-sm capitalize">{header.split('-')[1] ?? header.split('-')[0]}</span>
          </Tooltip>
        </div>
      ))}
    </div>
  );
  const VirtualTableContext = React.createContext<{
    top: number;
    setTop: (top: number) => void;
    header: React.ReactNode;
    footer: React.ReactNode;
  }>({
    top: 0,
    setTop: (value: number) => { },
    header: <></>,
    footer: <></>,
  });

  const VirtualTable = () => {
    const Row = ({ index, style }) => {
      const patient_index = index - 1
      const patient = filteredAndSortedPatients[patient_index];
      let formattedNewDate;
      let newDateResult = new Date(DateTime.fromJSDate(new Date(patient?.assignedAt ?? patient?.date_created)).toFormat('MMM d, yyyy'))
      if (patient?.selectedTurnAroundTime && patient?.selectedTurnAroundTime.includes('hours')) {
        const match = patient?.selectedTurnAroundTime.match(/\d+/);
        let hours = 2;
        if (match) {
          hours = parseInt(match[0], 10);
        }
        let date = new Date(DateTime.fromJSDate(new Date(patient?.assignedAt ?? patient?.date_created)).toFormat('MMM d, yyyy'))
        newDateResult = addHours(date, hours)
        formattedNewDate = newDateResult.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        });
      } else if (patient?.selectedTurnAroundTime && patient?.selectedTurnAroundTime.includes('BD')) {
        const match = patient?.selectedTurnAroundTime.match(/\d+/);
        let days = 2
        if (match) {
          days = parseInt(match[0], 10);
        }
        const formattedInitialDate = new Date(DateTime.fromJSDate(new Date(patient?.assignedAt ?? patient?.date_created)).toFormat('MMM d, yyyy'))
        newDateResult = addBusinessDays(formattedInitialDate, days);
        formattedNewDate = newDateResult.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }) + ' 17:00';
      }

      return (<div style={style}>
        {index === 0 ?
          <>
            <div className="w-max border border-superbill-soap divide-y divide-superbill-soap sm:max-md:rounded md:rounded-r">
              <div className="flex w-full  divide-x divide-superbill-soap text-superbill-jacarta ">
                <div className="md:hidden flex w-full divide-x divide-superbill-soap ">
                  <div className="flex items-center p-2 bg-superbill-anti-flash-white flex-shrink-0 w-[50px] h-[55px] truncate">
                    <div className='flex w-full h-full justify-center items-center'>
                      <input
                        type="checkbox"
                        className="rounded-sm checked:border-superbill-ultramarine"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </div>
                  </div>
                  <div className="flex items-center p-2 bg-superbill-anti-flash-white flex-shrink-0 w-[150px] h-[55px] truncate">
                    <span className="font-bold text-superbill-jacarta text-sm">Date Verified</span>
                    <span
                      className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200"
                      onClick={() => handleSort('end')}
                    >
                      {sortDirection === 'asc' && ['end', 'created_at'].includes(sortBy ?? '') ? (
                        <ChevronUpIcon className="cursor-pointer h-5 w-5" aria-hidden="true" />
                      ) : (
                        <ChevronDownIcon className="h-5 w-5 cursor-pointer" aria-hidden="true" />
                      )}
                    </span>
                  </div>
                  <div className="flex items-center p-2 bg-superbill-anti-flash-white flex-shrink-0 w-[130px] h-[55px] truncate">
                    <span className="font-bold text-superbill-jacarta text-sm">Date of Birth</span>
                  </div>
                  <div className="flex items-center p-2 bg-superbill-anti-flash-white flex-shrink-0 w-[130px] h-[55px] truncate">
                    <span className="font-bold text-superbill-jacarta text-sm">First Name</span>
                  </div>
                  <div className="flex items-center p-2 bg-superbill-anti-flash-white flex-shrink-0 w-[130px] h-[55px] truncate">
                    <span className="font-bold text-superbill-jacarta text-sm">Last Name</span>
                  </div>
                </div>
                {uniqueHeaders.map((header) => (
                  <div
                    key={`header-${header}`}
                    className={`p-2 bg-superbill-anti-flash-white w-[150px] h-[55px] overflow-auto`}
                  >
                    <Tooltip
                      content={
                        <span style={{ whiteSpace: 'pre-line' }}>
                          <span className="text-sm">{header}</span>
                        </span>
                      } className='bg-black py-1 px-2'>
                      <span className="font-bold text-sm capitalize">{header.split('-')[1] ?? header.split('-')[0]}</span>
                    </Tooltip>

                  </div>
                ))}
              </div>
            </div>
          </>
          :
          <div className="border border-superbill-soap divide-y divide-superbill-soap w-max sm:max-md:rounded md:rounded-r bg-white hover:bg-superbill-lavender-dark/20 ease-in-out duration-300">
            <div
              className={`flex divide-x divide-superbill-soap text-superbill-jacarta`}
              key={'body' + patient.location_id + patient.id}
            >
              <div className="md:hidden flex items-center p-2 flex-shrink-0 w-[50px] h-[55px] truncate">
                <div className='flex w-full h-full justify-center items-center'>
                  <>
                    <input
                      type="checkbox"
                      className="border border-superpay-light-text-gray rounded-sm checked:border-superbill-ultramarine"
                      checked={selectedItems.has(`${patient.call_id}`)}
                      onChange={() => handleSelectItem(patient.call_id)}
                    />
                  </>
                </div>
              </div>
              <div className="md:hidden flex items-center p-2 flex-shrink-0 w-[150px] h-[55px] truncate">
                <span className="text-base hover-underline-animation cursor-pointer" onClick={() => {
                  setSelectedPatient(patient.call_id)
                }}>
                  {patient.assignedAt ? <>{DateTime.fromJSDate(new Date(patient.assignedAt)).toFormat('MMM d, yyyy')}</> : <em>{formattedNewDate}</em>}
                </span>
                {patient?.call?.status === 'failed' ? (
                  <Tooltip
                    content={
                      <span style={{ whiteSpace: 'pre-line' }}>
                        Failed
                      </span>
                    } className='bg-black py-1 px-2'
                  >
                    <ExclamationIcon className="child:fill-[#F46F83] min-w-[16px] max-w-[16px] ml-2" />
                  </Tooltip>
                ) : null}
              </div>
              <div className="md:hidden flex items-center p-2 flex-shrink-0 w-[130px] h-[55px] truncate">
                <span className="text-base">{DateTime.fromJSDate(new Date(patient.dob)).toFormat('MMM d, yyyy')}</span>
              </div>
              <div className="md:hidden flex items-center p-2 flex-shrink-0 w-[130px] h-[55px] truncate">
                <span className="text-base">{patient.first_name}</span>
              </div>
              <div className="md:hidden flex items-center p-2 flex-shrink-0 w-[130px] h-[55px] truncate">
                <span className="text-base">{patient.last_name}</span>
              </div>
              {uniqueHeaders.map((header, patient_index) => (
                <div
                  key={`content-${patient_index}`}
                  className={`p-2 w-[150px] h-[55px] overflow-y-auto`}
                >
                  <span className="text-sm">
                    {patient.call && patient?.call?.status !== 'processing' && patient?.call?.results && typeof patient?.call?.results[header] !== 'undefined' && patient?.call?.results[header] !== null
                      ? (
                        patient.call.results[header]?.toString() === "true" ?
                          "Yes" :
                          (
                            patient.call.results[header]?.toString() === "false" ?
                              "No"
                              :
                              <Tooltip
                                content={
                                  <span style={{ whiteSpace: 'pre-line' }}>
                                    Answer: {patient.call.results[header].toString()}
                                  </span>
                                } className='bg-black py-1 px-2'
                              >
                                {patient.call.results[header].toString()}
                              </Tooltip>

                          ))
                      : '-'}
                  </span>
                </div>

              ))}
            </div>
          </div>
        }

      </div>)
    }
    const rowHeight = 55
    const tableHeight = rowHeight * (filteredAndSortedPatients.length + 1) + 3;

    return (
      <div className='flex-7/10 w-full'>
        <VirtualTableContext.Provider value={{ header: <TableHeader />, footer: '', top: 0, setTop: () => { } }}>
          <FixedSizeList height={tableHeight} width="100%" itemCount={filteredAndSortedPatients.length + 1} itemSize={rowHeight}>
            {Row}
          </FixedSizeList>
        </VirtualTableContext.Provider>
      </div>
    );
  };

  const VirtualFixedTable = () => {
    const Row = ({ index, style }) => {
      const patient_index = index - 1
      const patient = filteredAndSortedPatients[patient_index];
      let formattedNewDate;
      let newDateResult = new Date(DateTime.fromJSDate(new Date(patient?.assignedAt ?? patient?.date_created)).toFormat('MMM d, yyyy'))
      if (patient?.selectedTurnAroundTime && patient?.selectedTurnAroundTime.includes('hours')) {
        const match = patient?.selectedTurnAroundTime.match(/\d+/);
        let hours = 2;
        if (match) {
          hours = parseInt(match[0], 10);
        }
        let date = new Date(DateTime.fromJSDate(new Date(patient?.assignedAt ?? patient?.date_created)).toFormat('MMM d, yyyy'))
        newDateResult = addHours(date, hours)
        formattedNewDate = newDateResult.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        });
      } else if (patient?.selectedTurnAroundTime && patient?.selectedTurnAroundTime.includes('BD')) {
        const match = patient?.selectedTurnAroundTime.match(/\d+/);
        let days = 2
        if (match) {
          days = parseInt(match[0], 10);
        }
        const formattedInitialDate = new Date(DateTime.fromJSDate(new Date(patient?.assignedAt ?? patient?.date_created)).toFormat('MMM d, yyyy'))
        newDateResult = addBusinessDays(formattedInitialDate, days);
        formattedNewDate = newDateResult.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }) + ' 17:00';
      }
      return (<div style={style}>
        {index === 0 ?
          <>
            <div className="hidden md:block border border-superbill-soap divide-y divide-superbill-soap shadow-[8px_0_0_0_rgba(228,228,229,0.4)] z-10">
              <div className="flex w-full divide-x divide-superbill-soap">
                <div className="flex items-center p-2 bg-superbill-anti-flash-white flex-shrink-0 w-[50px] h-[55px] truncate">
                  <div className='flex w-full h-full justify-center items-center'>
                    <input
                      type="checkbox"
                      className="rounded-sm checked:border-superbill-ultramarine"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </div>
                </div>
                <div className="flex items-center p-2 bg-superbill-anti-flash-white flex-shrink-0 w-1/4 h-[55px] truncate ">
                  <span className="font-bold text-superbill-jacarta text-sm">Date Verified</span>
                  <span
                    className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200"
                    onClick={() => handleSort('end')}
                  >
                    {sortDirection === 'asc' && ['end', 'created_at'].includes(sortBy ?? '') ? (
                      <ChevronUpIcon className="cursor-pointer h-5 w-5" aria-hidden="true" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 cursor-pointer" aria-hidden="true" />
                    )}
                  </span>
                </div>
                <div className="flex items-center p-2 bg-superbill-anti-flash-white flex-shrink-0 w-1/4 h-[55px] truncate">
                  <span className="font-bold text-superbill-jacarta text-sm">Date of Birth</span>
                </div>
                <div className="flex items-center p-2 bg-superbill-anti-flash-white flex-shrink-0 w-1/4 h-[55px] truncate">
                  <span className="font-bold text-superbill-jacarta text-sm">First Name</span>
                </div>
                <div className="flex items-center p-2 bg-superbill-anti-flash-white flex-shrink-0 w-1/4 h-[55px] truncate">
                  <span className="font-bold text-superbill-jacarta text-sm">Last Name</span>
                </div>
              </div>
            </div>
          </>
          :
          <div className="hidden md:block border border-superbill-soap divide-y divide-superbill-soap shadow-[8px_0_0_0_rgba(228,228,229,0.4)] z-10">
            <div
              className={`flex divide-x divide-superbill-soap text-superbill-jacarta bg-superbill-table-grey hover:bg-superbill-lavender-dark/20 ease-in-out duration-300`}
              key={'header-' + patient.location_id + patient.id}
            >
              <div className="flex items-center p-2 flex-shrink-0 w-[50px] h-[55px] truncate">
                <div className='flex w-full h-full justify-center items-center'>
                  <>
                    <input
                      type="checkbox"
                      className="border border-superpay-light-text-gray rounded-sm checked:border-superbill-ultramarine"
                      checked={selectedItems.has(`${patient.call_id}`)}
                      onChange={() => handleSelectItem(patient.call_id)}
                    />
                  </>
                </div>
              </div>
              <div className="flex items-center p-2 flex-shrink-0 w-1/4 h-[55px] truncate">
                <span className={`${patient?.call?.status !== 'processing' ? 'hover-underline-animation' : 'text-superbill-slate-grey'} text-base  cursor-pointer`} onClick={() => {
                  setSelectedPatient(patient.call_id)
                }}>
                {patient?.call?.status !== 'processing' ? <>{DateTime.fromJSDate(new Date(patient.assignedAt)).toFormat('MMM d, yyyy')}</> : <em>{formattedNewDate}</em>}
                </span>
                {patient?.call?.status === 'failed' ? (
                  <Tooltip
                    content={
                      <span style={{ whiteSpace: 'pre-line' }}>
                        Failed
                      </span>
                    } className='bg-black py-1 px-2'
                  >
                    <ExclamationIcon className="child:fill-[#F46F83] min-w-[16px] max-w-[16px] ml-2" />
                  </Tooltip>
                ) : null}
              </div>
              <div className="flex items-center p-2 flex-shrink-0 w-1/4 h-[55px] truncate">
                <span className="text-base">{DateTime.fromJSDate(new Date(patient.dob)).toFormat('MMM d, yyyy')}</span>
              </div>
              <div className="flex items-center p-2 flex-shrink-0 w-1/4 h-[55px] truncate">
                <span className="text-base">{patient.first_name}</span>
              </div>
              <div className="flex items-center p-2 flex-shrink-0 w-1/4 h-[55px] truncate">
                <span className="text-base">{patient.last_name}</span>
              </div>
            </div>
          </div>
        }

      </div>)
    }
    const rowHeight = 55
    //const tableHeight = rowHeight * (filteredAndSortedPatients.length + 1);
    const tableHeight = rowHeight * (filteredAndSortedPatients.length + 1) + 3;

    return (
      <div className="hidden md:block flex-3/10 w-full">
        <VirtualTableContext.Provider value={{ header: <TableHeader />, footer: '', top: 0, setTop: () => { } }}>
          <FixedSizeList height={tableHeight} width="100%" itemCount={filteredAndSortedPatients.length + 1} itemSize={rowHeight}>
            {Row}
          </FixedSizeList>
        </VirtualTableContext.Provider>
      </div>
    );
  };

  return (
    <>
      {Object.keys(filteredAndSortedPatients).length === 0 ? (
        <>
          {archived ?
          <>
          <NoPatients className="w-full text-center mb-4" />
          <div className="font-sans w-full text-center items-center text-superpay-light-text-gray">
            No archived results available
          </div>
          </>
          :
          <>
          <NoPatients className="w-full text-center mb-4" />
          <div className="font-sans w-full text-center items-center text-superpay-light-text-gray">
            No patient insurance verification results available
          </div>
          </>
          }
        </>
      ) : (
        <>
          <div className="flex px-2 items-center font-sans h-[48px]">
                {!archived && <button
                  className='flex items-center gap-x-2 bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold mr-3 py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 disabled:bg-superbill-anti-flash-white disabled:text-superbill-slate-grey/50'
                  onClick={async () => { 
                    setIsRetryModalOpen(true);
                  }}
                  disabled={selectedItems.size == 0}
                >
                  Re-try
                </button>}
                <button
                  className='flex items-center gap-x-2 bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 disabled:bg-superbill-anti-flash-white disabled:text-superbill-slate-grey/50'
                  disabled={isArchiving || selectedItems.size == 0}
                  onClick={async () => { 
                    console.log('selectedItems', Array.from(selectedItems));
                    setIsArchiving(true)
                    const { httpCode } = await archiveResults({'calls':Array.from(selectedItems), archive: !archived})
                    setIsArchiving(false)
                    if ( httpCode === 200 ){
                      toast.success(`Successfully ${!archived ? 'archived' : 'un-archived'}`, {
                        iconTheme: {
                          primary: '#4a43cd',
                          secondary: '#ffffff',
                        },
                      });
                      refresh(pmsResults.offset, pmsResults.limit)
                    }
                  }}
                >
                  {!archived ? 'Archive' : 'Un-Archive'}
                  {isArchiving ? <Loader size={12} /> : null}
                </button>
            </div>
          <div className="flex w-full p-2 font-sans">
            <VirtualFixedTable />
            <VirtualTable />
          </div>
          <div className="flex items-center justify-end flex-row px-2 pt-2 font-sans">
            <div
              className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
              onClick={async () => {
                await refresh(Math.max(0, pmsResults.offset - pmsResults.limit))
              }}
            >
              {pmsResults.offset === 0 ? <></> : <BackIcon />}
            </div>
            <div className="mx-2 flex items-center">
              <strong className='mr-1'>
                <Controller
                  name="gotoInput"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      defaultValue={(pmsResults.offset / pmsResults.limit + 1).toString()}
                      className={`px-1 h-6 flex items-center justify-center text-center ronded rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base ${pmsResults.offset > 999 ? `w-16` : pmsResults.offset > 99 ? `w-12` : `w-8`}`}
                      onBlur={handleInputChange}
                      onKeyDown={handleKeyDown}
                    />
                  )}
                />

              </strong> {total ? <> of{" "} {Math.ceil(total! / pmsResults?.limit).toLocaleString()}
              </> : <Loader size={12} />}
            </div>
            <div
              className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
              /* onClick={() => changePagination(PaginationStates.forward)} */
              onClick={async () => {
                await refresh(Math.min(total, pmsResults?.offset + pmsResults?.limit), 0)
              }}
            >
              <ForwardIcon />
            </div>
          </div>
          <RetryCallsModal
            isLoading={isRetrying}
            isModalOpen={isRetryModalOpen} 
            setIsModalOpen={setIsRetryModalOpen} 
            numCalls={selectedItems.size} 
            retryCalls={retryCalls} 
          />
        </>
      )}

    </>
  );

}
export default PMSResultsTable