import React, { useEffect, useState, useContext, useRef } from 'react';

import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as HangUpIcon } from 'assets/phone-hang-up.svg';
import { ReactComponent as NextCallIcon } from 'assets/phone-next-call.svg'
import { AssignNextCallInQueueResponse } from 'types/types';

type SidekickCallsContentToolbarProps = {
  getNextCall: () => void;
  initWebCall?: () => void;
  initiateCallIsLoading: boolean;
  discard: () => void;
  callData: null | AssignNextCallInQueueResponse;
  robodialerId: string | null;
  register: any;
  editChecked: boolean;
  initiateWebCallDisabled: boolean;
  phoneNumber: string;
  getNextCallDisabled: boolean;
}
export default function SidekickCallsContentToolbar({ getNextCallDisabled, initiateWebCallDisabled, phoneNumber, initiateCallIsLoading, editChecked, initWebCall, getNextCall, robodialerId, discard, callData = null, register }: SidekickCallsContentToolbarProps) {
  const regex = /^[0-9]+$/;
  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  return (
    <>
      <div className="font-sans">
        <div className={`flex ${callData ? 'justify-between' : 'justify-center'} mt-4 mx-4 bg-white p-3 rounded border border-superbill-soap shadow `}>
          {callData ?
            <div className='w-1/2 flex gap-x-2 items-center justify-start'>
              {robodialerId === "DP7Ngpj5QGfffX07CtU4" || robodialerId === "jJFG0YRMbBxjFkiA4QHa" ?
                <div className='flex items-center justify-center px-4 py-2 gap-x-2 bg-superbill-banner-red rounded'>
                  <InfoIcon className='child:fill-superbill-ultra-red-hover max-w-[14px] ml-2 w-[14px]' />
                  <span className='text-sm text-superbill-jacarta'>
                    Phone number MUST be looked up for this call
                  </span>
                </div>
                :
                (!editChecked ? <label className='w-full flex items-center font-bold text-superbill-jacarta'>Number to call: +1 ({callData?.to?.replace(/-/g, '').slice(0, 3)}) {callData?.to?.replace(/-/g, '').slice(3, 6)}-{callData?.to?.replace(/-/g, '').slice(6, 10)}</label> : null)
              }
              <div className='w-full flex gap-x-2 items-center justify-start'>
                {editChecked ?
                  < input
                    type="text"
                    {...register('phoneNumber')}
                    placeholder='10 DIGITS NUMBER - please double check before initiating the call'
                    maxLength={10}
                    className="rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base w-full mr-2"
                  /> : null}
                <label className='flex items-center  bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 mr-4'>
                  <input
                    type="checkbox"
                    {...register('edit')}
                    className="mr-2 checked:bg-superbill-ultramarine rounded-sm hidden"
                  />
                  {!editChecked ? 'Edit phone number' : 'Cancel'}
                </label>

              </div>
            </div>
            : null}

          <div className={`w-1/2 flex items-center ${callData ? 'justify-end' : 'justify-center'} px-4 py-2 gap-x-2`}>
            {!callData ?
              <button
                disabled={getNextCallDisabled || isLoading}
               className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center mr-4' onClick={async () => {
                setIsLoading(true)
                await getNextCall()
                setIsLoading(false)
              }}>
                <NextCallIcon className='fill-white mr-2' />
                {isLoading ? <Spinner /> : <>Get Next Call</>}
              </button> :
              <button
                className='bg-green-600 disabled:bg-superbill-aero-green font-jakarta hover:bg-green-900 text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
                disabled={ initiateWebCallDisabled || initiateCallIsLoading || callData?._robodialer_id == null || 
                  (!editChecked && !callData.to) || (editChecked && (phoneNumber?.length !== 10 || !regex.test(phoneNumber)))}
                onClick={initWebCall}
              >
                {initiateCallIsLoading ? <Spinner /> : <>Initiate web call</>}
              </button>
            }
            {callData ?
              <button className='bg-superbill-ultra-red disabled:bg-superbill-ultra-red/50 font-jakarta hover:bg-superbill-ultra-red-hover text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center' onClick={async () => {
                setIsLoading(true)
                await discard()
                setIsLoading(false)
              }} disabled={!callData}>
                <HangUpIcon className='fill-white mr-2' />
                {isLoading ? <Spinner /> : <>Discard call</>}
              </button>
              : null}
          </div>
        </div>

      </div>
      {/* <RetoolPage /> */}
    </>
  );
}
