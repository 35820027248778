// basic react component
import React, { useState } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    height: "max(30%,300px)",
    maxWidth: "max(50%,400px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "9999",
  },
};

type BackgroundInfoModalProps = {
  isOpen: boolean;
  selectedIndex: string | number | null;
  onClose: () => void;
  onSubmit: (index, data) => void;
};
export default function BackgroundInfoModal({
  isOpen,
  onClose,
  onSubmit,
  selectedIndex,
}: BackgroundInfoModalProps) {
  const [localBackgroundText, setLocalBackgroundText] = useState<string>("");
  const handleBackgroundTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setLocalBackgroundText(event.target.value);
  };
  return (
    <div className="z-50">
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Provide Background Info for this Question
          </nav>
          <div className="flex-grow overflow-y-auto px-9 py-4">
            <div className="text-superbill-jacarta">
              Background information can help our AI assistant communicate with
              the representative.
            </div>
            <textarea
              id="backgroundInfo"
              placeholder="“Prior Auth” and “Certification” are the same thing, the rep might use those words "
              className={`border-superbill-soap focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none focus:border-superbill-indigo py-2 px-3 bg-white border block w-full sm:text-sm rounded placeholder:text-sm text-superbill-jacarta mt-2`}
              value={localBackgroundText}
              onChange={handleBackgroundTextAreaChange}
            />
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300"
              onClick={() => {
                setLocalBackgroundText("");
                onClose();
              }}
            >
              Close
            </button>
            <button
              onClick={() => {
                const backgroundInfo = localBackgroundText
                onSubmit(selectedIndex, backgroundInfo);
                setLocalBackgroundText('')
              }}
              className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
