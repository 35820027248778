import React, { useMemo, useState } from 'react'
import { DateTime } from 'luxon';
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { PaginationStates, paginationHelper } from '../utils/pagination';
import { downloadResults } from '../apis/robodialer';
import { Tooltip } from '@material-tailwind/react';
import { addBusinessDays } from 'date-fns';
import ResultsMenu from './results-options';
import { toast } from 'react-hot-toast';

type SandboxCallsResultsTableProps = {
  calls: any;
  setSelectedCallId: React.Dispatch<React.SetStateAction<string | null>>;
}

function SandboxCallsResultsTable({ calls, setSelectedCallId }: SandboxCallsResultsTableProps) {


  const convertedArray = Object.keys(calls ?? {})?.map(key => ({
    id: key,
    ...calls[key]
  }));

  const [currentResultsPageIndex, setCurrentResultsPageIndex] =
    useState<number>(0);

  const {
    newEntries: currentActiveResults,
    currentLowerIndex,
    currentUpperIndex,
    changePagination,
  } = paginationHelper({
    entries: convertedArray,
    currentPageIndex: currentResultsPageIndex,
    maxEntriesPerPage: 10,
    setCurrentPageIndex: setCurrentResultsPageIndex,
  });

  const sortedCalls = useMemo(() => {
    let sortedCall = [...currentActiveResults];

    sortedCall.sort(
      (a, b) => {
        if (a['createdAt'] < b['createdAt']) { return 1 } else { return -1 };
      }
    );
    return sortedCall;
  }, [currentActiveResults]);

  return (
    <>
      <div className="grid grid-cols-6 gap-4 w-full mt-4 px-5">
        <div className='col-span-2 text-sm text-superpay-light-text-gray'>CALLS</div>
        <div className='col-span-3 text-sm text-superpay-light-text-gray'>TIMESTAMP</div>
        <div className='col-span-1 text-sm text-superpay-light-text-gray'>RESPONSE</div>
      </div>
      <div className='w-full my-1 bg-white rounded px-5 py-2 border border-superbill-soap  divide-y divide-superbill-soap font-sans'>
        {Object.keys(calls).length === 0 ? <>
          <div className='text-center text-superbill-jacarta text-base'>No Results</div>
        </> : null}
        {Object.keys(sortedCalls)?.map((id) => {
          return (
            <div className='grid grid-cols-6 gap-4 p-3 content-center cursor-pointer items-center' key={id} onClick={() => { setSelectedCallId(sortedCalls[id].id) }}>
              <div className='col-span-2 text-superbill-indigo text-sm font-medium'>{sortedCalls[id].name ?? sortedCalls[id].scriptId}</div>
              <div className='col-span-3 text-superbill-jacarta text-sm'>{DateTime.fromJSDate(new Date((sortedCalls[id].createdAt ?? sortedCalls[id].dateCreated))).toFormat('MM/dd/yyyy HH:mm:ss')}</div>
              <div className='col-span-1 text-xs text-bold'>{sortedCalls[id].success ?
                <div className='text-superbill-ultramarine w-fit bg-superbill-banner-blue p-2 rounded-full'>200</div>
                :
                <div className='text-superbill-ultra-red w-fit bg-superbill-banner-red p-2 rounded-full'>500</div>
              }</div>
            </div>
          )
        })}
        <div className="flex justify-end flex-row px-2 pt-2">
          <div
            className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
            onClick={() => changePagination(PaginationStates.backward)}
          >
            <BackIcon />
          </div>
          <div className="mx-2">
            <strong>{currentLowerIndex + 1} to {currentUpperIndex + 1}</strong> of{" "}
            {convertedArray?.length}
          </div>
          <div
            className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
            onClick={() => changePagination(PaginationStates.forward)}
          >
            <ForwardIcon />
          </div>
        </div>
      </div>
    </>
  )
}

export default SandboxCallsResultsTable