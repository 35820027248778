import React, { useEffect, useState } from "react";
import Loader from 'components/Loader';
import Modal from "react-modal";
import { RunScriptModalStates } from "enums/enums";
import { RunScriptErrosType, Script } from "types/types";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { duplicateScript } from "../../apis/robodialer";
import { toast } from "react-hot-toast";
// custom styles
const customStyles = {
  content: {
    height: "max(30%,300px)",
    maxWidth: "max(50%,400px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};


// components
type DuplicateScriptModalProps = {

  scriptData: Array<Script> | null;
  isOpen: boolean;
  onClose: () => void;
  selectedScriptId: string;
  onSuccess?: () => void;
  
}
export default function DuplicateScriptModal({
  isOpen,
  onClose,
  selectedScriptId,
  onSuccess,
  scriptData }: DuplicateScriptModalProps) {
  //const [modalIsOpen, setModalIsOpen] = React.useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { control, handleSubmit, watch, setValue } = useForm();
  const onSubmit = async (data) => {
    if (!data.name){
      return
    }
    setIsLoading(true)
    const { httpCode } = await duplicateScript(selectedScriptId, data);
    setIsLoading(false)
    if (httpCode === 200) {
      toast.success('Script successfully duplicated.', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      });
    } else {
      toast.error('Unable to duplicated', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
    toggleModal()
  };

  const toggleModal = () => {
    console.log('closing');
    setValue('name', '');
    onClose()
  };

  useEffect(() => {
    if (selectedScriptId) {
      setValue('name', `Copy of ${scriptData?.find((e) => e.id === selectedScriptId)?.name}`);
    }
  },[selectedScriptId])

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Duplicate Script Modal"
      >
        <div className="flex flex-col h-full divide-y">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Duplicate a Script
          </nav>
          <div className="flex-grow overflow-y-auto p-9">
            <form className="w-full">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    defaultValue={`Copy of ${scriptData?.find((e) => e.id === selectedScriptId)?.name}`}
                    className={'rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base w-full'}
                    placeholder="Enter a name"
                  />
                )}
              />
            </form>
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={onClose}
            >
              Close
            </button>
            <button
              className="bg-superbill-ultramarine disabled:bg-blue-200 font-jakarta hover:bg-blue-700 text-white font-bold py-2 px-5 rounded-full"
              onClick={handleSubmit(onSubmit)}
            >
              {isLoading ? <Loader size={28} /> : <>Duplicate</>}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
